import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { paymentOption } from 'src/app/@shared/enums/payment-option.enums';
import { Breadcrumb } from 'src/app/@shared/models/breadcrumb.model';
import { CardPaymentModel } from 'src/app/@shared/models/Payment.model';
import { Template } from 'src/app/@shared/models/template.model';
import { PaymentService } from 'src/app/@shared/services/payment.service';
import { TemplateService } from 'src/app/@shared/services/template.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.sass'],
})
export class TemplateBillingComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [
    {
      id: '',
      title: 'Templates',
    },
  ];
  subs: Subscription = new Subscription();
  id: string = '';
  template: Template = {
    id: '',
    name: '',
    size: '',
    actions: {},
  };
  title: string = '';
  url: string = '';
  paymentOption = paymentOption;
  payment: paymentOption = paymentOption.card;
  cardNumber:string='';
  cardExpiry:string='';
  cvc:string='';
 
  constructor(
    private _route: ActivatedRoute,
    public dialog: MatDialog,
    private _templateService: TemplateService,
    private _paymentService: PaymentService
  ) {
    this.url = window.location.pathname;
  }

  ngOnInit(): void {
    if (this.url.search('templates') == 1) {
      this.title = 'Templates';
      this.subs.add(
        this._route.params.subscribe((data) => {
          this.id = data['id'];
        })
      );
      this.template = this._templateService.selectedTemplate;
      if (this.template.id) {
        // GET BILLING INFORMATION...
      } else {
        // GET TEMPLATE INFO USING ID...
      }

      this.breadcrumbs.push({
        id: '',
        title: this.template.name || 'Nondisclosure Agreement',
      });
    } else {
      this.title = 'Subscriptions';
    }
  }
  confirmSubscription() {
    if (this.payment == this.paymentOption.card) {
      const expMonth= this.cardExpiry.split('/')[0];
      const expYear= "20"+this.cardExpiry.split('/')[1];
      const cardInfo:CardPaymentModel={
        cardNumber:this.cardNumber,
        expMonth:expMonth ,
        expYear: expYear,
        cvc:this.cvc,
        amount: 100,
        currency: 'php',
        email: 'admin@dfa.gov.ph',
        firstName: 'admin',
        lastName: 'test'
      };
      this._paymentService.cardPayment(cardInfo).subscribe((i) => {
        
        const dialogRef = this.dialog?.open(ModalComponent, {
          width: '520px',
          data: { action: 'sub-payment-confirmed' },
        });
      });
      
    }
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
