<div class="automatic">
  <h3 class="automatic__title">EXPIRATION AND REMINDER OPTIONS</h3>
  <div class="automatic__expiry">
    <h3 class="expiry__label">Send Reminder in X Days</h3>
    <div class="expiry__datepicker">
      <div class="magic">
        <input
          [matDatepicker]="picker"
          placeholder="Select Schedule"
          [value]="data.expiration"
          (dateChange)="changeValue('expiration', $event)"
        />
        <mat-datepicker-toggle
          [for]="picker"
          [disableRipple]="false"
        ></mat-datepicker-toggle>
      </div>
      <mat-datepicker #picker></mat-datepicker>
      <h3 class="expiry__format">In Philippine Standard Time</h3>
    </div>
  </div>
  <div class="automatic__reminder">
    <div class="reminder__item">
      <h3 class="title">Send Reminder in X Days</h3>
      <input
        (change)="changeValue('reminderInXDays', $event)"
        type="number"
        min="0"
        placeholder="0"
        [value]="data.reminderInXDays"
      />
    </div>
    <div class="reminder__item">
      <h3 class="title">Send Reminder every X Days</h3>
      <input
        (change)="changeValue('reminderEveryXDays', $event)"
        type="number"
        min="0"
        placeholder="0"
        [value]="data.reminderEveryXDays"
      />
    </div>
    <div class="reminder__item">
      <h3 class="title">Send Reminder in X Days before Expiration</h3>
      <input
        (change)="changeValue('reminderBeforeXDays', $event)"
        type="number"
        min="0"
        placeholder="0"
        [value]="data.reminderBeforeXDays"
      />
    </div>
  </div>
</div>
