import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ActionCallbackData } from 'src/app/@shared/models/Actions.model';
import { Template } from 'src/app/@shared/models/template.model';
import { TemplateService } from 'src/app/@shared/services/template.service';

@Component({
  selector: 'app-admin-dashboard-templates',
  templateUrl: './admin-dashboard-templates.component.html',
  styleUrls: ['./admin-dashboard-templates.component.sass'],
})
export class AdminDashboardTemplatesComponent implements OnInit {
  templateColumns = this._templateService.templateTableColumns;
  templates: Observable<Template[]> = this._templateService.templates$;

  myTemplateColumns = this._templateService.myTemplateTableColumns;
  myTemplates: Observable<Template[]> = this._templateService.myTemplates$;

  cards: any[] = [
    { id: 'hr', name: 'HR', number: 20 },
    { id: 'education', name: 'Education', number: 4 },
    { id: 'admin', name: 'Admin', number: 20 },
    { id: 'government', name: 'Government', number: 2 },
    { id: 'other', name: 'Others', number: 14 },
  ];

  selected: string = 'hr';

  private actions: Map<string, any> = new Map([
    ['preview', this.preview],
    ['modify', this.modify],
  ]);

  constructor(
    private _templateService: TemplateService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._templateService.getTemplates();
  }

  tableActionClicked(tableName: string, data: any) {
    const { action, data: mData } = data;
    if (this.actions.has(action || '')) {
      this.actions.get(action)(
        tableName,
        mData,
        this._router,
        this._templateService
      );
    }
  }

  // Note need to pass everything if not, it will error undefined
  modify(tableName: string, data: any, router: Router) {
    
  }

  // Note need to pass everything if not, it will error undefined
  preview(
    tableName: string,
    data: any,
    router: Router,
    service: TemplateService
  ) {
    service.selectedTemplate = data;
    router.navigateByUrl(`templates/${data.id}`);
  }

  cardSelected(name: string) {
    this.selected = name;
  }
}
