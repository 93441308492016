<div class="container">
  <app-admin-sidebar [sideNavToggle]="toggle"></app-admin-sidebar>
  <div class="content-wrapper">
    <app-admin-navbar (toggleSideNavEvent)="toggleSideNav($event)"></app-admin-navbar>
    <div class="content-separate">
      <app-admin-sidebar [sideNavToggle]="toggle"></app-admin-sidebar>
      <app-admin-dashboard-content></app-admin-dashboard-content>
    </div>
  </div>
</div>
