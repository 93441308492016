import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.sass'],
})
export class UploadComponent implements OnInit {
  buttonText: string = 'Add files';
  value: string = 'files';
  @ViewChild('select')
  selected!: MatRadioButton;

  @Output() closeModalEvent = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onChangeOption({ value }: any) {
    this.buttonText = `Add ${value}`;
    this.value = value;
  }

  dragover(data: FileList) {
    if (this.value === 'files' && !this.checkIfContainsFolder(data)) {
      this.closeModalEvent.emit(data);
    } else {
      this.upload('folder');
    }
  }

  checkIfContainsFolder(data: FileList) {
    for (let i = 0; i < data.length; i++) {
      if (data.item(i)?.type === '') {
        return true;
      }
    }
    return false;
  }

  onFileSelected(data: any) {
    const regex =
      /.*\.(jpg|JPG|png|PNG|pdf|PDF|doc|DOC|docx|DOCX|xls|XLS|xlsx|XLSX|ppt|PPT|pptx|PPTX|mp3|MP3|mp4|MP4|zip|ZIP)$/g;
    let files = [];
    if (data.path) {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else if (data.target) {
      files = Array.from(data.target.files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    }

    this.closeModalEvent.emit(files);
  }

  upload(data: any) {
    switch (data || this.value) {
      case 'files': {
        document.getElementById('fileUpload')?.click();
        break;
      }
      case 'folder': {
        document.getElementById('folderUpload')?.click();
        break;
      }
      default: {
        break;
      }
    }
  }

  close() {
    this.closeModalEvent.emit();
  }
}
