import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass'],
})
export class SettingsComponent implements OnInit {
  cols: string[] = ['Name', 'Preview', 'Date Created', 'Last Update', 'State'];

  data = [
    {
      name: 'Banner 1',
      preview: '../../../assets/images/forma/ad-banner.svg',
      date_created: 'Jul 27, 2021 at 2:58pm',
      last_update: 'Jul 27, 2021 at 2:58pm',
      state: 'Active',
    },
    {
      name: 'Banner 1',
      preview: '../../../assets/images/forma/ad-banner.svg',
      date_created: 'Jul 27, 2021 at 2:58pm',
      last_update: 'Jul 27, 2021 at 2:58pm',
      state: 'Active',
    },
    {
      name: 'Banner 1',
      preview: '../../../assets/images/forma/ad-banner.svg',
      date_created: 'Jul 27, 2021 at 2:58pm',
      last_update: 'Jul 27, 2021 at 2:58pm',
      state: 'Active',
    },
    {
      name: 'Banner 1',
      preview: '../../../assets/images/forma/ad-banner.svg',
      date_created: 'Jul 27, 2021 at 2:58pm',
      last_update: 'Jul 27, 2021 at 2:58pm',
      state: 'Active',
    },
    {
      name: 'Banner 1',
      preview: '../../../assets/images/forma/ad-banner.svg',
      date_created: 'Jul 27, 2021 at 2:58pm',
      last_update: 'Jul 27, 2021 at 2:58pm',
      state: 'Active',
    },
    {
      name: 'Banner 1',
      preview: '../../../assets/images/forma/ad-banner.svg',
      date_created: 'Jul 27, 2021 at 2:58pm',
      last_update: 'Jul 27, 2021 at 2:58pm',
      state: 'Active',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
