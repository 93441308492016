<div class="fail-container">
	<div class="title">
		<img src="../../../../assets/images/warning.png" alt="" />
		<span>Document not found</span>
	</div>
	<div class="description">
		<p>The document can’t be found.</p>
		<p>Please try again</p>
	</div>
	<div class="button" (click)="close()">Back to log in</div>
</div>
