<div class="parties-container">
  <div class="top">
    <h1>Add document roles</h1>
    <p>
      Indicate who needs to sign, approve, and receive a copy of the document
      and notify them via email.
    </p>
  </div>
  <div class="parties-content-scroll">
    <div class="body" cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="header-pair">
        <!-- *ngIf="parties.length !== 1" -->
        <div class="side-left">
          <p>Set Signing Order</p>
          <img src="../../../../../assets/images/template-icons/toggle-off.svg" alt="" srcset="">
          <mat-slide-toggle
            (change)="signOrder = $event.checked"
            [checked]="signOrder" >
          </mat-slide-toggle>
        </div>
        <div class="side-right">
          <mat-checkbox
            (change)="addMeAsSignatory($event.checked)"
            [checked]="included"
            [ngClass]="isChecked ? 'check-css' : ''"
            >
            I am the signatory
          </mat-checkbox>
        </div>
      </div>
      <ng-container *ngFor="let party of parties; let i = index">
        <mat-card class="party-card-container" cdkDrag>
          <div class="top">
            <h1>Party {{ i + 1 }}</h1>
            <img
              *ngIf="i > 0"
              src="../../../../../assets/images/template-icons/trash-delete.png"
              alt=""
              (click)="removeParty(i)"
            />
          </div>
          <div class="form-body">
            <span class="form-pair">
              <div class="form-input">
                <mat-label>Full Name</mat-label>
                <mat-form-field floatLabel="auto" appearance="fill">
                  <input
                    type="text"
                    matInput
                    [(ngModel)]="party.name"
                    placeholder="John Doe"
                  />
                </mat-form-field>
              </div>
              <div class="form-input">
                <mat-label>Email</mat-label>
                <mat-form-field floatLabel="auto" appearance="fill">
                  <input
                    type="text"
                    matInput
                    [(ngModel)]="party.email"
                    [matAutocomplete]="auto"
                    [formControl]="party.control"
                    placeholder="jdoe@email.com"
                    (keydown.enter)="select(null, i)"
                  />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option
                      *ngFor="let option of party.options | async"
                      [value]="option"
                      (click)="select(option, i)"
                    >
                      {{ option.email }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <mat-error
                  *ngIf="
                    party.control?.errors?.alreadyExist?.value &&
                    parties.length > 1 &&
                    party.control?.value !== ''
                  "
                  >{{ party.control?.errors?.alreadyExist?.value }}</mat-error
                >
              </div>
            </span>
            <span class="form-pair">
              <div class="form-input">
                <mat-label>Designation (optional)</mat-label>
                <mat-form-field floatLabel="auto" appearance="fill">
                  <input
                    type="text"
                    matInput
                    [(ngModel)]="party.designation"
                    placeholder="Mr."
                  />
                </mat-form-field>
              </div>
              <div class="form-input">
                <mat-label>Role</mat-label>
                <mat-select [(ngModel)]="party.role" placeholder="Signatory">
                  <mat-option value="SIGN">Needs to sign</mat-option>
                  <mat-option value="APPROVE">Needs to approve</mat-option>
                  <mat-option value="COPY">Receives a copy</mat-option>
                </mat-select>
              </div>
            </span>
  
          
          </div>
        </mat-card>
      </ng-container>
      <button class="another-party" (click)="addParty()">
        <img class="add-party__icon"/>
        Add another party
      </button>
    </div>
  </div>
  <div class="bottom">
    <span class="btn-grp">
      <button class="back" (click)="back()">Back</button>
      <button class="next" (click)="next()">Next</button>
    </span>
  </div>
</div>

<ng-template #partyCard let-i="index" let-data="data"> </ng-template>
