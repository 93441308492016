import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilesService } from 'src/app/@shared/services/files.service';

@Component({
  selector: 'app-delete-document',
  templateUrl: './delete-document.component.html',
  styleUrls: ['./delete-document.component.sass'],
})
export class DeleteDocumentComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  @Input() data: any;

  constructor(private fileService: FilesService) { }

  ngOnInit(): void { console.log(this.data);}

  async deleteDocument() {
    const payload = {
      uid: this.data.data.uid,
      id: this.data.data.id,
    };

    await this.fileService.deleteFile(this.data.data.id)
    this.closeModal()
  }

  closeModal(): void {
    location.reload();
    this.closeModalEvent.emit();
  }
}
