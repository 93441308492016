<div class="detail">
    <div class="detail-left">
        <img src="../../../../assets/images/check-green.svg" alt="" class="checksign">
    </div>
    <div class="detail-right">
        <p class="time">{{time}}</p>
        <div class="content">
            <p class="event">{{ event }}</p>
            <p class="remarks" *ngIf="data?.remarks">Remarks: <strong>{{data.remarks}}</strong></p>
        </div>
    </div>
</div>