import { Component, OnInit } from '@angular/core';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UserGroupComponent } from '../user-group/user-group.component';

@Component({
  selector: 'app-delete-user-group',
  templateUrl: './delete-user-group.component.html',
  styleUrls: ['../user-group/user-group.component.sass'],
})
export class DeleteUserGroupComponent
  extends UserGroupComponent
  implements OnInit
{
  constructor(private _userGroupService: UserGroupService) {
    super();
  }

  ngOnInit(): void {}

  deleteUserGroup() {
    this._userGroupService
      .deleteUserGroups(this.data.data)
      .subscribe((result) => {
        this._userGroupService.getUserGroups().subscribe(({ data }) => {
          this._userGroupService.setUserGroup(
            this._userGroupService.format(data)
          );
        });
        this.closeModal();
      });
  }
}
