import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignSecureService } from '../../services/sign-secure.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-accept-reject',
  templateUrl: './accept-reject.component.html',
  styleUrls: ['./accept-reject.component.sass'],
})
export class AcceptRejectComponent implements OnInit {
  @Input()
  data: any;

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter();

  note: string = '';
  constructor(private _signSecure: SignSecureService) {}

  ngOnInit(): void {
    console.log({ id: this.data });
  }

async approve() {
  try {
    const result = await lastValueFrom(this._signSecure.documentApprove(this.data?.data ?? '', this.note));
    this.close('approve', result?.data);
  } catch (error) {
    console.error('Error approving document:', error);
  }
}

  decline() {
    this._signSecure
      .documentReject(this.data?.data ?? '', this.note)
      .subscribe((data) => {
        this.close('reject', data?.data);
      });
  }

  close(action: string, data: any) {
    this.closeModalEvent.emit({ action, note: this.note, data });
  }
}
