<mat-card class="progress-card">
  <div class="content">
    <div class="text">
      <p class="number-docs">{{ number }}</p>
      <h5 class="title">{{ title }}</h5>
    </div>
  </div>
  <div class="footer" (click)="navigateTo(location)">
    <p>See all</p>
    <img src="../../../assets/images/forma/next.svg" alt="" />
  </div>
</mat-card>
