import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { lastValueFrom } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';
import { Breadcrumb } from '../models/breadcrumb.model';
import { FileDataModel } from '../models/file-data.model';
import { AuthenticationService } from '../services/authentication.service';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { FileActionService } from '../services/file-action.service';
import { FilesService } from '../services/files.service';
import { InboxService } from '../services/inbox.service';
import { NavigationService } from '../services/navigation.service';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { ActionCallbackData } from '../models/Actions.model';
import { Router } from '@angular/router';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass'],
})
export class TableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @Input('columns')
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  @Input() isSearch: boolean = false;
  @Input() resultsLength: number = 0;
  @Input('data')
  data: any;
  isInitialLoad: boolean = true;
  @Input()
  title: string = 'Recent Documents';

  @Input()
  addButton: string = '';

  @Input()
  addButtonIcon: string = '';

  @Input() hasCheckBox: boolean = false;

  @Input()
  showFilter: boolean = false;

  @Output('modal')
  showModal: EventEmitter<any> = new EventEmitter<any>();

  @Input() type: string = '';

  @Input() customizeMatCellClass: string = '';

  @Output('modalCloses') callback: EventEmitter<any> = new EventEmitter<any>();

  @Output('clickedRow') clicked: EventEmitter<any> = new EventEmitter<any>();

  @Output('actionClicked') actionCallback: EventEmitter<ActionCallbackData> =
    new EventEmitter<ActionCallbackData>();

  checkBool: boolean = false;
  dataSource = new MatTableDataSource();
  @Output() selectedItems: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  @Input()
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  selectionLength: number = 0;

  file: any;

  @Input()
  canOpenFile: boolean = false;

  @Input()
  breadcrumb: Breadcrumb[] = [];

  @Input('paginate')
  isPaginate: boolean = false;

  @Input()
  pageSize: number = 5;

  @Input()
  showBreadcrumbs: boolean = false;

  @Input()
  isFolderStructure: boolean = true;

  @Input()
  folderFunctions: any;

  @Input()
  modifyFile: boolean | null = false;

  @Input()
  hideTitle: boolean = false;

  @Input()
  nameFunction: Function = (element: any) => {};

  @Input()
  rowFunction: Function = (event: any, element: any) => {};

  @Input()
  breadcrumbBack: Function = (event: any) => {};

  @Input()
  sortColumns: any = {};

  @ViewChild(MatSort) sort!: MatSort;

  isTabletWidth = false;
  isPhoneWidth = false;

  readonly workflow = environment.flag.useLocalSign;
  public delete = { delete: true };
  @Input() isChecked: boolean = false;
  // don't remove the unused services they are used in the different components
  constructor(
    public dialog: MatDialog,
    private _fileService: FilesService,
    private _fileActionService: FileActionService,
    private _breadcrumbs: BreadcrumbsService,
    private _inboxService: InboxService,
    private _navigation: NavigationService,
    private _signSecureService: SignSecureService,
    private router: Router,
    private _liveAnnouncer: LiveAnnouncer,
  ) {}
  ngAfterViewInit() {
    this.selection.changed.subscribe(s => {});
    this._breadcrumbs.breadcrumbs$.subscribe(i => {
      this.breadcrumb = i;
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: any): void {
    this.isChecked = false;
    this.dataSource.data = this.data;
  }

  ngOnInit(): void {
    if (screen.width >= 280 && screen.width <= 600) {
      this.isPhoneWidth = true;
    } else if (screen.width >= 600 && screen.width <= 1368) {
      this.isTabletWidth = true;
    } else {
      this.isPhoneWidth = false;
      this.isTabletWidth = false;
    }

    this.isChecked = false;
  }

  transformToSnakecase(val: string): string {
    return val.replaceAll(' ', '_').toLowerCase();
  }

  checkSelectAll(value: any): boolean {
    let checkBoxStatus;
    if (this.isInitialLoad) {
      checkBoxStatus = value;
    } else {
      checkBoxStatus = false;
    }
    return checkBoxStatus;
  }

  checkAllChanges(event: any) {
    if (event.checked) {
      this.isChecked = event.checked;
      this.checkAll();
      const selectedIds = this.selection.selected.map(i => i.id);
      this.selectedItems.emit(selectedIds);
    } else {
      this.isChecked = event.checked;
      this.uncheckAll();
      const selectedIds = this.selection.selected.map(i => i.id);
      this.selectedItems.emit(selectedIds);
    }
  }

  checkAll(): void {
    this.selection.select();
    this.selection.select(...this.dataSource.data.filter((datum: any) => datum?.id !== 'signsecure'));
  }

  uncheckAll(): void {
    this.selection.clear();
  }

  getClassTh(column: string): string {
    return this.hasCheckBox && column === ' '
      ? 'header checkbox-container'
      : 'header';
  }
  getClassTd(column: string): string {
    return this.hasCheckBox && column === ' ' ? `td__${this.transformToSnakecase(column)} checkbox-container` : `td__${this.transformToSnakecase(column)}`;
  }

  getObjectImage(fileType: string) {
    switch (fileType) {
      case 'file':
        return '../../../assets/images/icons/file.png';
      case 'folder':
        return '../../../assets/images/icons/folder.png';
      default:
        return '../../../assets/images/icons/file.png';
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  hasSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  select(event: any, element: any) {
    if (event.checked) {
      this.selection.select(element);
    } else {
      this.selection.deselect(element);
    }
    const selectedIds = this.selection.selected.map(i => i.id);

    this.selectedItems.emit(selectedIds);
  }

  openFile(data: any): void {
    this.modifyFile = true;
  }

  modifyFileEvent(data: any): void {
    this._fileActionService.file = data;
    this._fileActionService.breadCrumbs;
    if (!this.isPhoneWidth && !this.isTabletWidth) {
      this.modifyFile = !this.modifyFile;
      this._fileActionService.setIsModify(this.modifyFile);
    }
  }

  async generateBreadcrumbs(fileDatas: any[], file: any) {
    if (file.parent) {
      const { data } = await this.getFile(file.parent);
      fileDatas.push(data);
      await this.generateBreadcrumbs(fileDatas, data);
    }
  }

  async getFile(fileId: string) {
    return await lastValueFrom(this._fileService.getFile(fileId));
  }

  getStatusColor(status: string) {
    switch (status) {
      case 'PENDING':
        return 'grey';
      case 'VIEWED':
      case 'SIGNED':
      case 'APPROVED':
      case 'COMPLETED':
        return 'green';
      case 'DECLINED':
      case 'REJECTED':
        return 'red';
      default:
        return 'grey';
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
