import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignSecureService } from '../../services/sign-secure.service';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/@shared/services/settings.service';

@Component({
  selector: 'app-template-navbar',
  templateUrl: './template-navbar.component.html',
  styleUrls: ['./template-navbar.component.sass'],
})
export class TemplateNavbarComponent implements OnInit {
  @Input()
  step: Number | null = 0;

  @Input('pageNumber')
  index = 1;

  @Input()
  totalPages = 11;

  @Input()
  updatePage: Function = () => { }

  @Input()
  addedAll: boolean = false

  @Output()
  review: EventEmitter<boolean> = new EventEmitter<boolean>(false)

  zoom: number = 1
  backTo: string = ''
  logoSrc = this._settingsService.logoSrc$
  constructor(private _navService: NavigationService, private _signsecure: SignSecureService, private _router:Router, private _settingsService: SettingsService) { }

  ngOnInit(): void {
    console.log({ pageNumber: this.index })
    this.backTo = this._navService.getLastLocation()
  }

  goBack() {
    this._signsecure.clearWorkFlowData();
    if(this.backTo === 'Dashboard') {
      this._router.navigateByUrl('/dashboard');
    } else {
      this._router.navigateByUrl(this._navService.prevLocation);  
    }
    //this._navService.hideWorkFlow();
  }

  pageNavi(navi: string) {
    switch (navi) {
      case "up":
        if (this.index > 1) {
          this.index = this.index - 1;
        }
        break;
      case "down":
        if (this.index < this.totalPages) {
          this.index = this.index + 1;
        }
        break;
      default:
        break;
    }
    console.log({ newIndex: this.index })
    this._signsecure.setAddFieldPdfNumber(this.index)
  }

  zoomLevel(level: string) {
    switch (level) {
      case "in":
        if (this.zoom <= 2.75) {
          this.zoom = +this.zoom + +0.25;
        }
        break;
      case "out":
        if (this.zoom >= 0.5) {
          this.zoom = this.zoom - 0.25;
        }
        break;
      default:
        break;
    }
    this._signsecure.setAddFieldZoom(this.zoom)
  }

  reviewData() {
    if (this.addedAll) {
      this.review.emit(true)
    }
  }

  updateZoom(event: any) {
    this.zoom = +event.target.value
    this._signsecure.setAddFieldZoom(this.zoom)
  }
}
