import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsService } from 'src/app/@shared/services/settings.service';

@Component({
  selector: 'app-settings-preview',
  templateUrl: './settings-preview.component.html',
  styleUrls: ['./settings-preview.component.sass'],
})
export class SettingsPreviewComponent implements OnInit {
  primaryColor: string = '';
  menuBackgroundColor: string = '';
  backgroundColor: string = '';
  textColor: string = '';
  topbarColor: string = '';
  hoverColor: string = '';

  subs: Subscription = new Subscription();
  logo: string = ''
  constructor(private _settings: SettingsService) {}

  ngOnInit(): void {
    this.subs.add(
      this._settings.previewTheme$.subscribe((theme) => {
        console.log({theme})
        this.primaryColor = `--primary-color: ${theme.primaryColor};`;
        this.menuBackgroundColor = `--secondary-color: ${theme.menuBackgroundColor};`;
        this.backgroundColor = `--background-color: ${theme.backgroundColor};`;
        this.textColor = `--text-color: ${theme.textColor};`;
        this.topbarColor = `--topbar-color: ${theme.topbarColor};`;
        this.hoverColor = `--hover-color: ${theme.hoverColor};`;
        this.logo = theme.logo
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
