import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.sass']
})
export class AuditTrailComponent implements OnInit {


  @Input()
  data: any

  @Input()
  show: boolean = true

  @Output()
  close: EventEmitter<boolean> = new EventEmitter<boolean>(false)
  constructor() { }

  ngOnInit(): void {
    this.data.reverse();
  }

}
