import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AdvanceTaggingService } from '../service/advance-tagging.service';

@Component({
  selector: 'advanced-tagging-matter-details',
  templateUrl: './matter-details.component.html',
  styleUrls: ['./matter-details.component.sass'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule],
})
export class AdvanceTaggingMatterDetails implements OnInit {
  private readonly aTaggingService = inject(AdvanceTaggingService);
  constructor() {}

  ngOnInit(): void {}
  nextStep() {
    this.aTaggingService.setATProgress(2);
  }
}
