import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription, switchMap } from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { UsersService } from 'src/app/@shared/services/users.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.sass'],
})
export class DeleteUserComponent implements OnInit, OnDestroy {
  @Output() closeModalEvent = new EventEmitter();
  @Input() data: ModalData = {
    type: '',
    action: '',
  };
  private _subs = new Subscription();
  constructor(private _usersService: UsersService) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  deleteUser(): void {
    this._subs = this._usersService
      .deleteUser(this.data.data)
      .pipe(
        switchMap((i) => {
          return this._usersService.getUsers();
        })
      )
      .subscribe((result) => {
        this._usersService.setUsers(
          this._usersService.formatUsers(result.data)
        );
        this.closeModal();
      });
  }
  closeModal(): void {
    this.closeModalEvent.emit();
  }
}
