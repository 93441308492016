<div class="breadcrumbs-wrapper">
  <div
    class="breadcrumb"
    *ngFor="let datum of data; let i = index"
    (click)="onBack.emit(datum)"
  >
    <span class="text {{ datum.title.length > 20 ? 'limit-text' : '' }}">{{
      datum.title
    }}</span>
    <span class="icon" *ngIf="data.length - 1 !== i"></span>
  </div>
</div>
