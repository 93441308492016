import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { SubscriptionModel } from 'src/app/@shared/models/Subscription.model';
import { SubscriptionService } from 'src/app/@shared/services/subscription.service';

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.sass'],
})
export class SubscriptionCardComponent implements OnInit, AfterViewInit {
  element: any;
  isLoaded: boolean = false;
  @Input() selected: number = 0;

  @Input() data: SubscriptionModel = {
    tier: '',
    price: '',
    plan: '',
    details: [],
  };
  @Input() index: number = -1;
  constructor(private subsService: SubscriptionService) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    
    this.isLoaded = true;
  }
  getrecord(data: MatCheckbox) {
    this.subsService.setIndex(Number(data.id.split('-').pop()) - 1);
  }
}
