import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FolderModel } from 'src/app/@shared/models/folder.model';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { FilesService } from 'src/app/@shared/services/files.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { catchError } from 'rxjs/internal/operators/catchError';
import { throwError } from 'rxjs/internal/observable/throwError';
import { of } from 'rxjs';
import { BreadcrumbsService } from 'src/app/@shared/services/breadcrumbs.service';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.sass'],
})
export class CreateFolderComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  @Input() data: ModalData = {
    type: '',
    action: '',
  };
  error: boolean = false;
  name!: string;
  errorMsg: any;

  constructor(
    private _fileService: FilesService,
    private _snackBar: MatSnackBar,
    private _bread: BreadcrumbsService
  ) {}

  ngOnInit(): void {}

  createFolder() {
    const folderData: FolderModel = {
      name: this.name,
      type: 'folder',
    };

    const dataLength = this.data.data?.length;

    if (dataLength && dataLength > 0) {
      folderData.parent = this.data.data[dataLength - 1].id;
    }

    this._fileService
      .createFolder(folderData)
      .pipe(
        catchError(err => {
          if (err.status == 400) {
            this.errorMsg = err.error.error;
            this.errorMsg = this.errorMsg.slice(15);
            this.error = true;
          }
          return of();
        })
      )
      .subscribe(i => {
        if (i) {
          this.getUpdatedData();
          this._snackBar.openFromComponent(SnackbarComponent, {
            data: { type: 'FolderCreated' },
            duration: 3000,
          });
          this.closeModal();
        }
      });
  }

  getUpdatedData() {
    const dataLength = this.data.data?.length;

    if (dataLength && dataLength > 0) {
      this._fileService
        .getAllFolderFiles(this.data.data[dataLength - 1].id)
        .subscribe(({ data }) => {
          this.setFoldersAndFilesData(data);
        });
    } else {
      this._fileService.getAllFilesAndFolder().subscribe(data => {
        this.setFoldersAndFilesData(data.data.data);
      });
    }

    this._fileService.getAllFolder().subscribe(({ data }) => {
      const formattedFolders = this._fileService.formatFolder(data);
      this._fileService.setFolderAsync(formattedFolders);
    });
  }

  setFoldersAndFilesData(data: any) {
    const formattedFilesAndFolder =
      this._fileService.formatFilesAndFolderData(data);

    if (!!!this._bread.breadcrumbsData.length) {
      // const signSecure = {
      //   id: "signsecure",
      //   title: " ‌SignSecure workflow documents",
      //   file_type: "folder",
      //   status: "",
      // };

      this._fileService.setFilesAndFolderAsync([...formattedFilesAndFolder]);
    } else {
      this._fileService.setFilesAndFolderAsync([...formattedFilesAndFolder]);
    }
  }

  closeModal() {
    this.closeModalEvent.emit();
  }
}
