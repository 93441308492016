<div>
  <mat-card class="container-login">
    <div class="login-card-form">
      <div>
        <div class="flexGrid">
          <h2 class="heading2">Hello again!</h2>
          <h4 class="heading4">We're glad you're back. Please sign in to access your account</h4>
        </div>
        <form name="loginForm" [formGroup]="loginForm">
          <div class="flex padding-left">
            <mat-label class="label">Email Address</mat-label>
          </div>
          <div class="flex padding-left">
            <div class="form login-form">
              <mat-form-field
                appearance="fill">
                <input
                  type="email"
                  placeholder="Enter Email Here"
                  matInput
                  formControlName="username"
                  (ngModelChange)="errorMsg = ''"
                  [ngClass]="
                    (loginForm.controls['username'].invalid || errorMsg) &&
                    !loginForm.controls['username'].value &&
                    (loginForm.controls['username'].touched || loginForm.controls['username'].dirty) || isUsernameEmpty
                      ? 'textfieldRegInvalid'
                      : 'textfieldReg'
                  "
                  (keyup)="keyLogin($event)" />
              </mat-form-field>
              <ng-container *ngIf="loginForm.controls['username']">
                <mat-error
                  class="longError"
                  *ngIf="
                    loginForm.controls['username'].value?.length <= 0 &&
                    (loginForm.controls['username'].touched || loginForm.controls['username'].dirty) || isUsernameEmpty
                  "
                  >Email Address is Required</mat-error
                >
                <mat-error
                  class="longError"
                  *ngIf="
                    loginForm.controls['username'].invalid &&
                    loginForm.controls['username'].value?.length > 0
                  "
                  >Email Address format is invalid</mat-error
                >
                <mat-error
                  class="longError"
                  *ngIf="
                    errorMsg && loginForm.controls['username'].value?.length > 0
                  "
                  >{{ errorMsg }}</mat-error
                >
              </ng-container>
            </div>
          </div>
          <div class="flex padding-left">
            <mat-label class="label">Password</mat-label>
          </div>
          <div class="flex padding-left">
            <div class="form login-form password">
              <mat-form-field
                appearance="fill">
                <input
                  [type]="hide ? 'password' : 'text'"
                  placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                  matInput
                  formControlName="password"
                  [ngClass]="
                    loginForm.controls['password'].invalid &&
                    (loginForm.controls['password'].touched || loginForm.controls['password'].dirty) || isPasswordEmpty
                      ? 'textfieldRegInvalid'
                      : 'textfieldReg'
                  "
                  (keyup)="keyLogin($event)" />
                <mat-icon matSuffix (click)="hide = !hide">
                  {{ hide ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </mat-form-field>
              <ng-container  *ngIf="loginForm.controls['password'].touched || loginForm.controls['password'].dirty || isPasswordEmpty">
                <mat-error class="longError" *ngIf="!loginForm.controls['password'].value" 
                  >Password is Required</mat-error
                >
                <mat-error class="longError"
                  *ngIf="
                    loginForm.controls['password'].invalid &&
                    loginForm.controls['password'].value?.length > 0
                  "
                  >Password is Invalid</mat-error
                >
              </ng-container>
            </div>
          </div>
        </form>
        <div class="flexSpaceBetween">
          <div class="col">
            <!-- class="padding-left20" -->
            <mat-checkbox 
            [(ngModel)]="rememberMe" 
            [ngClass]="rememberMe ? 'check-css padding-left20' : 'padding-left20'"
            
              >Remember Me</mat-checkbox
            >
          </div>
          <div style="flex-basis: 5%"></div>
          <div class="col align-right">
            <label class="label-fpw" (click)="goTo('recoverPassword')"
              ><a class="sidebar-item" routerLink="/recover-password">
                Forgot Password
              </a></label
            >
          </div>
        </div>

        <div class="flex padding-left padding margin-top">
          <button matRipple class="signin-btn" (click)="login()" >
            <label class="btn-font">Sign In</label>
          </button>
        </div>
        <div class="flex padding-left padding padding-top-16">
          <button matRipple class="signin-face-recognition-btn">
            <label class="btn-font">Sign In with Face Recognition</label>
          </button>
        </div>

        <div class="flex padding-left padding padding-top40 line-flex">
          <img class="line" src="../../../assets/images/forma/line.png" alt="" />
          <span class="or-sign-in-text">Or Sign In With</span>
          <img class="line" src="../../../assets/images/forma/line.png" alt="" />
        </div>

        <div class="flex padding-left padding-top40">
          <button matRipple class="google-signin-btn" (click)="googleLogin()">
            <label class="btn-font-google"
              ><img src="../../../assets/images/forma/google-logo.svg" /> 
              <!-- Sign in with Google -->
              </label
            >
          </button>
        </div>

        <div class="flexCenter padding-top30 padding-left" *ngIf="regFlag">
          <label class="no-account">Don't have an account yet? </label>
          <label class="label-clickable">
            <a class="sidebar-item" routerLink="/register"> Register here </a>
          </label>
        </div>
      </div>
    </div>
  </mat-card>
</div>
