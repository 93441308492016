<mat-card class="subs-card" *ngIf="data != undefined">
  <div class="card-container">
    <div>
      <mat-checkbox
        [checked]="selected === index"
        #checkbox="matCheckbox"
        (change)="selected = index; getrecord(checkbox)"
      ></mat-checkbox>
    </div>
    <div class="card-content">
      <div class="plan">
        <div class="type">
          <a class="bold-header emphasize">{{ data.tier }}</a>
          <a>{{ data.plan }}</a>
        </div>
        <div class="price">
          <a class="bold-header">{{ data.price }}</a>
        </div>
      </div>
      <span class="space"></span>
      <div *ngFor="let details of data.details">
        <a>{{ details.planDetails }}</a>
        <br />
      </div>

      <!--<a>Send unlimited documents</a>
      <a>for signing per month</a>
      <br />
      <a>10GB of storage</a>!-->
    </div>
  </div>
</mat-card>
