import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of, switchMap } from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { FilesService } from 'src/app/@shared/services/files.service';
import { InboxService } from 'src/app/@shared/services/inbox.service';

@Component({
  selector: 'app-delete-inbox',
  templateUrl: './delete-inbox.component.html',
  styleUrls: ['./delete-inbox.component.sass'],
})
export class DeleteInboxComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  @Input() data: ModalData = {
    type: '',
    action: '',
  };

  constructor(
    private _fileService: FilesService,
    private _inboxService: InboxService
  ) {}

  ngOnInit(): void {}

  deleteMessage() {
    this.data.data.forEach((select: any) => {
      this._inboxService
        .deleteMessages(select.id)
        .pipe(
          switchMap(() => {
            return this._inboxService.getMessages();
          })
        )
        .subscribe((i: any) => {
          this.closeModal(true);
          this._inboxService.setMessages(
            i.data.result.map((res: any) => this._inboxService.format(res))
          );
        });
    });
  }

  closeModal(confirm?: boolean) {
    this.closeModalEvent.emit(confirm);
  }
}
