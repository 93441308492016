<app-wrapper-background>
  <app-logo-container></app-logo-container>
  <div class="email-sent-container">
    <!-- <img src="../../../../assets/images/forma/forma-white-logo.svg" /> -->
    <mat-card class="container">
      <div class="wrapper" id="email">
        <h2 class="header">Email sent</h2>
        <!-- *ngIf="type == 'email-sent'" -->
        <h4 class="description" *ngIf="type == 'email-sent'">
          Click the link that was sent to your email to complete 
          the verification process. You will only be able to
          access the dashboard once you are verified.
        </h4>
        <h4 class="description" *ngIf="type == 'email-resetpw'">
          We’ve sent you instructions to recover your password in your email.
        </h4>
        <a class="btn-next" routerLink="/login">
          <label class="btn-font"> Back to sign in </label>
        </a>
        <p class="other">
          Did not receive the email? Check your spam or junk folder
        </p>
      </div>
    </mat-card>
  </div>
</app-wrapper-background>
