import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CardPaymentModel } from "../models/Payment.model";

@Injectable({
    providedIn: 'root',
  })
  export class PaymentService{
    private readonly _paymentUrl = `${environment.apiConfig.baseUrl}${environment.apiConfig.payment.url}`;
    constructor(private _httpClient: HttpClient) {}

    cardPayment(card:CardPaymentModel){
       
        return this._httpClient.post(`${this._paymentUrl}/card`,card);
    }
  }