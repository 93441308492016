import { Component, Input, OnInit } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.sass'],
})
export class PreviewComponent implements OnInit {
  @Input()
  id: string = '';

  zoom: number = 1;
  totalPages: number = 0;
  pageNumber: number = 0;

  @Input()
  src: string = '';

  constructor() {}

  ngOnInit(): void {}

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
    this.pageNumber = 1;
  }

  prevPage() {
    if (this.pageNumber !== 1) {
      this.pageNumber--;
    }
  }

  nextPage() {
    if (this.pageNumber !== this.totalPages) {
      this.pageNumber++;
    }
  }

  updateZoom(level: string) {
    switch (level) {
      case 'in':
        this.zoom = +this.zoom + +0.25;
        break;
      case 'out':
        this.zoom = +this.zoom - 0.25;
        break;
      default:
        break;
    }
  }
}
