<form class="delete-case-container">
  <h4 class="title">Delete Case</h4>
  <h4 class="sub-title">Are you sure you want to delete “Case Title”?</h4>
  <div class="content">
    <div class="actions">
      <button matRipple class="cancel">Cancel</button>
      <button matRipple class="create">Delete</button>
    </div>
  </div>
</form>
