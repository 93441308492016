import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Input,
  ViewChildren, 
  QueryList, 
  ElementRef,
  Renderer2
} from '@angular/core';
import {
  interval,
  of,
  Subscription,
  switchMap,
  takeUntil,
  takeWhile,
} from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { SignatureService } from 'src/app/@shared/services/signature.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-signature',
  templateUrl: './add-signature.component.html',
  styleUrls: ['./add-signature.component.sass'],
})
export class AddSignatureComponent implements OnInit, OnDestroy {
  @Output() closeModalEvent = new EventEmitter();
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef>;
  sub = new Subscription();
  timer = new Subscription();
  time = 100;
  image: string = '';
  value: any;
  reader = new FileReader();
  otp: any = '';
  otpValue: string = '';
  otpError: string = '';
  formattedTime:any;
  @Input() isOTPValid: boolean = false;
  constructor(
    private _signatureService: SignatureService,
    private _userService: UsersService,
    private _auth: AuthenticationService,
    private renderer: Renderer2,
    private _router: Router
  ) {
    this.reader.onload = (e: any) =>
      (this.image = this.reader.result as string);
  }

  ngOnInit(): void {}
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.timer.unsubscribe();
  }

  onChangeOption({ value }: any) {}

  dragover(data: FileList) {
    this.value = data[0];
    this.image = data[0].name;
    this.reader.readAsDataURL(this.value);
    // this.closeModalEvent.emit(data);
  }

  checkIfContainsFolder(data: FileList) {
    for (let i = 0; i < data.length; i++) {
      if (data.item(i)?.type === '') {
        return true;
      }
    }
    return false;
  }

  onFileSelected(data: any) {
    const regex = /.*\.(jpg|JPG|png|PNG)$/g;
    let files = [];

    if (data.path) {
      this.value = data.path[0].files[0];
      this.image = this.value.name;
      this.reader.readAsDataURL(this.value);
    } else if (data.target) {
      this.value = data.target.files[0];
      this.image = this.value.name;
      this.reader.readAsDataURL(this.value);
    }
    // this.closeModalEvent.emit(files);
  }
  startTimer() {
    this.timer = interval(1000).subscribe((timeData) => {
      
      this.time = this.time - 1;
    });
    return this.timer;
  }
  upload() {
    document.getElementById('fileUpload')?.click();
  }

  close() {
    this.closeModalEvent.emit();
  }

  chooseAnother() {
    this.image = '';
    this.value = {};
    this.upload();
  }

  sendOTP() {
    this.otp = 'otp';
  
    this.sub = this._userService
      .generateOTP()
      .pipe(
        switchMap(() => {
          return interval(1000).pipe(
            takeWhile(() => this.time >= 1)
          );
        })
      )
      .subscribe(() => {
        // Decrease time by 1 second
        this.time = this.time - 1;
  
        // Calculate hours, minutes, and seconds
        const hours = Math.floor(this.time / 3600);
        const minutes = Math.floor((this.time % 3600) / 60);
        const seconds = this.time % 60;
  
        // Format time as `hh:mm:ss`
        this.formattedTime = `${this.pad(minutes)}:${this.pad(seconds)}`;
  
        // Optional: Stop timer when it reaches 0
        if (this.time <= 0) {
          this.sub.unsubscribe();
          this.time = 0;
        }
      });
  }
  
  // Helper method to pad single-digit numbers with leading zero
  pad(num:any) {
    return num.toString().padStart(2, '0');
  }
  

  inputOtp(data: any) {
    this.otpValue = data?.target?.value || '';
    
  }

  verifyOtp() {
    
    this.sub.add(
      this._userService
        .validateOTP(this.otpValue)
        .pipe(
          switchMap((data) => {
            

            if (data.data.valid) {
              return this._signatureService.addSignature({
                name: `signature-${this._auth.userId}-${
                  this._signatureService.total + 1
                }`,
                file: this.value,
              });
            } else {
              this.otpInputs.toArray()[1].nativeElement.focus();
              this.otpError = 'Please enter a valid code';
              this.clearInputsAndChangeBorderColor('red')
              return of();
            }
          })
        )
        .subscribe((i) => {
          
          // this._router.navigate(['signatures'])
          this.isOTPValid = true;
          this.close();
        })
    );
  }

 // Method to clear input fields and change border color
  private clearInputsAndChangeBorderColor(color: string): void {
    this.otpInputs.forEach(input => {
      const element = input.nativeElement as HTMLInputElement;

      // Clear the value of the input
      element.value = '';

      // Change the border color to red
      this.renderer.setStyle(element, 'borderColor', color);
      this.otpInputs.toArray()[0].nativeElement.focus();
    });
  }

  navigateToDashboard(){
    this._router.navigate(['dashboard'])
  }

  moveFocus(event: Event, index: number) {
    this.otpError = ''
    const target = event.target as HTMLInputElement;
    this.otpValue = this.otpValue + target.value
    if (target.value.length === 1) {
      if (index < this.otpInputs.length - 1) {
        this.otpInputs.toArray()[index + 1].nativeElement.focus();
      } else {
        this.verifyOtp()
      }
    }
  }
}
