<div class="mat-DocDetails-container">
  <div class="header">Document Details</div>
  <div class="content">
    <div class="col">
      <div class="input-container">
        <mat-label class="label">Barcode</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container">
        <mat-label class="label">Document Name</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container">
        <mat-label class="label">Folder Number</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container">
        <mat-label class="label">Date of Document</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container">
        <mat-label class="label">Date</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container center">
        <mat-radio-group class="options-container" [selected]="selected">
          <mat-radio-button value="1" disableRipple #selected
            >Received</mat-radio-button
          >
          <mat-radio-button value="2" disableRipple
            >Internal Record</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div class="input-container">
        <mat-label class="label">Case Title</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container">
        <mat-label class="label">Handling Lawyer</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>
    </div>
    <div class="col">
      <div class="input-container">
        <mat-label class="label">Notes</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container">
        <mat-label class="label">Court</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container">
        <mat-label class="label">Court Branch</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container">
        <mat-label class="label">Court Region</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>

      <div class="input-container">
        <mat-label class="label">Court Location</mat-label>
        <mat-form-field><input matInput /></mat-form-field>
      </div>
    </div>
  </div>
  <div class="btn-container">
    <button class="btn" (click)="nextStep()">Next</button>
  </div>
</div>
