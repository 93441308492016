import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignatureService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly signatureUrl = environment.apiConfig.signature.url;
  private readonly _signatureUrl = `${this.baseUrl}${this.signatureUrl}`;

  private signatures = new BehaviorSubject<any>([]);
  signatures$ = this.signatures.asObservable();
  total: number = 0;

  constructor(private _httpClient: HttpClient) {}

  getSignaturesStatic() {
    return [
      {
        created_at: '18 Jul 2022',
        image_type: 'JPEG',
        signature: '../../../assets/images/signature.svg',
        actions: {
          delete: '1',
        },
      },
      {
        created_at: '18 Jul 2022',
        image_type: 'JPEG',
        signature: '../../../assets/images/signature.svg',
        actions: {
          delete: '1',
        },
      },
      {
        created_at: '18 Jul 2022',
        image_type: 'JPEG',
        signature: '../../../assets/images/signature.svg',
        actions: {
          delete: '1',
        },
      },
      {
        created_at: '18 Jul 2022',
        image_type: 'JPEG',
        signature: '../../../assets/images/signature.svg',
        actions: {
          delete: '1',
        },
      },
      {
        created_at: '18 Jul 2022',
        image_type: 'JPEG',
        signature: '../../../assets/images/signature.svg',
        actions: {
          delete: '1',
        },
      },
    ];
  }

  setSignatures(data: any) {
    this.total = data.length || 0;
    this.signatures.next(data);
  }

  addSignature(data: any) {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.name);

    return this._httpClient.post(`${this._signatureUrl}`, formData);
  }

  formatSignatures(data: any) {
    return data?.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((datum: any) => {
      return {
        created_at: datum.createdAtFormatted,
        image_type: datum.imageType,
        signature: datum.path,
        actions: {
          delete: datum.id,
        },
      };
    });
  }

  getSignatures() {
    return this._httpClient.get(`${this._signatureUrl}/all`);
  }

  getSignature(id: string) {
    return this._httpClient.get(`${this._signatureUrl}/${id}`);
  }

  deleteSignature(id: string) {
    return this._httpClient.delete(`${this._signatureUrl}/${id}`);
  }
}
