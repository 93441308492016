<ng-content *ngIf="!addUserComplete">
  <h4 class="title">Add a new user</h4>
  <form class="add-new-user" [formGroup]="addNewUserForm">
  <mat-card appearance="outlined" class="add-new-user-card">
    <mat-card-content>
        <div class="header-align">
          <div class="header">Personal Info</div>
          <div class="sub-header">Update the users personal information</div>
        </div>
        <div class="content">
          <div class="form-field-pair">
            <div class="input-group">
              <mat-label class="label">First Name</mat-label>
              <mat-form-field
                [ngClass]="
                  addNewUserForm.controls['givenName'].invalid &&
                  addNewUserForm.controls['givenName'].dirty
                    ? 'form-field-error'
                    : 'form-field'
                "
                
                appearance="fill">
                <input
                  type="name"
                  matInput
                  placeholder="First Name"
                  formControlName="givenName" />
  
                <span
                  class="error-code"
                  *ngIf="
                    addNewUserForm.controls['givenName'].value?.length <= 0 &&
                    addNewUserForm.controls['givenName'].dirty
                  "
                  >Given name is required</span
                >
              </mat-form-field>
            </div>
            <div class="input-group">
              <mat-label class="label">Last Name</mat-label>
              <mat-form-field
                [ngClass]="
                  addNewUserForm.controls['lastName'].invalid &&
                  addNewUserForm.controls['lastName'].dirty
                    ? 'form-field-error'
                    : 'form-field'
                "
                
                appearance="fill">
                <input
                  type="name"
                  matInput
                  placeholder="Last Name"
                  formControlName="lastName" />
                <span
                  class="error-code"
                  *ngIf="
                    addNewUserForm.controls['lastName'].value?.length <= 0 &&
                    addNewUserForm.controls['lastName'].dirty
                  "
                  >Last name is required</span
                >
              </mat-form-field>
            </div>
          </div>
          <div class="form-field-pair">
            <div class="input-group">
              <mat-label class="label">Email</mat-label>
              <mat-form-field
                [ngClass]="
                  (addNewUserForm.controls['email'].invalid &&
                    addNewUserForm.controls['email'].dirty) ||
                  isEmailTaken
                    ? 'form-field-error'
                    : 'form-field'
                "
                
                appearance="fill">
                <input
                  type="email"
                  matInput
                  placeholder="Email"
                  formControlName="email"
                  (ngModelChange)="errorMsg = ''; isEmailTaken = false" />
              </mat-form-field>
              <span
                class="error-code"
                *ngIf="
                  addNewUserForm.controls['email'].invalid &&
                  addNewUserForm.controls['email'].dirty
                "
                >Please enter a valid email</span
              >
              <span class="error-code" *ngIf="errorMsg && isEmailTaken">{{
                errorMsg
              }}</span>
            </div>
            <div class="input-group">
              <mat-label class="label">Position</mat-label>
              <mat-form-field class="form-field"  appearance="fill">
                <input
                  type="name"
                  matInput
                  placeholder="Position"
                  formControlName="position" />
              </mat-form-field>
            </div>
          </div>
          <div class="form-field-pair">
            <div class="input-group">
              <mat-label class="label">Role</mat-label>
              <mat-form-field class="form-field"  appearance="fill">
                <mat-select formControlName="role" panelClass="role-select">
                  <mat-option value="admin" selected> Admin</mat-option>
                  <mat-option value="user"> User</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-group">
              <mat-label class="label">Office</mat-label>
              <mat-form-field class="form-field"  appearance="fill">
                <input
                  type="text"
                  matInput
                  placeholder="Office"
                  formControlName="office" />
              </mat-form-field>
            </div>
          </div>
          <hr />
          <div class="header-align">
            <div class="header">Password</div>
            <div class="sub-header">Set the users password for their account</div>
          </div>
          <div class="input-group">
            <mat-label class="label">New Password</mat-label>
            <mat-form-field
              [ngClass]="
                (addNewUserForm.controls['newPassword'].dirty &&
                  addNewUserForm.controls['newPassword'].invalid) ||
                !isPwordMatch
                  ? 'form-field-error'
                  : 'form-field'
              "
              
              appearance="fill">
              <input
                [type]="hide ? 'password' : 'text'"
                formControlName="newPassword"
                placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                (ngModelChange)="checkPassword()"
                matInput />
              <mat-icon matSuffix (click)="hide = !hide">{{
                hide ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </mat-form-field>
            <mat-error
              *ngIf="
                addNewUserForm.controls['newPassword'].invalid &&
                addNewUserForm.controls['newPassword'].dirty
              "
              >Password must be at least 8 characters<br />
              Contains at least 1 lower case<br />
              Contains at least 1 upper case<br />
              Contains at least 1 number<br />
              Contains at least 1 special character</mat-error
            >
            <span class="error-code" *ngIf="!isPwordMatch">{{ pwordErrorMsg }}</span>
          </div>
          <div class="input-group">
            <mat-label class="label">Confirm Password</mat-label>
            <mat-form-field
              [ngClass]="
                (addNewUserForm.controls['confirmPassword'].dirty &&
                  addNewUserForm.controls['confirmPassword'].invalid) ||
                !isPwordMatch
                  ? 'form-field-error'
                  : 'form-field'
              "
              
              appearance="fill">
              <input
                [type]="confirmHide ? 'password' : 'text'"
                formControlName="confirmPassword"
                placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                (ngModelChange)="checkPassword()"
                matInput />
              <mat-icon matSuffix (click)="confirmHide = !confirmHide">{{
                confirmHide ? 'visibility_off' : 'visibility'
              }}</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="actions">
      <button matRipple class="cancel" (click)="closeModal()">Cancel</button>
      <button matRipple class="create" (click)="createUser()">
        Create User
      </button>
    </div>
  </form>
</ng-content>

<div class="success-added-user" *ngIf="addUserComplete">
  <img src="../../../../assets/images/email-sent.svg" alt="">
  <h2>Email Sent</h2>
  <p>We've sent an email to {{addNewUserForm.value.email}} with further instructions.</p>
  <button matRipple class="create" (click)="navigateToDashboard()">
    Go back to dashboard
  </button>
</div>