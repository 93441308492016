<div class="settings-form-container">
  <div class="section">
    <h5 class="section-title">Company logo</h5>
    <p class="section-description">Update your company logo here</p>

    <div class="section-input image">
      <img [src]="theme.logo" alt="" class="logo" />
      <button (click)="changeLogo()">Choose file</button>
    </div>
  </div>
  <div class="section">
    <h5 class="section-title">Color settings</h5>
    <p class="section-description">Update your dashboard colors here</p>

    <div class="section-input color">
      <p class="text">Primary/button color</p>
      <app-color-picker
        *ngIf="customColorPicker; else picker"
        [color]="theme.primaryColor"
        (changes)="updateColor('primaryColor', $event)"></app-color-picker>
    </div>
    <div class="section-input color">
      <p class="text">Menu/cards background color</p>
      <app-color-picker
        [color]="theme.menuBackgroundColor"
        (changes)="
          updateColor('menuBackgroundColor', $event)
        "></app-color-picker>
    </div>
    <div class="section-input color">
      <p class="text">Background Color</p>

      <app-color-picker
        [color]="theme.backgroundColor"
        (changes)="updateColor('backgroundColor', $event)"></app-color-picker>
    </div>
    <div class="section-input color">
      <p class="text">Text Color</p>
      <app-color-picker
        [color]="theme.textColor"
        (changes)="updateColor('textColor', $event)"></app-color-picker>
    </div>
    <div class="section-input color">
      <p class="text">Top Bar Color</p>
      <app-color-picker
        [color]="theme.topbarColor"
        (changes)="updateColor('topbarColor', $event)"></app-color-picker>
    </div>
    <div class="section-input color">
      <p class="text">Hover Color</p>
      <app-color-picker
        [color]="theme.hoverColor"
        (changes)="updateColor('hoverColor', $event)"></app-color-picker>
    </div>
  </div>
  <div class="section">
    <h5 class="section-title">Font settings</h5>
    <p class="section-description">Update your dashboard fonts here</p>

    <div class="section-input font">
      <p class="text">Heading</p>
      <select disabled>
        <option>DMS SANS</option>
        <option>DM SANS Bold</option>
        <option>Lato</option>
        <option>Lato Bold</option>
        <option>Montserrat</option>
        <option>Montserrat Bold</option>
        <option>Open Sans</option>
        <option>Open Sans Bold</option>
        <option>Noto Sans</option>
        <option selected>Noto Sans Bold</option>
        <option>Arial</option>
      </select>
    </div>
    <div class="section-input font">
      <p class="text">Sub heading</p>
      <select disabled>
        <option>DMS SANS</option>
        <option>DM SANS Bold</option>
        <option>Lato</option>
        <option>Lato Bold</option>
        <option>Montserrat</option>
        <option>Montserrat Bold</option>
        <option>Open Sans</option>
        <option>Open Sans Bold</option>
        <option selected>Noto Sans</option>
        <option>Noto Sans Bold</option>
        <option>Arial</option>
      </select>
    </div>
    <div class="section-input font">
      <p class="text">Body</p>

      <select disabled>
        <option selected>DMS SANS</option>
        <option>DM SANS Bold</option>
        <option>Lato</option>
        <option>Lato Bold</option>
        <option>Montserrat</option>
        <option>Montserrat Bold</option>
        <option>Open Sans</option>
        <option>Open Sans Bold</option>
        <option>Noto Sans</option>
        <option>Noto Sans Bold</option>
        <option>Arial</option>
      </select>
    </div>
  </div>
  <div class="section buttons">
    <button matRipple class="preview" (click)="previewTheme()">Preview</button>
    <button matRipple class="save" (click)="updateTheme()">Save</button>
  </div>
  <div class="reset" (click)="resetDefaultTheme()">
    <button matRipple>
      <img src="../../../assets/images/forma/reset.svg" />
    </button>
    <p>Reset to default settings</p>
  </div>
</div>

<input
  type="file"
  id="fileUpload"
  name="fileUpload"
  style="display: none"
  (change)="onFileSelected($event)"
  accept=".jpg,.png"
  multiple />

<ng-template #picker>
  <form name="colorPicker" [formGroup]="colorForm">
    <mat-form-field formControlName="color" style="display: flex;padding:0"
      ><input matInput type="color" #colorInput /><label class="color-picker-label" for="colorInput"
        >Choose a color</label
      ></mat-form-field
    >
  </form>
</ng-template>
