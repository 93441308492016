import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.sass'],
})
export class TemplateCardComponent implements OnInit {
  @Input()
  name: string = 'HR';

  @Input()
  number: number = 20;

  @Input()
  id: string = 'hr';

  @Output()
  clicked = new EventEmitter();

  @Input()
  selected: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
