import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from '../models/breadcrumb.model';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbs.asObservable();
  breadcrumbsData: Breadcrumb[] = [];

  constructor() {}

  getBreadcrumbsData() {
    return [...this.breadcrumbsData];
  }

  setBreadcrumbsData(data: Breadcrumb) {
    if (this.breadcrumbsData.length === 0) {
      this.breadcrumbsData.push({ title: 'Root', id: '' });
    }
    this.breadcrumbsData.push(data);

    this.breadcrumbs.next(this.breadcrumbsData);
    return this.breadcrumbsData;
  }

  removeBreadcrumbData() {
    if (this.breadcrumbsData.length === 2) {
      this.breadcrumbsData.pop();
    }
    this.breadcrumbsData.pop();
    this.breadcrumbs.next(this.breadcrumbsData);
    return this.breadcrumbsData;
  }

  backBreadcrumb(location: number) {
    this.breadcrumbsData = this.breadcrumbsData.slice(0, location + 1);

    this.breadcrumbs.next(this.breadcrumbsData);
    return this.breadcrumbsData;
  }

  emptyBreadcrumb() {
    this.breadcrumbsData = [];

    this.breadcrumbs.next(this.breadcrumbsData);
    return this.breadcrumbsData;
  }
}
