import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class GuestAuthguardService implements CanActivate {
  sessionTimer: number = 0;
  constructor(
    private _authservice: AuthenticationService,
    private _nav: NavigationService,
    public router: Router,
    private idle: Idle
  ) {}

  canActivate(): boolean {
    if (!this._authservice.isLogin()) {
      this.router.navigate(['login']);
      return false;
    }
    
    const user = this._authservice.getUser();

    if(user.role !== 'guest') {
      this._nav.setLocation('login')
      return false
    }

    const sessionTimer: number = +(localStorage.getItem('sTime') || 0);
    this.sessionTimer = sessionTimer;
    this.idle.setIdle(30);
    this.idle.setTimeout(1800);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.pipe(switchMap(() =>
      this._authservice.logout()
    )).subscribe(() => {
      this.router.navigateByUrl("/login");
    })

    this.idle.watch();
    return true;
  }
  
}
