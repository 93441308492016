import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import DataJson from '../../../dummy-data/workflow.json';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  signSecureData:any;
  constructor(private _httpClient: HttpClient) {}
  

  dummyData() {
    return DataJson;
  }

  getUserDocuments(userId: string | null) {
    return this._httpClient.get(
      `${environment.integration.signSecureAPIBaseURL}?id=${userId}`
    );
  }
}
