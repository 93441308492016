import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UserGroupModel } from 'src/app/@shared/models/user-group.model';
import { FilesService } from 'src/app/@shared/services/files.service';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-admin-dashboard-manage-user-groups',
  templateUrl: './admin-dashboard-manage-user-groups.component.html',
  styleUrls: ['./admin-dashboard-manage-user-groups.component.sass'],
})
export class AdminDashboardManageUserGroupsComponent implements OnInit {
  userGroups = new Observable();
  userGroupsCols = this._manageUserGroups.columns;
  searchQuery:string = '';
  userGroupData:any[]=[];
  userGroupDataOrig:any[]=[];
  isCreateGroups: boolean = false;
  isUpdateGroups: boolean = false;
  isGroupSuccess: boolean = false;

  constructor(
    private _manageUserGroups: UserGroupService,
    private _fileService: FilesService
    ) {
    this.userGroups = this._manageUserGroups.userGroups$;
    this.createDataShow()
    this.UpdateDataShow()
  }

  ngOnInit(): void {
    this._manageUserGroups.getUserGroups().subscribe(({ data }) => {
      this.userGroupDataOrig = this._manageUserGroups.format(data);
      this.userGroupData = this.userGroupDataOrig;
      this._manageUserGroups.setUserGroup(this.userGroupDataOrig);
    });
  }

  createDataShow(){
    this._fileService.createData$.subscribe(data => {
      this.isCreateGroups = data.createData;
      this.isGroupSuccess = data.successData;
    });
  }

  UpdateDataShow(){
    this._fileService.updateData$.subscribe(data => {
      this.isUpdateGroups = data.updateData;
      this.isGroupSuccess = data.successData;
    });
  }

  showModal(data: any) {
    this.isCreateGroups = true;
    // const { dialog, type: action } = data;

    // const dialogRef = dialog?.open(ModalComponent, {
    //   width: '636px',
    //   data: { action },
    // });
  }

  backToManageGroups(){
    this.isCreateGroups = false;
    this.isGroupSuccess = false;
    console.log(this.isGroupSuccess)
  }


  search(){
    if (this.searchQuery.trim().length == 0) {
      this.userGroupData = this.userGroupDataOrig;
    } else {
      this.userGroupData = this.userGroupDataOrig.filter((userGroup) => {
        if (userGroup.group_name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          return userGroup;
        } else {return;}
      });
    }
    this._manageUserGroups.setUserGroup(this.userGroupData);
  }
}
