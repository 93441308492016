import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Template } from '../models/template.model';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  // To be bought templates

  private templates = new BehaviorSubject<Template[]>([]);
  templates$ = this.templates.asObservable();
  templateTableColumns: string[] = ['Name', 'Size', 'Rate', 'Actions'];

  // Owners templates
  private myTemplates = new BehaviorSubject<Template[]>([]);
  myTemplates$ = this.myTemplates.asObservable();
  myTemplateTableColumns: string[] = ['Name', 'Size', 'Status', 'Actions'];

  selectedTemplate: Template = {
    id: '',
    name: '',
    size: '',
    actions: {},
  };
  constructor(private _httpClient: HttpClient) {}

  // TODO:: Update function to handle formatting
  formatTemplate(data: any[]) {
    return data;
  }

  // TODO:: Update function to handle formatting
  formatMyTemplate(data: any[]) {
    return data;
  }

  // TODO:: Update function for http retrieval
  getTemplates() {
    const sampleTemplates = [
      {
        id: 'testing_1',
        name: 'Waiver of Pre-Emptive Right',
        size: '4 pages',
        rate: 'PHP 000',
        actions: {
          preview: {
            id: 'testing_1',
            name: 'Waiver of Pre-Emptive Right',
            size: '4 pages',
            rate: 'PHP 000',
          },
        },
      },
      {
        id: 'testing_2',
        name: 'Nondisclosure Agreement',
        size: '5 pages',
        rate: 'PHP 000',
        actions: {
          preview: {
            id: 'testing_2',
            name: 'Nondisclosure Agreement',
            size: '5 pages',
            rate: 'PHP 000',
          },
        },
      },
      {
        id: 'testing_3',
        name: 'Subscription Agreement',
        size: '10 pages',
        rate: 'PHP 000',
        actions: {
          preview: {
            id: 'testing_3',
            name: 'Subscription Agreement',
            size: '10 pages',
            rate: 'PHP 000',
          },
        },
      },
      {
        id: 'testing_4',
        name: 'Board Resolution',
        size: '2 pages',
        rate: 'PHP 000',
        actions: {
          preview: {
            id: 'testing_4',
            name: 'Board Resolution',
            size: '2 pages',
            rate: 'PHP 000',
          },
        },
      },
    ];

    const sampleMyTemplates = [
      {
        id: 'testing_5',
        name: 'Nondisclosure Agreement',
        size: '5 pages',
        status: 'Paid',
        actions: {
          modify: {
            id: 'testing_5',
            name: 'Nondisclosure Agreement',
            size: '5 pages',
            rate: 'PHP 000',
          },
        },
      },
    ];

    this.setTemplates(sampleTemplates);
    this.setMyTemplates(sampleMyTemplates);
  }

  setTemplates(data: Template[]) {
    this.templates.next(data);
  }

  setMyTemplates(data: Template[]) {
    this.myTemplates.next(data);
  }
}
