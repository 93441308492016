<div class="user-groups margin-container" *ngIf="!isCreateGroups && !isUpdateGroups">
  <app-table
    [title]="''"
    [addButton]="'Add New Group'"
    [columns]="userGroupsCols"
    [data]="userGroups | async"
    [type]="'mUserGrp'"
    (modal)="showModal($event)"
  >
    <!-- <div class="tableSearch">
      <div class="search-form">
        <mat-form-field  appearance="fill">
          <input
            matInput
            type="text"
            placeholder="Input Your Text in Here"
            [(ngModel)]="searchQuery"
            (keydown.enter)="search()"
          />
          <span matPrefix
            ><img
              class="search-image"
              src="../../../assets/images/icons/search.png"
              alt=""
          /></span>
        </mat-form-field>
        <button type="submit" class="search-button" (click)="search()">
          Search
        </button>
      </div>
    </div> -->
    </app-table>
</div>
<app-add-user-group *ngIf="isCreateGroups && !isGroupSuccess"></app-add-user-group>
<app-edit-user-group *ngIf="isUpdateGroups && !isGroupSuccess"></app-edit-user-group>

<div class="group-success" *ngIf="isGroupSuccess">
  <img src="../../../../assets/images/icons/success-create-group.svg" alt="">
  <h1>Group has been created</h1>
  <button (click)="backToManageGroups()">Go back to Manage Groups</button>
</div>