<div class="complete">
	<img src="../../../../../assets/images/forma/document-sent-icon.svg" alt="" srcset="">
	<div class="text-content">
		<h4>Document sent</h4>
		<p> Recipients will be notified when it's their turn to </p>
		<p> sign or approve the document. </p>
	</div>

	<div class="buttons">
		<button class="download" >Download Document</button>
		<button class="return" (click)="goBack()">Go to  {{lastLocation}}</button>
	</div>
</div>
