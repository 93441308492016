import { Component, Input, OnInit } from '@angular/core';

interface Tag {
  name: string;
  type: string;
}

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.sass'],
})
export class TagsComponent implements OnInit {
  @Input()
  tags: Tag[] = [];

  shownTags: Tag[] = [];

  show: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if(this.tags)
    this.shownTags = this.tags.slice(0, 3);
    
  }

  more() {
    if (!this.show) {
      this.shownTags = this.tags.slice(0, 10);
    } else {
      this.shownTags = this.tags.slice(0, 3);
    }

    this.show = !this.show;
  }
}
