import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-signature-field-preview",
	templateUrl: "./signature-field-preview.component.html",
	styleUrls: ["./signature-field-preview.component.sass"],
})
export class SignatureFieldPreviewComponent implements OnInit {
	@Input()
	details: any = {};

	@Input()
	signatory: any = null;


	@Input()
	zoom: number = 1

	textColor: any = null;
	color: any
	constructor() { }

	ngOnInit(): void {
		if (this.signatory.color) {
			this.textColor = this.signatory.color;
			this.color = `${this.signatory.color}55`;
		}
	}
}
