import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-color-slider',
  templateUrl: './color-slider.component.html',
  styleUrls: ['./color-slider.component.sass'],
})
export class ColorSliderComponent implements OnInit {
  @ViewChild('canvas')
  canvas!: ElementRef<HTMLCanvasElement>;

  @Output()
  color: EventEmitter<string> = new EventEmitter();

  private ctx: CanvasRenderingContext2D | null = null;
  private mousedown: boolean = false;
  private selectedHeight: number = 1;

  ngAfterViewInit() {
    this.draw();
    this.emitColor(1, 1);
  }

  ngOnInit(): void {}

  draw() {
    if (!this.ctx) {
      this.ctx = this.canvas.nativeElement.getContext('2d');
    }
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    this.ctx!.clearRect(0, 0, width, height);

    const gradient = this.ctx!.createLinearGradient(0, 0, width, 0);
    gradient.addColorStop(0, '#FF0000');
    gradient.addColorStop(0.15, '#F89E1B');
    gradient.addColorStop(0.32, '#FFF500');
    gradient.addColorStop(0.47, '#009D12');
    gradient.addColorStop(0.62, '#09A3FA');
    gradient.addColorStop(0.79, '#212259');
    gradient.addColorStop(0.95, '#8F25C0 ');

    this.ctx!.beginPath();
    this.ctx!.rect(0, 0, width, height);

    this.ctx!.fillStyle = gradient;
    this.ctx!.fill();
    this.ctx!.closePath();

    if (this.selectedHeight) {
      this.ctx!.beginPath();
      this.ctx!.strokeStyle = 'white';
      this.ctx!.lineWidth = 1;
      this.ctx!.rect(this.selectedHeight - 5, 0, 2, height);
      this.ctx!.stroke();
      this.ctx!.closePath();
    }
  }

  @HostListener('window:mouseup', ['$event'])
  onMouseUp(evt: MouseEvent) {
    this.mousedown = false;
  }

  onMouseDown(evt: MouseEvent) {
    this.mousedown = true;
    this.selectedHeight = evt.offsetX;
    this.draw();
    this.emitColor(evt.offsetX, evt.offsetY);
  }

  onMouseMove(evt: MouseEvent) {
    if (this.mousedown) {
      this.selectedHeight = evt.offsetX;
      this.draw();
      this.emitColor(evt.offsetX, evt.offsetY);
    }
  }

  emitColor(x: number, y: number) {
    const rgbaColor = this.getColorAtPosition(x, y);
    this.color.emit(rgbaColor);
  }

  getColorAtPosition(x: number, y: number) {
    const imageData = this.ctx!.getImageData(x, y, 1, 1).data;
    return (
      'rgba(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ',1)'
    );
  }
}
