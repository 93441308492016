<ng-container>
  <form class="edit-user" [formGroup]="editUserForm">
    <h4 class="title">Edit User</h4>
    <div class="content">
      <div class="form-field-pair">
        <div class="input-group">
          <mat-label class="label">First Name</mat-label>
          <mat-form-field  appearance="fill">
            <input
              type="name"
              matInput
              placeholder="Juan"
              formControlName="givenName"
            />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label class="label">Last Name</mat-label>
          <mat-form-field  appearance="fill">
            <input
              type="name"
              matInput
              placeholder="Dela Cruz"
              formControlName="lastName"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="input-group ">
        <mat-label class="label">Email</mat-label>
        <mat-form-field  class="readonly"appearance="fill">
          <input
            type="email"
            matInput
            placeholder="juandelacruz@email.com"
            formControlName="email"
            readonly
          />
        </mat-form-field>
      </div>
      <div class="input-group">
        <mat-label class="label">Position</mat-label>
        <mat-form-field  appearance="fill">
          <input
            type="text"
            matInput
            placeholder="Court Attorney"
            formControlName="position"
          />
        </mat-form-field>
      </div>
      <div class="input-group-role">
        <mat-label class="label">Role</mat-label>
        <mat-form-field  appearance="fill">
          <mat-select
            placeholder="Admin"
            panelClass="role-select"
            formControlName="role"
          >
            <mat-option value="admin"> Admin</mat-option>
            <mat-option value="user"> User</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="actions">
        <button matRipple class="cancel" (click)="closeModal()">Cancel</button>
        <button matRipple class="create" [disabled]="editUserForm.invalid" (click)="updateUser()">Save</button>
      </div>
    </div>
  </form>
</ng-container>
