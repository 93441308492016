<div class="general-settings-container">
  <div class="section">
    <h5 class="section-title">General Settings</h5>
    <!-- <p class="section-description">Update your company logo here</p> -->

    <div class="section-item">
      <p class="text">Enable banner ads?</p>
      <div class="switch-button">
        <button>ON</button>
        <button matRipple class="active">OFF</button>
      </div>
    </div>
  </div>
</div>
