<div class="preview-container">
  <div class="preview">
    <pdf-viewer
      [src]="src"
      [render-text]="true"
      [fit-to-page]="true"
      [show-all]="false"
      [zoom]="zoom"
      [(page)]="pageNumber"
      (after-load-complete)="afterLoadComplete($event)"
      class="preview-pdf"
    ></pdf-viewer>
    <div class="pagination">
      <img
        src="../../../assets/images/forma/arrow-left.svg"
        alt=""
        (click)="prevPage()"
      />
      <p class="pagination-text">
        Page {{ pageNumber }} &nbsp;/ &nbsp;{{ totalPages }}
      </p>
      <img
        src="../../../assets/images/forma/arrow-right.svg"
        alt=""
        (click)="nextPage()"
      />
    </div>
  </div>
  <div class="page-zoom">
    <div (click)="updateZoom('in')">
      <img src="../../../assets/images/forma/zoom-in.svg" alt="" />
    </div>
    <div (click)="updateZoom('out')">
      <img src="../../../assets/images/forma/zoom-out.svg" alt="" />
    </div>
  </div>
</div>
