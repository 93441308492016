<div class="main-container">
	<div class="container">
		<ngx-scanner-qrcode
			#action="scanner"
			[config]="isRearCam ? rearCam : frontCam"
			(event)="reading($event)"
		></ngx-scanner-qrcode>
	</div>

	<div class="reset" *ngIf='isMobile' (click)="switchCam()">
		<img src="../../../../assets//images/reset.png" alt="" />
		<span>Switch</span>
	</div>
</div>
<img
	src="../../../../assets/images/template-icons/close.svg"
	alt=""
	class="header__close_img"
	(click)="close()"
/>
<!-- Loading -->

<!-- start -->
