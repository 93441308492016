import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InboxService } from 'src/app/@shared/services/inbox.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.sass'],
})
export class InboxComponent implements OnInit {
  columns: string[] = [' ', 'Name', 'Status', 'Subject', 'Date Modified'];
  data = this._inboxService.messages$;
  viewMsg = this._inboxService.viewMessage$;

  hasCheckBox: boolean = environment.flag.multiFileSelect;
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  constructor(public dialog: MatDialog, private _inboxService: InboxService) {}

  ngOnInit(): void {
    this.getData();
  }

  onClickRow(event: any, message: any) {
    if (event.target.className.includes('checkbox')) {
      return;
    }

    if (message?.status_raw == false) {
      this._inboxService.updateStatus(message).subscribe((data: any) => {});
    }

    this._inboxService.getMessage(message.id).subscribe((data: any) => {
      const formattedData = this._inboxService.format(data.data);
      this._inboxService.setMessage(formattedData);
      this._inboxService.setViewMessage(true);
    });
  }

  goback(data: any) {
    this.getData();
    this._inboxService.setViewMessage(data);
  }

  getData() {
    this._inboxService.getMessages().subscribe((data: any) => {
      const { result } = data.data;
      const mData = result.map((res: any) => this._inboxService.format(res));
      this._inboxService.setMessages(mData);
      this._inboxService.prevMessages = result;
    });
  }

  deleteMessages() {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '512px',
      data: {
        action: 'delete-message',
        data: this.selection.selected,
      },
    });

    dialogRef.afterClosed()?.subscribe((data) => {
      if (data) {
        this.selection.clear();
      }
    });
  }
}
