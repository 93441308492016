<app-spinner></app-spinner>

<div class="container margin-container">
  <mat-grid-list cols="4" rowHeight="835px" class="my-account-container">
    <mat-grid-tile colspan="{{gridTileColumn1}}">
      <!-- <div class="header-container">
        <h4 class="title">My Account</h4>
        <div class="right" *ngIf="isFormaCommercial">
          <h5 class="title">Personal Plan</h5>
          <a>745 MB of 1 GB used</a>
          <mat-progress-bar
            class="progress-bar"
            mode="determinate"
            value="74.5" 
          ></mat-progress-bar>
        </div>
      </div> -->
      <div class="cards-container">
        <div class="cards-content">
          <img [src]="pictureSrc" />
          <button matRipple class="upload-picture-btn" (click)="upload()">
            <img src="../../../../assets/images/forma/edit-pen.svg" class="edit-profile-icon" alt="" srcset="">
            Change Photo
          </button>
          <!-- <button matRipple class="create" (click)="updateDisplayPhoto()">
            Save Photo
          </button> -->
          <div class="profile-info-container">
            <h1 class="profile-text profile-title">Hello {{fullName}}</h1>
            <p class="plan-text">Your plan expires on: <strong class="plan-date-text">---</strong></p>

            <h1 class="profile-text margin-top">Basic Information</h1>
            <p class="profile-sub-title">Name <strong class="profile-text">{{fullName}}</strong></p>
            <p class="profile-sub-title">Email Address <strong class="profile-text">{{email}}</strong></p>
            <p class="profile-sub-title">Mobile Number <strong class="profile-text">---{{phoneNumber}}</strong></p>

            <h1 class="profile-text margin-top">Referral Information</h1>
            <p class="profile-sub-title text-icon">
              Referral Code <strong class="profile-text">---</strong>
              <img src="../../../../assets/images/forma/copy.svg" class="icon-copy" alt="" srcset="">
            </p>
            <p class="profile-sub-title">Referral Points <strong class="profile-text">---</strong></p>
            <h1 class="profile-text">Invite Link</h1>
            <input type="text" class="input-invite-link" value="https://forma-puno.unawa.dev/login">
            <img src="../../../../assets/images/forma/copy.svg" class="icon-copy-invite" alt="" srcset="">
            
            <button class="advance-setting-btn margin-top-2">
              <img src="../../../../assets/images/forma/settings.svg" class="icon-setting" alt="" srcset="">
              Advance Setting
            </button>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="{{gridTileColumn2}}">
      <div class="cards-container">
        <div class="cards-content border-left-right">
          <div class="account-title-container">
            <p class="account-title">EDIT PROFILE</p>
            <button class="edit-btn">
              <img src="../../../../assets/images/forma/edit.svg" class="edit-btn-icon" alt="" srcset="">
              EDIT</button>
            <button class="save-btn" (click)="updateProfile()">
              <img src="../../../../assets/images/forma/save.svg" class="save-btn-icon" alt="" srcset="">
              SAVE</button>
          </div>
          
          <div class="form-field-pair margin-top-2">
            <div class="input-group">
              <mat-label>First Name</mat-label>
              <mat-form-field
                class="input-form-pair"
                appearance="fill"
              >
                <input
                  type="text"
                  matInput
                  class="textfieldReg"
                  [(ngModel)]="givenName"
                />
              </mat-form-field>
            </div>
            <div class="input-group">
              <mat-label>Last Name</mat-label>
              <mat-form-field
                class="input-form-pair"
                
                appearance="fill"
              >
                <input [(ngModel)]="lastName" matInput />
              </mat-form-field>
            </div>
          </div>
    
          <div class="form-field-pair margin-top-2">
            <div class="input-group">
              <mat-label>Email</mat-label>
              <mat-form-field
                class="input-form-pair"
                
                appearance="fill"
              >
                <input
                  type="email"
                  [(ngModel)]="email"
                  matInput
                  class="textfieldReg readonly"
                  disabled
                />
              </mat-form-field>
            </div>
            <div class="input-group">
              <form [formGroup]="editAccountForm" class="align-left">
                  <mat-label>Mobile Number</mat-label>
                  <mat-form-field
                    class="input-form-pair input-pair-top"
                    
                    appearance="fill"
                  >
                    <ngx-mat-intl-tel-input
                      [preferredCountries]="['ph']"
                      formControlName="phoneNumber"
                      #phone
                    ></ngx-mat-intl-tel-input>
      
                    <!--<input type="text" matInput class="textfieldReg" />!-->
                  </mat-form-field>
                </form>
              </div>
          </div>
          <!-- <div class="btnGrp">
            <button matRipple class="cancel" (click)="close()">Cancel</button>!
            <button matRipple class="create" (click)="updateProfile()">
              Update personal info
            </button>
          </div> -->
    
          <div class="account-title-container mt-account-title">
            <p class="account-title">CHANGE PASSWORD</p>
            <button class="edit-btn">
              <img src="../../../../assets/images/forma/edit.svg" class="edit-btn-icon" alt="" srcset="">
              EDIT</button>
            <button class="save-btn" (click)="updatePassword()">
              <img src="../../../../assets/images/forma/save.svg" class="save-btn-icon" alt="" srcset="">
              SAVE</button>
          </div>
          <div class="form-field-pair margin-top-2">
            <div class="input-group">
              <mat-label>Current Password</mat-label>
              <mat-form-field
                class="input-form-pair"
                
                appearance="fill"
                [ngClass]="!errorMsg ? 'input-form-pair' : 'input-form-pair-error'"
              >
                <input
                  [type]="hideOldPw ? 'password' : 'text'"
                  [(ngModel)]="oldPassword"
                  placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                  matInput
                  class="textfieldReg"
                  (ngModelChange)="errorMsg = ''; errorStatus = 0"
                />
                <mat-icon matSuffix (click)="hideOldPw = !hideOldPw">{{
                  hideOldPw ? "visibility_off" : "visibility"
                }}</mat-icon>
              </mat-form-field>
              <ng-container *ngIf="oldPassword">
                <mat-error
                  class="error"
                  *ngIf="oldPassword.length > 0 && errorStatus >= 403"
                  >{{ errorMsg }}
                </mat-error>
              </ng-container>
            </div>
            <!-- second column input -->
            <div class="input-group"></div>
          </div>

          <div class="form-field-pair margin-top-2">
            <div class="input-group">
              <mat-label>New Password</mat-label>
              <mat-form-field
                class="input-form-pair"
                
                appearance="fill"
                [ngClass]="!errorMsg&&isPwordValid ? 'input-form-pair' : 'input-form-pair-error'"
              >
                <input
                  [type]="hideNewPw ? 'password' : 'text'"
                  [(ngModel)]="newPassword"
                  placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                  matInput
                  (ngModelChange)="errorMsg = ''; errorStatus = 0; checkPword()"
                />
                <mat-icon matSuffix (click)="hideNewPw = !hideNewPw">{{
                  hideNewPw ? "visibility_off" : "visibility"
                }}</mat-icon>
              </mat-form-field>
              <ng-container *ngIf="newPassword">
                <span *ngIf="!isPwordValid" style="color:red">
                  Password must be at least 8 characters<br />
                  Contains at least 1 lower case<br />
                  Contains at least 1 upper case<br />
                  Contains at least 1 number<br />
                  Contains at least 1 special character
                </span>
                <mat-error
                  class="error"
                  *ngIf="newPassword.length > 0 && errorStatus == 1"
                  >{{ errorMsg }}</mat-error
                >
              </ng-container>
            </div>
            <div class="input-group">
              <mat-label>Confirm Password</mat-label>
              <mat-form-field
                class="input-form-pair"
                
                appearance="fill"
                [ngClass]="!errorMsg ? 'input-form-pair' : 'input-form-pair-error'"
              >
                <input
                  [type]="hideConfirm ? 'password' : 'text'"
                  [(ngModel)]="confirmPassword"
                  (ngModelChange)="errorMsg = ''; errorStatus = 0"
                  placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
                  matInput
                />
                <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
                  hideConfirm ? "visibility_off" : "visibility"
                }}</mat-icon>
              </mat-form-field>
              <ng-container *ngIf="confirmPassword">
                <mat-error
                  class="error"
                  *ngIf="confirmPassword.length > 0 && errorStatus == 1"
                  >{{ errorMsg }}</mat-error
                >
              </ng-container>
            </div>
          </div>
    
          <!-- <div class="btnGrp">
            <button matRipple class="cancel" (click)="close()">Cancel</button>!
            <button matRipple class="create" (click)="updatePassword()">
              Change Password
            </button>
          </div> -->
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="{{gridTileColumn3}}">
      <div class="cards-container">
        <div class="cards-content">
          <div class="billing-title-container">
            <p class="billing-title">BILLING</p>
          </div>
          <div class="billing-info-container">
            <p class="billing-sub-title">Current Plan: <strong class="billing-text">Free</strong></p>
            <p class="billing-sub-title">Payment Information <strong class="billing-text">None</strong></p>
          </div>

          <!-- <button matRipple class="create btn-align-bottom"> --(click)="updateDisplayPhoto()"--
            UPGRADE PLAN
          </button> -->
        </div>
      </div>

      <div class="subscription-container" *ngIf="isFormaCommercial">
        <span class="subscription-title">Subscriptions</span>
        <span class="subscription-sub-title">Manage your subscriptions </span>
  
        <div class="subscriptions">
          <div class="card-group">
            <mat-card class="progress-card">
              <div class="subs">
                <div class="title-group">
                  <span class="subscription-title">ForMA</span>
                  <span class="subscription-title">PHP 0.00</span>
                </div>
                <span class="subscription-sub-title">Business Plan </span>
                <span class="subs-colored-sub-title"
                  >Next billing cycle on Dec 1 2022</span
                >
              </div>
              <div class="footer">
                <p>See all</p>
                <img src="../../../assets/images/icons/next.png" alt="" />
              </div>
            </mat-card>
            <span class="cancel-sub" (click)="cancelSub()"
              >Cancel Subscription
            </span>
          </div>
  
          <div class="card-group">
            <mat-card class="progress-card">
              <div class="subs">
                <div class="title-group">
                  <span class="subscription-title">SignSecure</span>
                  <span class="subscription-title">PHP 0.00</span>
                </div>
                <span class="subscription-sub-title">Personal Plan </span>
                <span class="subs-colored-sub-title"
                  >Next billing cycle on Dec 1 2022</span
                >
              </div>
              <div class="footer">
                <p>See all</p>
                <img src="../../../assets/images/icons/next.png" alt="" />
              </div>
            </mat-card>
            <span class="cancel-sub" (click)="cancelSub()"
              >Cancel Subscription
            </span>
          </div>
        </div>
  
        <div class="btm-row">
          <button matRipple class="button" (click)="addSubscription()">
            Add a new subscription
          </button>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<input
  type="file"
  id="fileUpload"
  name="fileUpload"
  style="display: none"
  (change)="onFileSelected($event)"
  accept=".jpg,.png,.pdf,.docx,.doc,.xlsx,.xls,.ppt,.pptx"
  multiple
/>
