import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { SignSecureProgressModel } from 'src/app/sign-secure/models/sign-secure-progress.model';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { AdvanceTaggingStepComponent } from '../step/step.component';
import { NgFor } from '@angular/common';
import { AdvanceTaggingService } from '../service/advance-tagging.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.sass'],
  standalone: true,
  imports: [AdvanceTaggingStepComponent, NgFor],
})
export class AdvanceTaggingStepsComponent implements OnInit, OnDestroy {
  steps: SignSecureProgressModel[] = [
    {
      inactive: '../../../assets/images/puno-assets/puno-matter-inactive.svg',
      active: '../../../assets/images/puno-assets/puno-matter-active.svg',
      done: '../../../assets/images/puno-assets/puno-matter-done.svg',
      label: 'Matter Details',
      status: 'active',
    },
    {
      inactive: '../../../assets/images/puno-assets/puno-matter-inactive.svg',
      active: '../../../assets/images/puno-assets/puno-matter-active.svg',
      done: '../../../assets/images/puno-assets/puno-matter-done.svg',
      label: 'Add Case',
      status: 'inactive',
    },
    {
      inactive: '../../../assets/images/puno-assets/puno-matter-inactive.svg',
      active: '../../../assets/images/puno-assets/puno-matter-active.svg',
      done: '../../../assets/images/puno-assets/puno-matter-done.svg',
      label: 'Document Fields',
      status: 'inactive',
    },
    {
      inactive:
        '../../../assets/images/puno-assets/puno-matter-review-inactive.svg',
      active:
        '../../../assets/images/puno-assets/puno-matter-review-active.svg',
      done: '../../../assets/images/steps/review-done.svg',
      label: 'Review',
      status: 'inactive',
    },
    {
      inactive: '',
      active: '',
      done: '',
      label: 'Done',
      status: 'inactive',
    },
  ];

  subs = new Subscription();
  workflow: any;
  constructor(
    private _aTProgress: AdvanceTaggingService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this._aTProgress.$advancedTaggingProgress.subscribe(max => {
        for (let i = 0; i < this.steps.length; i++) {
          if ((i as number) < max) {
            this.steps[i].status = 'done';
          } else {
            this.steps[i].status = 'inactive';
          }

          if (i === +max - 1) this.steps[i].status = 'active';
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  move(page: number) {
    console.log({ page });
    // const workflow = data.workflow;
    // if (page >= 4 && workflow) {
    //   const parties = workflow.parties;
    //   if (!!!parties.some((party: any) => party.role === 'SIGN')) {
    //     this._snackBar.openFromComponent(SnackbarComponent, {
    //       duration: 3000,
    //       data: {
    //         type: 'error',
    //         text: 'Please add signatory',
    //       },
    //     });
    //     return;
    //   }
    // }
    console.log(page);
    this._aTProgress.setATProgress(1);
  }
}
