import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

@Component({
  selector: "app-qr-restricted",
  templateUrl: "./qr-restricted.component.html",
  styleUrls: ["./qr-restricted.component.sass"],
})
export class QrRestrictedComponent implements OnInit {
  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit(): void { }

  close() {
    this.closeModalEvent.emit(true)
  }
}
