<ng-container>
  <form class="upload-documents">
    <h4 class="title">Uploading {{ files.length }} items</h4>
    <div class="content">
      <div class="documents">
        <div class="document" *ngFor="let file of files">
          <div
            class="loader"
            [style]="file.progress > 10 ? 'width: ' + file.progress + '%' : ''"
            *ngIf="file?.progress !== 100 && file?.progress >= 0"
          ></div>
          <div class="right">
            <img
              [src]="
                file.type === 'file'
                  ? '../../../assets/images/icons/file.png'
                  : '../../../assets/images/icons/folder.png'
              "
              alt=""
            />
            <span class="file-name">{{ file?.name }} </span>
            <span class="file-size"> - {{ file?.size }}</span>
          </div>
          <div class="left">
            <img
              *ngIf="file?.progress === 100"
              src="../../../assets/images/icons/success.png"
              alt=""
            />
            <img
              *ngIf="file?.progress !== 100 && file?.progress >= 0"
              src="../../../assets/images/icons/normal.svg"
              alt=""
            />
            <span *ngIf="file?.progress < 0">{{ file.error }}</span>
            <img
              src="../../../assets/images/icons/error.png"
              alt=""
              *ngIf="file?.progress < 0"
            />
          </div>
        </div>

        <!--START:: Reference for other upload status :: Gonna Delete Later -->
        <!-- <div class="document">
          <div class="right">
            <img src="../../../assets/images/icons/folder.png" alt="" />
            <span class="file-name"
              >Santiago v. Commission on Elections, 270 SCRA 106 (1997)
            </span>
            <span class="file-size"> - 6.00 KB</span>
          </div>
          <div class="left">
            <span>Upload error</span>
            <img src="../../../assets/images/icons/error.png" alt="" />
          </div>
        </div>
        <div class="document">
          <div class="right">
            <img src="../../../assets/images/icons/file.png" alt="" />
            <span class="file-name"
              >Santiago v. Commission on Elections, 270 SCRA 106 (1997)
            </span>
            <span class="file-size"> - 6.00 KB</span>
          </div>
          <div class="left">
            <img src="../../../assets/images/icons/success.png" alt="" />
          </div>
        </div>
        <div class="document">
          <div class="right">
            <img src="../../../assets/images/icons/file.png" alt="" />
            <span class="file-name"
              >Santiago v. Commission on Elections, 270 SCRA 106 (1997)
            </span>
            <span class="file-size"> - 6.00 KB</span>
          </div>
          <div class="left">
            <img src="../../../assets/images/icons/success.png" alt="" />
          </div>
        </div>
        <div class="document">
          <div class="right">
            <img src="../../../assets/images/icons/file.png" alt="" />
            <span class="file-name"
              >Santiago v. Commission on Elections, 270 SCRA 106 (1997)
            </span>
            <span class="file-size"> - 6.00 KB</span>
          </div>
          <div class="left">
            <img src="../../../assets/images/icons/success.png" alt="" />
          </div>
        </div>
        <div class="document">
          <div class="right">
            <img src="../../../assets/images/icons/file.png" alt="" />
            <span class="file-name"
              >Santiago v. Commission on Elections, 270 SCRA 106 (1997)
            </span>
            <span class="file-size"> - 6.00 KB</span>
          </div>
          <div class="left">
            <span>Upload error</span>
            <img src="../../../assets/images/icons/error.png" alt="" />
          </div>
        </div> -->
        <!-- END :: Reference for other upload status :: Gonna Delete Later -->
      </div>
      <div class="actions">
        <button matRipple class="more" (click)="uploadMore()">Upload more files</button>
        <button matRipple class="done" *ngIf="isUploadComplete" (click)="closeModal()">Done</button>
      </div>
    </div>
  </form>
</ng-container>
