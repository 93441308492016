<div class="accept-right-container">
    <div class="header">
        <h2 class="header__title">Do you want to approve or reject this document?</h2>
        <img src="../../../../assets/images/template-icons/close.svg" alt="" class="header__close_img" (click)="close('', {})">
    </div>
    <div class="content">
        <p class="content__title">Reason for rejecting document (optional)</p>
        <textarea [(ngModel)]="note" class="content__input"></textarea> 
    </div>
    <div class="buttons">
        <button class="reject" (click)="decline()">Reject</button>
        <button class="approve" (click)="approve()">Approve</button>
    </div>
</div>