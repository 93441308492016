<div class="mat-details-container">
  <div class="form-container">
    <div class="header">Matter Details</div>
    <div class="input-container">
      <mat-label class="label">Barcode</mat-label>
      <mat-form-field><input matInput /></mat-form-field>
    </div>
    <div class="input-container">
      <mat-label class="label">Matter No.</mat-label>
      <mat-form-field><input matInput /></mat-form-field>
    </div>
    <div class="input-container">
      <mat-label class="label">Matter Title</mat-label>
      <mat-form-field><input matInput /></mat-form-field>
    </div>
    <div class="input-container">
      <mat-label class="label">Practice Area</mat-label>
      <mat-form-field><input matInput /></mat-form-field>
    </div>
    <div class="input-container">
      <mat-label class="label">Status</mat-label>
      <mat-form-field><input matInput /></mat-form-field>
    </div>

    <div class="header">Clients & Lawyers Details</div>
    <div class="input-container">
      <mat-label class="label">Clients</mat-label>
      <mat-form-field><input matInput /></mat-form-field>
    </div>
    <div class="input-container">
      <mat-label class="label">Lawyers</mat-label>
      <mat-form-field><input matInput /></mat-form-field>
    </div>
  </div>
  <div class="btn-container">
    <button class="btn" (click)="nextStep()">Next</button>
  </div>
</div>
