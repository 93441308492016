import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-changed',
  templateUrl: './password-changed.component.html',
  styleUrls: ['./password-changed.component.sass'],
})
export class PasswordChangedComponent implements OnInit {
  ngOnInit(): void {
    
  }
}
