import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duplicate-file',
  templateUrl: './duplicate-file.component.html',
  styleUrls: ['./duplicate-file.component.sass'],
})
export class DuplicateFileComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
