<div class="file-view-container">
  <div class="header-container">
    <h4 class="title">Modify {{file?.type || 'file' | titlecase}}</h4>
  </div>
  <div class="file-navigator">
    <app-breadcrumb
      [data]="breadcrumbs"
      (onBack)="onBreadcrumbBack($event)"
    ></app-breadcrumb>
  </div>
  <div
    [ngClass]="{
      'file-container': file?.type == 'file',
      'folder-container': file?.type == 'folder'
    }"
  >
    <div class="title-container">
      <h4 class="file-view-title">
        <img [src]="getObjectImage(file?.type)" />{{
          file?.name ?? "File Name"
        }}
      </h4>

      <mat-checkbox
        class="publish"
        *ngIf="file?.type == 'file' && !file.workflow"
        [checked]="isPublished"
        (change)="publishFile()"
        >Publish</mat-checkbox
      >
    </div>
    <div class="flex">
      <mat-label class="label">Name</mat-label>
    </div>
    <div class="flex">
      <mat-form-field class="input-form" floatLabel="auto" appearance="fill">
        <input matInput [(ngModel)]="name" class="padding-left10" type="text" placeholder="Name" />
      </mat-form-field>
    </div>
    <div class="flex padding-top-20">
      <mat-label class="label padding-top-20">Tags</mat-label>
    </div>
    <div class="flex">
      <mat-form-field
        class="label input-form input-tags"
        floatLabel="auto"
        appearance="fill"
      >
        <div class="members width-100 height-fit-content">
          <mat-chip-grid #chipList aria-label="Tags">
            <mat-chip class="member-tag" [ngStyle]="{'background-color':isPublished?'#fafafa':'#D2EBF1','cursor':isPublished?'not-allowed':'pointer'}" *ngFor="let tag of tags">
              {{ tag?.name || tag }}&nbsp;<span *ngIf="!isPublished && tag?.name !== 'ocr'" (click)="removeTag(tag)" style="font-size: 10px;cursor:pointer;align-self: flex-start; opacity: .5;">x</span>
            </mat-chip>
            <input
              
              [matChipInputFor]="chipList"
              (keydown.enter)="addTags(tag)"
              [disabled]="isPublished"
              #tag
            />
          </mat-chip-grid>
        </div>
      </mat-form-field>
    </div>

    <div class="form-field-pair label padding-top-20">
      <div class="input-group">
        <mat-label>Date</mat-label>
        <mat-form-field
          appearance="fill"
          floatLabel="auto"
          class="input-form-date"
        >
          <input matInput [(ngModel)]="dateVal" class="padding-left10" [matDatepicker]="date" [disabled]="true" />
          <mat-datepicker-toggle matSuffix [for]="date" [disabled]="true">
            <mat-icon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="input-group" *ngIf="file.type === 'file'">
        <mat-label>Due Date</mat-label>
        <mat-form-field
          appearance="fill"
          floatLabel="auto"
          class="input-form-date"
        >
          <input matInput [(ngModel)]="dueDateVal" class="padding-left10" [matDatepicker]="dueDate" [disabled]="isPublished" [min]="today"/>
          <mat-datepicker-toggle matSuffix [for]="dueDate" [disabled]="isPublished">
            <mat-icon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dueDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="form-field-pair label padding-top-20">
      <div class="input-group">
        <mat-label>Document Number</mat-label>
        <mat-form-field
          class="input-form-date"
          floatLabel="auto"
          appearance="fill"
        >
          <input
            type="text"
            [(ngModel)]="documentNumber"
            matInput
            class="textfieldReg"
            [disabled]="isPublished"
          />
        </mat-form-field>
      </div>
      <div class="input-group">
        <mat-label class="label">Details</mat-label>
        <mat-form-field
          class="input-form-date"
          floatLabel="auto"
          appearance="fill"
        >
          <input
            type="text"
            [(ngModel)]="docDetails"
            matInput
            class="textfieldReg"
            [disabled]="isPublished"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="padding-top-20">
      <div>
        <mat-label class="label">Shared To</mat-label>
      </div>
      <mat-form-field
        class="label input-form-shared"
        floatLabel="auto"
        appearance="fill"
      >
        <div class="members width-100 height-fit-content padding-left10">
          <mat-chip-grid #permission aria-label="User Groups">
            <mat-chip class="member-tag" *ngFor="let usergroup of userGroups">{{
              usergroup.group_name || usergroup.groupName || usergroup.name
            }}</mat-chip>
            <mat-chip class="member-tag" *ngFor="let usergroup of userList">{{
              usergroup.name
            }}</mat-chip>
            <input placeholder="" [matChipInputFor]="permission" disabled />
          </mat-chip-grid>
        </div>
      </mat-form-field>
      <button matRipple class="edit" [ngStyle]="{'background-color':isPublished?'#fafafa':'#ffffff','cursor':isPublished?'not-allowed':'pointer'}" (click)="showModal()" [disabled]="isPublished">
        Edit sharing permissions
      </button>
    </div>

    <div class="label" *ngIf="file?.type == 'file'">
      <hr />
      <button matRipple [ngStyle]="{'background-color':isPublished?'#fafafa':'#ffffff','cursor':isPublished?'not-allowed':'pointer'}" class="btnOcr" (click)="runOcr('run-ocr')" [disabled]="isPublished">Run OCR</button>
    </div>

    <div *ngIf="ocrResult" class="ocr-preview">
      <mat-form-field class="text-form" floatLabel="auto" appearance="fill">
        <textarea
          class="textarea-resize"
          matInput
          [(ngModel)]="ocrResult"
          disabled="true"
        ></textarea>
      </mat-form-field>
      <span class="flex editOcr" (click)="runOcr('edit-ocr')">Edit OCR</span>
    </div>

    <div
      [ngClass]="{
        fileBtnGrp: file?.type == 'file',
        btnGrp: file?.type == 'folder'
      }"
    >
      <button matRipple class="cancel" (click)="goBack()">Cancel</button>
      <button matRipple class="create" (click)="updateDirectory()">Save</button>
    </div>
  </div>
  <div style="padding-bottom: 20px"></div>
</div>
