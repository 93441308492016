import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription, switchMap } from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { SignatureService } from 'src/app/@shared/services/signature.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-delete-signature',
  templateUrl: './delete-signature.component.html',
  styleUrls: ['./delete-signature.component.sass'],
})
export class DeleteSignatureComponent implements OnInit, OnDestroy {
  @Output() closeModalEvent = new EventEmitter();
  @Input() data: ModalData = {
    type: '',
    action: '',
  };
  private _subs: Subscription = new Subscription();

  constructor(private _signatureService: SignatureService) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {}

  closeModal(confirm?: boolean) {
    this.closeModalEvent.emit(confirm);
  }

  deleteSignature() {
    let result: any;
    this._signatureService
      .deleteSignature(this.data.data)
      .pipe(
        switchMap((i) => {
          return this._signatureService.getSignatures();
        })
      )
      .subscribe((i: any) => {
        result = this._signatureService.formatSignatures(i.data);
        this._signatureService.setSignatures(result);
      });
    this.closeModal();
  }
}
