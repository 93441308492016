import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileDataModel } from '../models/file-data.model';
import { FileTableModel } from '../models/file-table.model';
import FileDataJson from '../../../dummy-data/files.json';
import { AuthenticationService } from './authentication.service';
import { SearchResults } from '../models/search-results.model';
import { NavigationService } from './navigation.service';
@Injectable({
  providedIn: 'root',
})
export class OcrService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly folder = environment.apiConfig.folder.url;
  private readonly file = environment.apiConfig.file.url;
  private readonly directory = environment.apiConfig.directory.url;
  private readonly _fileUrl = `${this.baseUrl}${this.file}`;
  private readonly _folderUrl = `${this.baseUrl}${this.folder}`;
  private readonly _directoryUrl = `${this.baseUrl}${this.directory}`;
  imgSrc: string = '';
  title: string = '';

  addButton: string = 'add sample';

  filesCols: string[] = [
    ' ',
    'title',
    'tags',
    'Date Modified',
    'Date Created',
    'actions',
  ];

  filesData: Object[] = FileDataJson;
  files1Data: FileTableModel[] = [];
  fileData: FileDataModel[] = [];
  private searchResults = new BehaviorSubject<SearchResults>({
    searchQuery: '',
    data: [],
  });
  searchResults$ = this.searchResults.asObservable();
  searchQuery: string = '';

  file1Data: FileDataModel[] = [];
  filesAysnc = new Subject<any>();
  private files = new BehaviorSubject<FileDataModel[]>([]);
  files$ = this.files.asObservable();
  resultsLength: number = 0;

  private userfiles = new BehaviorSubject<FileTableModel[]>([]);
  userfiles$ = this.userfiles.asObservable();

  private groupfiles = new BehaviorSubject<FileTableModel[]>([]);
  groupfiles$ = this.groupfiles.asObservable();

  private recentfiles = new BehaviorSubject<FileTableModel[]>([]);
  recentfiles$ = this.recentfiles.asObservable();

  // Used for Files and Folders
  filesAndFolder: any[] = [];
  private filesAndFolderAsync = new BehaviorSubject<any[]>([]);
  filesAndFolderAsync$ = this.filesAndFolderAsync.asObservable();
  // Used for Folders
  folders: any[] = [];
  private foldersAsync = new BehaviorSubject<any[]>([]);
  folderAsync$ = this.foldersAsync.asObservable();
  constructor(private _httpClient: HttpClient) {}

  runOcr(fileId: string) {
    let params = new HttpParams();
    params.append('id', fileId);

    return this._httpClient.post<any>(
      `${this._directoryUrl}/ocr/` + fileId,
      ''
    );
  }
  updateOcr(fileId: string, ocrText: string) {
    return this._httpClient.patch<any>(
      `${this._directoryUrl}/ocr/update/` + fileId,
      { text: ocrText }
    );
  }
}
