<app-wrapper-background>
  <div class="email-sent-container">
    <img src="../../../../assets/images/forma/forma-white-logo.svg" />
    <mat-card class="container">
      <div class="wrapper" id="email">
        <h2 class="header">Password Changed</h2>
        <h4 class="description">
          Your password has been successfully changed.
          <br />You may now log in using your new password.
        </h4>
        <a class="btn-next" routerLink="/login">
          <label class="btn-font"> Back to login </label> </a
        >>
      </div>
    </mat-card>
  </div>
</app-wrapper-background>
