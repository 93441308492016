import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserTableModel } from 'src/app/@shared/models/user-table.model';
import { UsersService } from 'src/app/@shared/services/users.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-admin-dashboard-manage-user',
  templateUrl: './admin-dashboard-manage-user.component.html',
  styleUrls: ['./admin-dashboard-manage-user.component.sass'],
})
export class AdminDashboardManageUserComponent implements OnInit {
  @Input()
  users = this._userService.users$;
  userCols = this._userService.userTableColumns;
  searchQuery: string = '';
  userData: UserTableModel[] = [];
  userDataOrig: UserTableModel[] = [];
  showAddUser: boolean = false;

  constructor(
    private _userService: UsersService,
    private _zone: NgZone,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._userService.getUsers().subscribe((users) => {
      this.userData = this._userService.formatUsers(users.data);
      this.userDataOrig = this.userData;
      this._userService.setUsers(this.userData);
    });
  }

  showModal(data: any) {
    this.showAddUser = true;
    // const { dialog, type: action } = data;
    // const dialogRef = dialog?.open(ModalComponent, {
    //   width: '636px',
    //   data: { action },
    // });

    // dialogRef?.afterClosed()?.subscribe((email: string) => {
    //   if (email) {
    //     this.showUserVerification(this.dialog, email);
    //   }
    //   this._userService.getUsers().subscribe((users) => {
    //     this._userService.setUsers(this._userService.formatUsers(users.data));
    //   });
    // });
  }

  showUserVerification(dialog: MatDialog, email: string) {
    const dialogRef = dialog?.open(ModalComponent, {
      width: '636px',
      data: { action: 'mUser-verification', data: email },
    });
  }
  search() {
   
    if (this.searchQuery.trim().length == 0) {
      this.userData = this.userDataOrig;
    } else {
      this.userData = this.userDataOrig.filter((user) => {
        if (user.email.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          return user;
        } else if (user.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          return user;
        } else if (user.position?.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          return user;
        } else if (user.role.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          return user;
        }else {return;}
      });
    }
    this._userService.setUsers(this.userData);
  }
}
