import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qr-verification-fails',
  templateUrl: './qr-verification-fails.component.html',
  styleUrls: ['./qr-verification-fails.component.sass']
})
export class QrVerificationFailsComponent implements OnInit {

  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()


  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.closeModalEvent.emit(true)
  }

}
