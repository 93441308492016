import { Component, OnInit } from '@angular/core';
import { FaqsModel } from 'src/app/@shared/models/Faqs.model';
import faq from '../../../../assets/faq.json';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.sass'],
})
export class FaqComponent implements OnInit {
  faqs: FaqsModel[] = faq;
  constructor() {}

  ngOnInit(): void {}
}
