<div class="recover-container">
  <mat-card class="container">
    <div
      class="flexGrid"
      [ngClass]="isValid ? 'recover-password' : 'recover-password-error'"
    >
      <h2 class="headerPw">Forgot Password</h2>
      <h4 class="description">
        Enter your email for the verification process,
we will send 4-digit code to your email.
      </h4>
      <div class="flex padding-top40">
        <mat-label class="labelPw">Email</mat-label>
      </div>
      <div class="flex">
        <input
          type="email"
          placeholder="Enter your email"
          matInput
          class="textfieldPw"
          [(ngModel)]="email"
          (ngModelChange)="isValid = true"
        />
      </div>
      <span class="error" *ngIf="!isValid">{{ errorMsg }}</span>
      <div class="padding-top40">
        <button matRipple class="btn-next" (click)="resetPassword()">
          <label class="btn-font">Continue</label>
        </button>
        <div style="display: flex;justify-content: center;padding-top:40px">
          <a >Need to return to the login page? <a class="label-clickable" (click)="goTo('login')">Back to Sign In</a></a>
        </div>
      </div>
    </div>
  </mat-card>
</div>
