<ng-container *ngIf="image === ''">
	<div
		class="drag-drop-container"
		appDragDropFileUpload
		(fileDropped)="dragover($event)"
	>
		<div class="container">
			<img src="../../../../assets/images/upload-icon.svg" alt="" />
			<h4>Drag and Drop to upload</h4>
		</div>
		<div class="or-container">
			<p class="or">Or select from your files</p>
			<button (click)="upload()">Browse</button>
		</div>
	</div>
	<p class="description">
		We will send an OTP (One-Time Password) to your email address to validate
		your uploaded signature
	</p>
	<!-- <div class="buttons-container" *ngIf = >
		<button class="no-button" (click)="close(null)">Cancel</button>
		<button class="yes-button">Update email</button>
	</div> -->
	<input
		type="file"
		id="fileUpload"
		name="fileUpload"
		style="display: none"
		(change)="onFileSelected($event)"
		accept=".jpg,.png"
	/>
</ng-container>
<ng-container *ngIf="image !== ''">
	<div class="signature-container">
		<img [src]="image" alt="" class="signature" />
	</div>

	<ng-container *ngIf="otp === ''">
		<p class="use-this-sig">Use this signature?</p>
		<div class="buttons-container">
			<button class="no-button" (click)="chooseAnother()">
				No, select another image
			</button>
			<button class="yes-button" (click)="sendOTP()">Yes, send OTP</button>
		</div>
	</ng-container>
	<ng-container *ngIf="otp !== ''">
		<p class="otp-confirmation">
			Your security One-Time Pin (OTP) has been sent to your registered email
			address.<ng-container *ngIf="time>0;else otpExpired">It will expire in {{time}} seconds.</ng-container>
		</p>
		<div class="otp-input-container">
			<div class="input-container">
				<input
					type="text"
					class="otp"
					placeholder="Enter OTP"
					(blur)="inputOtp($event)"
				/>
				<p *ngIf="otpError !== ''" class="error-text">{{ otpError }}</p>
			</div>

			<button
				[class]="otpValue === '' ? 'otp-button' : 'otp-button active'"
				(click)="verifyOtp()"
			>
				Verify OTP
			</button>
		</div>
	</ng-container>
</ng-container>
<ng-template #otpExpired><a style="color: red">OTP has expired</a></ng-template>