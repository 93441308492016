import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { PwordRegex } from 'src/app/@shared/constants/regex.const';
import { UserModel } from 'src/app/@shared/models/user.model';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.sass'],
})
export class RegistrationComponent implements OnInit {
  //@Output() verificationScreen = new EventEmitter<string>();
  //@Output() navigatePage = new EventEmitter<string>();
  hide: boolean = true;
  hideConfirm: boolean = true;
  errMsg='';
  regFormData: UserModel = {
    lastName: '',
    givenName: '',
    email: '',
    password: '',
    role: 'user',
  };
  imnageCarousel: Object[] = [
    {
      image: '../../../assets/images/forma/carousel/enotary.png',
      thumbImage: '../../../assets/images/forma/carousel/enotary.png',
    },
    {
      thumbImage: '../../../assets/images/forma/carousel/forma.png',
    },
    {
      thumbImage: '../../../assets/images/forma/carousel/safeform.png',
    },
    {
      thumbImage: '../../../assets/images/forma/carousel/signsecure.png',
    },
  ];
  regConfirmation: string = '../../../assets/images/regLabel.png';
  signUpForm: FormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6),Validators.pattern(PwordRegex)]],
    confirmPassword: ['', [Validators.required, this.checkPasswordMatch()]],
    isAgree: [false, [Validators.required]],
  });
  isAgree: boolean = false;
  isEmailSent: boolean = false;
  constructor(
    private fb: FormBuilder,
    private _authService: AuthenticationService,
    private dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.signUpForm.controls['email'].invalid == false;
  }
  checkPasswordMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(!control.value){
        return null;
      }
      return control.value!== this.signUpForm.controls['password'].value ? {pwordNotMatch:true} :null;
    };
  }
  verify(data: string): void {
    
    if (this.signUpForm.valid) {
      let formData = this.signUpForm.getRawValue();
      if (formData.password === formData.confirmPassword) {
        this.regFormData = {
          givenName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          role: 'user',
        };

        if (this.signUpForm.controls['isAgree'].value) {
          this._authService.registerUser(this.regFormData).pipe(catchError((err:HttpErrorResponse)=>{
            let errMsg='';
            this.errMsg=err.error.error.slice(15);
            throw new Error(errMsg);
          })).subscribe(() => {
            this.isEmailSent = true;
          });
        } else {
          this._snackBar.openFromComponent(SnackbarComponent, {
            data: {
              type: 'error',
              text: 'Read and agree with terms of use and privacy policy',
            },
            duration: 3000,
          });
        }
      }
    } else {
      this.checkInvalidControl();
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { type: 'error', text: 'Missing/Invalid Field Values' },
        duration: 3000,
      });
    }
  }
  showUserVerification() {
    this.isEmailSent = true;
    //this.router.navigateByUrl('/login');
  }
  checkInvalidControl() {
    const controls = this.signUpForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            this.signUpForm.controls[name].markAsDirty();
        }
    }
}
  goTo(data: string) {
    //this.navigatePage.emit(data);
  }
 
}
