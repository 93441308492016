<form class="update-case-container">
  <h4 class="title">Update Case</h4>

  <div class="content">
    <div class="input-group">
      <mat-label class="label">Case Number</mat-label>
      <mat-form-field  appearance="fill">
        <input type="name" matInput placeholder="First Name" />
      </mat-form-field>
    </div>

    <div class="input-group">
      <mat-label class="label">Case Title</mat-label>
      <mat-form-field  appearance="fill">
        <input type="name" matInput placeholder="First Name" />
      </mat-form-field>
    </div>

    <div class="actions">
      <button matRipple class="cancel">Cancel</button>
      <button matRipple class="create">Update</button>
    </div>
  </div>
</form>
