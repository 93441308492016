import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import inboxData from '../../../dummy-data/inbox.json';

@Injectable({
  providedIn: 'root',
})
export class InboxService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly messageUrl = environment.apiConfig.message.url;

  prevMessages: any;
  private messages = new BehaviorSubject('');
  messages$ = this.messages.asObservable();

  prevMessage: any;
  private message = new BehaviorSubject({ html: '' });
  message$ = this.message.asObservable();

  private viewMessage = new BehaviorSubject(false);
  viewMessage$ = this.viewMessage.asObservable();
  constructor(private _httpClient: HttpClient, public sanitizer: DomSanitizer) {
    this.viewMessage.next(false);
  }

  dummyData() {
    return inboxData;
  }

  setMessage(data: any) {
    this.message.next(data);
  }

  setMessages(data: any) {
    this.messages.next(data);
  }

  getMessages() {
    return this._httpClient.get(`${this.baseUrl}${this.messageUrl}/all`);
  }

  deleteMessages(id: string) {
    return this._httpClient.delete(`${this.baseUrl}${this.messageUrl}/` + id);
  }

  getMessage(messageId: string) {
    return this._httpClient.get(
      `${this.baseUrl}${this.messageUrl}/data/${messageId}`
    );
  }

  updateStatus(message: any) {
    return this._httpClient.put(`${this.baseUrl}${this.messageUrl}/update`, {
      id: message.id,
      status: !message.status_raw,
    });
  }

  format(data: any) {
    // columns: string[] = [' ', 'Name', 'Subject', 'Date Modified', 'Status'];
    let html: any = '';
    if (data.html) {
      html = this.sanitizer.bypassSecurityTrustHtml(
        this._toUtf8(data.html.replace(/[\n\r]/g, ''))
      );
    }

    return {
      id: data.id,
      name: data.sender,
      subject: data.subject,
      status: data.status ? 'Viewed' : 'Unread',
      status_raw: data.status,
      date_modified: data.createdAtFormatted,
      html: html,
      sender: data.sender,
      senderId: data.senderId,
      recipient: data.recipient,
    };
  }

  private _toUtf8(text: string) {
    var surrogate = encodeURIComponent(text);
    var result = '';
    for (var i = 0; i < surrogate.length; ) {
      var character = surrogate[i];
      i += 1;
      if (character == '%') {
        var hex = surrogate.substring(i, (i += 2));
        if (hex) {
          result += String.fromCharCode(parseInt(hex, 16));
        }
      } else {
        result += character;
      }
    }
    return result;
  }

  setViewMessage(view: boolean) {
    this.viewMessage.next(view);
  }
}
