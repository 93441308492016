<ng-container>
  <form class="delete-file">
    <h4 class="title">Delete messages?</h4>
    <div class="content">
      <div class="input-group">
        <mat-label class="label-text"
          >Are you sure you want to delete the selected messages?
        </mat-label>
      </div>
      <div class="actions">
        <button matRipple class="cancel" (click)="closeModal()">Cancel</button>
        <button matRipple class="create" (click)="deleteMessage()">Delete</button>
      </div>
    </div>
  </form>
</ng-container>
