import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Breadcrumb } from '../models/breadcrumb.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass'],
})
export class BreadcrumbComponent implements OnInit {
  @Input()
  data: Breadcrumb[] = [];

  @Output()
  onBack: EventEmitter<Breadcrumb> = new EventEmitter<Breadcrumb>();

  constructor() {}

  ngOnInit(): void {}
}
