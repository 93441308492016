import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-verification-success',
  templateUrl: './verification-success.component.html',
  styleUrls: ['../../../components/verification-container/verification-container.component.sass']
})
export class VerificationSuccessComponent implements OnInit {
  @Output() returnEvent=new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }
  returnScreen(value: string) {
    this.returnEvent.emit(value);
  }
}
