<canvas
  #canvas
  class="color-palette"
  width="208"
  height="151"
  (mouseup)="onMouseUp($event)"
  (mousedown)="onMouseDown($event)"
  (mousemove)="onMouseMove($event)"
>
</canvas>
