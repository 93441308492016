<app-spinner></app-spinner>
<div class="img-viewer-container">
  <div class="img-viewer-nav">
    <div class="left">
      <button (click)="goBack()">
        <img
          src="../../../assets//images/icons/back.svg"
          alt=""
          class="back-button" />
      </button>
      <button matRipple class="cta-buttons" (click)="download()">
        <img src="../../../assets//images/icons/download-white.svg" alt="" />
        <p>Download</p>
      </button>
      <!-- <button matRipple class="cta-buttons" *ngIf="showSignatureButton">
        <img src="../../../assets//images/sidebar/signature-white.svg" alt="" />
        <p>Signature</p>
      </button> -->
      <button matRipple class="cta-buttons" (click)="useSignSecure()"  *ngIf="showSignatureButton">
        <p>Use SignSecure</p>
      </button>
    </div>

    <div class="center">
      <button (click)="zoomLevel('in')">
        <img
          class="action-btn"
          src="../../../assets/images/zoom-in.png"
          alt="" />
      </button>
      <button (click)="zoomLevel('out')">
        <img
          class="action-btn"
          src="../../../assets/images/zoom-out.png"
          alt="" />
      </button>
      <select name="" id="" [(ngModel)]="zoom">
        <option value="0.25">25%</option>
        <option value="0.5">50%</option>
        <option value="0.75">75%</option>
        <option value="1">100%</option>
        <option value="1.25">125%</option>
        <option value="1.5">150%</option>
        <option value="1.75">175%</option>
        <option value="2">200%</option>
        <option value="2.25">225%</option>
        <option value="2.5">250%</option>
        <option value="2.75">275%</option>
        <option value="3">300%</option>
      </select>
      <div class="divider"></div>
      <button matTooltip="Rotate Image">
        <img
          class="action-btn"
          src="../../../assets/images/rotate-btn.png"
          (click)="rotateImg()" />
      </button>
      <button matTooltip="Flip Horizontal">
        <img
          class="action-btn"
          src="../../../assets/images/flip-horizontal-btn.png"
          (click)="flipHorizontal()" />
      </button>
      <button matTooltip="Flip Vertical">
        <img
          class="action-btn"
          src="../../../assets/images/flip-vertical-btn.png"
          (click)="flipVertical()" />
      </button>
    </div>

    <div class="right">
      <button matRipple class="cta-buttons" (click)="openModal()">
        <img src="../../../assets//images/icons/share-white.svg" alt="" />
        <p>Share</p>
      </button>
      <button matRipple class="cancel" (click)="saveImageState()">
        Save Changes
      </button>
    </div>
  </div>
  <div class="img-container">
    <img
      class="img-preview"
      #imgPreview
      [ngStyle]="{
        transform:
          'rotate(' +
          angle +
          'deg) scaleX(' +
          horizontalScale +
          ') scaleY(' +
          verticalScale +
          ') scale(' +
          zoom +
          ')'
      }"
      [src]="fileSrc" />
  </div>
</div>
