<div class="upload-container">
  <button matRipple class="close" (click)="close()">
    <img src="../../../../assets/images/close.svg" alt="" srcset="" />
  </button>
  <h2 class="title">Upload your attachment</h2>

  <div
    class="drag-drop-container"
    appDragDropFileUpload
    (fileDropped)="dragover($event)"
  >
    <div class="container">
      <img src="../../../../assets/images/upload.svg" alt="" />
      <h4>Drag and Drop to upload</h4>
    </div>
    <div class="or-container">
      <p class="or">Or select from your computer</p>
    </div>
    <div class="container-button">
      <button matRipple (click)="upload()">Add Attachment</button>
    </div>
  </div>
  <div class="info-container">
    <p>Acceptable file formats: jpg, pdf, docx, xlsx, pptx, mp3, mp4, zip</p>
    <p>Max file size: 200MB</p>
  </div>
</div>

<input
  type="file"
  id="fileUpload"
  name="fileUpload"
  style="display: none"
  (change)="onFileSelected($event)"
  accept=".jpg,.png,.jpeg,.pdf,.docx,.doc,.xls,.xlsx,.ppt,.pptx,.zip,.mp3,.mp4"
  multiple
/>
