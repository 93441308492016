<ng-container>
  <div class="add-new-user">
    <h4 class="title">Create Group</h4>
    <div class="content">
      <div>
        <h4 class="content-title">Create Information</h4>
        <mat-label class="content-sub-title">Update the group information</mat-label>
      </div>

      <div class="input-group">
        <mat-label class="label">Group Name</mat-label>
        <mat-form-field floatLabel="auto" appearance="fill">
          <input
            type="name"
            matInput
            placeholder="Group Name"
            [(ngModel)]="groupName"
          />
        </mat-form-field>
      </div>
      <div class="input-group">
        <mat-label class="label">Add members to the group</mat-label>
        <mat-form-field floatLabel="auto" appearance="fill">
          <input
            type="email"
            placeholder="Email Address"
            (keydown.enter)="addMember()"
            matInput
            [(ngModel)]="groupMember"
            [matAutocomplete]="auto"
            [formControl]="userListControl"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of userlist | async"
              [value]="option"
              (click)="addMember()"
            >
              {{ option.email }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-chip-grid class="members">
          <mat-chip
            class="member-tag"
            *ngFor="let member of groupMembers; let i = index"
            >{{ member.email }}
            <img
              src="../../../assets/images/icons/close-icon.svg"
              alt=""
              (click)="removeMember(i)"
            />
          </mat-chip>
        </mat-chip-grid>
      </div>
      <div class="actions">
        <button matRipple class="cancel" (click)="back()">Cancel</button>
        <!-- <button matRipple class="cancel" (click)="closeModal()">Cancel</button> -->
        <button matRipple class="create" (click)="addUserGroup()">Create Group</button>
      </div>
    </div>
  </div>
</ng-container>
