import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  Router } from '@angular/router';
import { ModalData } from 'src/app/@shared/models/Modal.model';

@Component({
  selector: 'app-cancel-subscription-modal',
  templateUrl: './cancel-subscription-modal.component.html',
  styleUrls: ['./cancel-subscription-modal.component.sass']
})
export class CancelSubscriptionModalComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  @Input() data: ModalData = {
    type: '',
    action: '',
  };
  constructor(private _router:Router) { }

  ngOnInit(): void {
  }
  closeModal(confirm?: boolean) {
    this.closeModalEvent.emit(confirm);
  }
  
  cancelSubscription(){
    this._router.navigateByUrl('account/subscription');
    this.closeModal();
  }
}
