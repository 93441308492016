<div
  class="settings-preview"
  style=" {{ primaryColor }} {{ menuBackgroundColor }} {{ backgroundColor }} {{
    textColor
  }} {{ topbarColor }} {{ hoverColor }}"
>
  <div class="section">
    <h5 class="title">Dashboard preview</h5>
    <div class="dashboard-preview">
      <div class="preview-sidebar">
        <img
          [src]="logo"
          alt=""
          class="preview-logo"
        />
        <div class="preview-item first">
          <div class="icon"></div>
          <div class="content"></div>
        </div>
        <div class="preview-item">
          <div class="icon"></div>
          <div class="content"></div>
        </div>
        <div class="preview-item">
          <div class="icon"></div>
          <div class="content"></div>
        </div>
        <div class="preview-item">
          <div class="icon"></div>
          <div class="content"></div>
        </div>
      </div>
      <div class="preview-content-container">
        <div class="preview-top-bar"></div>
        <div class="preview-contents">
          <div class="preview-content">
            <div class="bottom"></div>
          </div>
          <div class="preview-content">
            <div class="bottom"></div>
          </div>
          <div class="preview-content">
            <div class="bottom"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <h5 class="title">Text preview</h5>
    <div class="font-preview">
      <div class="title">Header</div>
      <h6 class="header-preview">
        The quick brown fox jumps over the lazy dog
      </h6>
    </div>
    <div class="font-preview">
      <div class="title">Sub heading</div>
      <h6 class="sub-heading-preview">
        The quick brown fox jumps over the lazy dog
      </h6>
    </div>
    <div class="font-preview">
      <div class="title">Body</div>
      <h6 class="body-preview">The quick brown fox jumps over the lazy dog</h6>
    </div>
  </div>
</div>
