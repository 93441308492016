<div class="color-picker" style="{{ colorStyle }}">
  <!-- START: close state -->
  <div class="close-state {{ !opened ? 'open' : 'close' }}" (click)="open()">
    <div class="color-picked"></div>
    <p class="text">Choose a color</p>
  </div>

  <div class="open-state {{ opened ? 'open' : 'close' }}">
    <div class="color-details">
      <div class="color-picked"></div>
      <input [(ngModel)]="color" (ngModelChange)="updateColor($event)" />
      <!-- <p class="text">{{ color }}</p> -->
    </div>
    <hr />
    <div class="color-wrapper">
      <app-color-palette
        [hue]="hue"
        (color)="updateColor($event)"
        (done)="close()"
      ></app-color-palette>
      <app-color-slider (color)="hue = $event"></app-color-slider>
    </div>
  </div>

  <!-- END: close state -->
</div>
