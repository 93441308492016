import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, firstValueFrom, switchMap } from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { FilesService } from 'src/app/@shared/services/files.service';

@Component({
  selector: 'app-delete-file',
  templateUrl: './delete-file.component.html',
  styleUrls: ['./delete-file.component.sass'],
})
export class DeleteFileComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  @Input() data: ModalData = {
    type: '',
    action: '',
  };

  constructor(private _fileService: FilesService) {}

  ngOnInit(): void {
  }

  async deleteFile() {
    if (!this.data.isBulk) {
      for (let i = 0; i < this.data.data.length; i++) {
        await this._fileService.deleteFile(this.data.data[i].id);
      }
    } else {
      await firstValueFrom(this._fileService.deleteBulkFile(this.data.data));
    }
    this._fileService.getAllFilesAndFolder().subscribe(i => {
      const formattedData = this._fileService.formatFilesAndFolderData(i.data.data);
      const signSecure = {
        id: 'signsecure',
        title: ' ‌SignSecure workflow documents',
        file_type: 'folder',
        status: '',
      };
      this._fileService.setFilesAndFolderAsync([signSecure, ...formattedData]);
    });

    this.closeModal('deleted');
  }

  closeModal(confirm?: string) {
    this.closeModalEvent.emit('deleted');
  }
}
