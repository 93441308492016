import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignSecureService } from '../services/sign-secure.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FilesService } from 'src/app/@shared/services/files.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-signing-page-done',
  templateUrl: './signing-page-done.component.html',
  styleUrls: ['./signing-page-done.component.sass'],
})
export class SigningPageDoneComponent implements OnInit {
  @Input()
  data: any;

  lastLocation: string = '';
  returnText: string = ''
  constructor(
    private _sign: SignSecureService,
    private _nav: NavigationService,
    private _router: Router,
    private _loc: Location,
    private _file: FilesService
  ) {}

  ngOnInit(): void {
    this.lastLocation = this._nav.getLastLocation();
    this.returnText = `Return to ${this.lastLocation}`
  }

  async download() {
    const data = await lastValueFrom(this._sign.worflowData$);
    var blob = new Blob([this._sign.signPdfData], { type: 'application/pdf' });

    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = this._sign.signPdfName;
    link.download = fileName;
    await this._file.downloadDirectory(data?.id);
    link.click();
  }

  return() {
    if (this._nav.prevLocation === '/') {
      this._nav.setLocation('dashboard');
    } else {
      this._loc.back();
    }
  }
}
