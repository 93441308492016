import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.sass'],
})
export class GoogleLoginComponent implements OnInit, OnDestroy {
  subs: Subscription = new Subscription();
  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    private _navigation: NavigationService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this._route.queryParams.subscribe(({ accessToken, refreshToken }) => {
        this._authService.authenticateUser(accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        this._navigation.setLocation('dashboard');
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
