import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, of, switchMap } from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { UsersService } from 'src/app/@shared/services/users.service';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.sass'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  @Input() data: ModalData = {
    type: '',
    action: '',
  };
  @Output() closeModalEvent = new EventEmitter();
  private _subs = new Subscription();
  editUserForm: FormGroup = this.fb.group({
    givenName: [
      this.data.data?.givenName ? this.data.data.givenName : '',
      [Validators.required],
    ],
    lastName: [
      this.data.data?.lastName ? this.data.data.lastName : '',
      [Validators.required],
    ],
    email: [
      this.data.data?.email ? this.data.data.email : '',
      [Validators.required],
    ],
    position: [
      this.data.data?.position ? this.data.data.position : '',
      
    ],
    role: [
      this.data.data?.role ? this.data.data.role : '',
      [Validators.required],
    ],
  });

  constructor(private fb: FormBuilder, private _usersService: UsersService, 
    private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.editUserForm.controls['givenName'].setValue(this.data.data?.givenName);
    this.editUserForm.controls['lastName'].setValue(this.data.data?.lastName);
    this.editUserForm.controls['email'].setValue(this.data.data?.email);
    this.editUserForm.controls['role'].setValue(this.data.data?.role);
    this.editUserForm.controls['position'].setValue(this.data.data?.position);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  updateUser() {
    const user = this.getUserData();
    this._subs = this._usersService
      .udpateUser(user)
      .pipe(
        switchMap((i) => {
          return this._usersService.getUsers();
        }),
        catchError((error) => {
          // Handle the error here
          console.error('An error occurred:', );

          if (error.error.error == 'Resource already exists') {
            this._snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { type: 'error', text: 'The email already exists.' },
            });
          }
          // You can also return an observable with a default value or rethrow the error
          return of([]); // For example, return an empty array
        })
      )
      .subscribe((result) => {
        this._usersService.setUsers(
          this._usersService.formatUsers(result.data)
        );
        this.closeModal();
      });
  }
  closeModal(): void {
    this.closeModalEvent.emit();
  }

  private getUserData() {
    const { givenName, lastName, email, role, position } =
      this.editUserForm.controls;
    const user = { ...this.data.data };
    user['givenName'] = givenName.value;
    user['lastName'] = lastName.value;
    user['email'] = email.value;
    user['role'] = role.value;
    user['position'] = position.value;

    return user;
  }
}
