import { Component, OnInit } from '@angular/core';
import { FilesService } from 'src/app/@shared/services/files.service';
import { BehaviorSubject, lastValueFrom, of } from 'rxjs';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { AdvancedTaggingTemplateNavbarComponent } from '../template-navbar/template-navbar.component';
import { MatCardModule } from '@angular/material/card';
import {
  AsyncPipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { AdvanceTaggingMatterDetails } from '../material-details/matter-details.component';
import { AdvanceTaggingAddCaseComponent } from '../add-case/add-case.component';
import { AdvanceTaggingDocumentDetailsComponent } from '../document-details/document-details.component';
import { MatInputModule } from '@angular/material/input';
import { AdvanceTaggingService } from '../service/advance-tagging.service';
import { AdvancedTaggingReviewComponent } from '../review/review.component';

@Component({
  selector: 'advanced-tagging',
  templateUrl: './advanced-tagging.component.html',
  styleUrls: ['./advanced-tagging.component.sass'],
  standalone: true,
  imports: [
    AdvancedTaggingTemplateNavbarComponent,
    MatCardModule,
    NgSwitch,
    NgTemplateOutlet,
    AsyncPipe,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    AdvanceTaggingMatterDetails,
    AdvanceTaggingAddCaseComponent,
    AdvanceTaggingDocumentDetailsComponent,
    MatInputModule,
    AdvancedTaggingReviewComponent,
  ],
})
export class AdvancedTaggingComponent implements OnInit {
  //this.signSecureService.signSecurePage$;
  // options = this.signSecureService.signatureOptions;

  // data = this.signSecureService.worflowData$;
  aTProgress = this.advanceTaggingService.$advancedTaggingProgress;

  signatureData: any;

  pdfDetails: any;

  addedAll: any = false;

  qr: string = 'bottom-right';
  constructor(
    private advanceTaggingService: AdvanceTaggingService,
    private _files: FilesService
  ) {}

  async ngOnInit() {
    // this.signSecureService.clearWorkFlowData();
    // const data = await this.signSecureService.getSavedWorkflowData();
    // if (!!data) {
    //   try {
    //     if (typeof data.fileData === 'object') {
    //       data.fileData = Uint8Array.from(Object.values(data.fileData)).buffer;
    //     } else {
    //       if (data.fileData) {
    //         data.fileData = await lastValueFrom(
    //           this._files.getProxyUrl(data.fileData)
    //         );
    //         data.fileData = await data.fileData.arrayBuffer();
    //       }
    //     }
    //   } catch {}
    //   data.file = this.signSecureService.bytesToFile(data.fileData, data.name);
    //   this.signSecureService.setWorkFlowData(data);
    //   this.signSecureService.clearSavedData();
    //   this.signSecureService.nextProgress(2);
    // }
  }

  review(value: boolean) {
    // this.signSecureService.setSignatures(this.signatureData ?? [], this.qr);
    // this.signSecureService.nextProgress(5);
  }

  setSignatureData(data: any) {
    // this.signatureData = data;
    // this.signSecureService.setSignatures(data, this.qr);
  }

  setPdfDetails(data: any) {
    // this.pdfDetails = data;
  }

  addedAllSignatories(status: any) {
    // this.addedAll = status;
  }

  updateQRPosition(qrposition: string) {
    // this.qr = qrposition;
  }
}
