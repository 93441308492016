<form class="archive-container">
  <h4 class="title">Unarchive File</h4>

  <div class="content">
    <div class="input-group">
      <mat-label class="label">Barcode</mat-label>
      <mat-form-field appearance="fill">
        <input type="name" matInput placeholder="First Name" />

      </mat-form-field>
    </div>

    <div class="input-group">
      <mat-label class="label">Box Number</mat-label>
      <mat-form-field appearance="fill">
        <input type="name" matInput placeholder="First Name" />

      </mat-form-field>
    </div>

    <div class="input-group">
      <mat-label class="label">Archiver</mat-label>
      <mat-form-field  appearance="fill">
        <input type="name" matInput placeholder="First Name" />

      </mat-form-field>
    </div>

    <div class="input-group">
      <mat-label>Archive Date</mat-label>
      <mat-form-field
        appearance="fill"
        class="filter-form-field-date">
        <input
          matInput
          [matDatepicker]="archivePicker"
          placeholder="11 Oct 2020 - 19 July 2022" />
        <mat-datepicker-toggle matSuffix [for]="archivePicker">
          <mat-icon></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #archivePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="input-group">
      <mat-label>Retrieved Date</mat-label>
      <mat-form-field
        appearance="fill"
        class="filter-form-field-date">
        <input
          matInput
          [matDatepicker]="retirevePicker"
          placeholder="11 Oct 2020 - 19 July 2022" />
        <mat-datepicker-toggle matSuffix [for]="retirevePicker">
          <mat-icon></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #retirevePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="textarea-group">
      <mat-label>Remarks</mat-label>
      <mat-form-field  appearance="fill">
        <textarea class="textarea-resize" matInput></textarea>
      </mat-form-field>
    </div>

    <div class="actions">
      <button matRipple class="cancel">Cancel</button>
      <button matRipple class="create">
        <ng-container>Archive</ng-container>
        <ng-container>Unarchive</ng-container>
      </button>
    </div>
  </div>
</form>
