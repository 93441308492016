<div class="subs-container">
  <div class="header">
    <h4 class="title">Subscriptions</h4>
    <div class="breadcrumb">
      <a>My Account > </a>
      <a>Subscriptions</a>
    </div>
  </div>
  <div class="body">
    <div class="subscription-overview" *ngIf="isCancelSub">
      <div class="left">
        <a class="title">Subscription Cancelled</a>
        <a class="sub-title"
          >You have cancelled your subscription. You have 15 days to back-up
          your data.</a
        >
      </div>
      <div class="line"></div>
      <div class="right">
        <div class="plan-type">
          <a class="title">SignSecure</a>
          <a class="sub-title">Professional Plan</a>
        </div>
        <div class="expiration">
          <a>Expires on Dec 1 2022</a>
        </div>
      </div>
    </div>
    <div class="available-subscriptions">
      <a class="title subs-title">Available Subscription</a>
      <div class="cards-container">
        <!--TODO: add service method to allow only one checkbox to be checked -->
        <app-subscription-card
          *ngFor="let count of counts; let i = index"
          [data]="subData[i]"
          [index]="i"
          [selected]="subIndex"
        ></app-subscription-card>
      </div>
      <div class="btn-row">
        <button (click)="goToPayment()">Subscribe</button>
      </div>
    </div>
  </div>
</div>
