import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { lastValueFrom, map, switchMap } from 'rxjs';
import { ImagePropertyModel } from 'src/app/@shared/models/file-data.model';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { ModalComponent } from '../modal/modal.component';
import { Location } from '@angular/common';
import { PDFDocument, PageSizes } from 'pdf-lib';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.sass'],
})
export class ImageViewComponent implements OnInit {
  @ViewChild('imgPreview') imgPreview!: ElementRef;
  @Input()
  id: string = '';
  imagePath: any;
  zoom: number = 1;
  totalPages: number = 0;
  pageNumber: number = 1;
  fileSrc: any = '';
  private renderer!: Renderer2;
  angle: number = 0;
  horizontalScale: number = 1;
  verticalScale: number = 1;
  vFlip: boolean = false;
  hFlip: boolean = false;
  rotate: string = '';
  fileId: string = '';
  imgProperties!: ImagePropertyModel;
  showSignatureButton: boolean = true;
  fileData: any;
  constructor(
    public dialog: MatDialog,
    private _fileActionService: FileActionService,
    private _fileService: FilesService,
    private _router: Router,
    private _location: Location,
    private _nav: NavigationService,
    private _sign: SignSecureService
  ) {}

  ngOnInit() {
    this.fileId = this.id;

    this._fileService
      .getFile(this.id)
      .pipe(
        switchMap(({ data }) => {
          this.fileData = data;
          this.imgProperties = data.properties || {
            rotate: '0deg',
            horizontalFlip: false,
            verticalFlip: false,
          };
          this.checkImgProperties();
          return this._fileService.getProxyUrl(this.id);
        }),
        switchMap(response => {
          this.imagePath = response;
          this._fileService.blobToBase64(response).then(image => {
            this.fileSrc = image;
          });
          return this._fileService.viewDirectory(this.fileId);
        })
      )
      .subscribe();
  }

  checkImgProperties() {
    if (this.imgProperties.horizontalFlip) {
      this.horizontalScale = -1;
    } else {
      this.horizontalScale = 1;
    }

    if (this.imgProperties.verticalFlip) {
      this.verticalScale = -1;
    } else {
      this.verticalScale = 1;
    }

    this.rotate = this.imgProperties.rotate;
    this.angle = +this.rotate.split('deg')[0];
  }

  zoomLevel(level: string) {
    switch (level) {
      case 'in':
        if (this.zoom <= 2.75) {
          this.zoom = +this.zoom + +0.25;
        }
        break;
      case 'out':
        if (this.zoom >= 0.5) {
          this.zoom = this.zoom - 0.25;
        }
        break;
      default:
        break;
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
  }

  pageNavi(navi: string) {
    switch (navi) {
      case 'up':
        if (this.pageNumber > 1) this.pageNumber = this.pageNumber - 1;
        break;
      case 'down':
        if (this.pageNumber < this.totalPages)
          this.pageNumber = this.pageNumber + 1;

        break;
      default:
        break;
    }
  }

  goBack() {
    this._location.back();
  }

  download(name?: string) {
    this._fileService.downloadFile(this.fileData.name, this.imagePath);
  }
  openModal() {
    const file = this.fileData;

    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: file.name,
        id: file.id,
        data: [...file.userGroups, ...file.users] || [],
      },
    });

    // dialogRef?.afterClosed()?.subscribe((result) => {
    //   this.callback.emit(result);
    // });
  }
  rotateImg() {
    this.angle = this.angle - 90;
    if (this.angle == -360) {
      this.angle = 0;
    }
    this.rotate = this.angle + 'deg';
  }
  flipHorizontal() {
    if (this.horizontalScale == 1) this.horizontalScale = -1;
    else if (this.horizontalScale == -1) this.horizontalScale = 1;
    switch (this.horizontalScale) {
      case 1: {
        this.hFlip = false;
        break;
      }
      case -1: {
        this.hFlip = true;
        break;
      }
      default: {
        break;
      }
    }
  }
  flipVertical() {
    if (this.verticalScale == 1) this.verticalScale = -1;
    else if (this.verticalScale == -1) this.verticalScale = 1;
    switch (this.verticalScale) {
      case 1: {
        this.vFlip = false;
        break;
      }
      case -1: {
        this.vFlip = true;
        break;
      }
      default: {
        break;
      }
    }
  }
  saveImageState() {
    this._fileService
      .saveImageState(this.fileId, this.rotate, this.hFlip, this.vFlip)
      .subscribe(i => {});
  }

  async useSignSecure(name?: string) {
    const file =
      this._fileActionService?.file?.actions?.download ||
      this._fileActionService?.file?.actions?.choices?.download ||
      this._fileActionService?.file?.actions?.download_normal;
    let filename = file.name;

    // get file
    const bytes = await lastValueFrom(this._fileService.getProxyUrl(file.id))

    // Fetching

    var blob = new Blob([bytes]); // create blob...
    window.URL = window.URL || window.webkitURL;
    var blobURL = window.URL.createObjectURL(blob); // and get it's URL

    // helper Image object
    var img = new Image();
    img.src = blobURL;
    //preview.appendChild(image); // preview commented out, I am using the canvas instead
    img.onload = async () => {
      // have to wait till it's loaded
      let resized = null;
      let size = 0;
      let reduction = 1;
      do {
        resized = this.resizeMe(img, file.fileExtension, reduction);
        size = resized.length * (3 / 4) - 2;
        reduction++;
        console.log({ resized, ext: file.fileExtension });

        console.log({ size, reduction });
      } while (size > 200000);
      // send it to canvas

      const pdfDoc = await PDFDocument.create({});

      let image;
      if (resized.includes('image/jpg;')) {
        image = await pdfDoc.embedJpg(resized);
      } else if (resized.includes('image/png;')) {
        image = await pdfDoc.embedPng(resized);
      } else {
        console.log('Invalid File');
        return;
      }

      const { width, height } = image.scaleToFit(
        PageSizes.Folio[0],
        PageSizes.Folio[1]
      );
      const page = pdfDoc.addPage([width, height]);

      page.drawImage(image, { x: 0, y: 0, width, height });

      const pdfBytes = await pdfDoc.save();

      const fileObj = this._sign.bytesToFile(pdfBytes, filename);

      
      this._sign.clearWorkFlowData();
      this._sign.setId(file.id);
      this._sign.setFile(fileObj, pdfBytes);
      this._sign.setFileInfo(file.name, file.details);
      this._sign.saveWorkflowData();
      this._sign.nextProgress(2);
      this._nav.setLocation('sign-secure')
    };

  }

  resizeMe(img: any, ext: string, reduction: number) {
    const canvas = document.createElement('canvas');

    let width = img.width;
    let height = img.height;

    const max_width = PageSizes.Folio[0] / (0.95 + 0.05 * reduction);
    const max_height = PageSizes.Folio[1] / (0.95 + 0.05 * reduction);

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        //height *= max_width / width;
        height = Math.round((height *= max_width / width));
        width = max_width;
      }
    } else {
      if (height > max_height) {
        //width *= max_height / height;
        width = Math.round((width *= max_height / height));
        height = max_height;
      }
    }

    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0, width, height);

    return canvas.toDataURL(`image/${ext}`, 1); // get the data from canvas as 70% JPG (can be also PNG, etc.)
  }
}
