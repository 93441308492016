<div class="restrict-container">
	<div class="title">
		<img src="../../../../assets/images/lock.svg" alt="" />
		<span>Document restricted</span>
	</div>
	<div class="description">
		<p>The document you are trying to view is confidential/restricted.
		Please log in first to view</p>
	</div>
	<div class="button" (click)="close()">Back to log in</div>
</div>
