<div class="tablet-table">
  <div class="tablet-table__header">
    <h3 class="tablet-table__title">{{ title }}</h3>
    <ng-content select=".button"></ng-content>
  </div>
  <div class="tablet-table__content">
    <div class="tablet-table__item" *ngFor="let datum of pageData">
      <ng-container
        *ngTemplateOutlet="itemTemplate; context: { $implicit: datum }"
      >
      </ng-container>
    </div>
    <div class="empty" *ngIf="data?.length <= 0">
      <img src="../../../assets/images/empty-folder.svg" alt="" srcset="" />
      <p>There is currently no data available.</p>
    </div>
  </div>
  <div class="paginator"> <mat-paginator
    
    *ngIf="isPaginate"
    [length]="resultsLength"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="changePage($event)"
  ></mat-paginator></div>
 
</div>
