import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  combineLatest,
  combineLatestWith,
  firstValueFrom,
  Observable,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import { DashboardCardModel } from 'src/app/@shared/models/dasboard.model';
import { FileTableModel } from 'src/app/@shared/models/file-table.model';
import { UserModel } from 'src/app/@shared/models/user.model';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { BreadcrumbsService } from 'src/app/@shared/services/breadcrumbs.service';
import { DashBoardServices } from 'src/app/@shared/services/dashboard.service';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { WorkflowService } from 'src/app/@shared/services/workflow.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { UserResponseModel } from 'src/app/@shared/models/user-response.model';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { MatDialog } from '@angular/material/dialog';
import { dashFilter } from 'src/app/@shared/enums/dashboard-filter.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
@Component({
  selector: 'app-admin-dashboard-report',
  templateUrl: './admin-dashboard-report.component.html',
  styleUrls: ['./admin-dashboard-report.component.sass'],
})
export class AdminDashboardReportComponent implements OnInit, OnDestroy {
  recentDocmentTitle: string = 'Recent Documents';
  recentUsersTitle: string = 'Recent Users';
  recentDocumentCols: string[] = ['name', 'tags', 'date', 'actions'];
  recentDocumentSortCols = {
    name: true,
  };
  recentDocumentData: Observable<any> = this._fileService.recentfiles$;
  signSecureDocumentTitle: string = 'SignSecure Documents';
  signSecureDocumentData: Object[] = [];
  signSecureDocumentDataHolder: Object[] = [];
  signSecureDocumentDataAll: Object[] = [];
  signSecureDocumentCols: string[] = [
    'title',
    'status',
    'Date Created',
    'Last Updated',
    'actions',
  ];

  signSecureDocumentSortCols = {
    title: true,
  };

  dashData: DashboardCardModel = {
    allDocuments: '0',
    viewed: '0',
    signed: '0',
    pending: '0',
    completed: '0',
  };

  searchQuery: string = '';
  recentFoldersCols: string[] = ['name', 'tags', 'date', 'actions'];
  recentFolders: any[] = [];

  recentUsersCols: string[] = ['Name', 'Email', 'Date Added'];
  private _subs: Subscription = new Subscription();
  filterEnum = dashFilter;
  recentUsersData: Object[] = [];

  userType = this._authService.userRole;

  isTabletWidth = false;
  isPhoneWidth = false;

  constructor(
    private _usersService: UsersService,
    private _fileService: FilesService,
    private _dashboardService: DashBoardServices,
    private _authService: AuthenticationService,
    private _fileActionService: FileActionService,
    private _breadcrumbs: BreadcrumbsService,
    private _navigation: NavigationService,
    private _workflowService: WorkflowService,
    private router: Router,
    private _signSecureService: SignSecureService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // this.isTabletWidth = (screen.width >= 481 && screen.width <= 1279) ? true : false;
    if (screen.width >= 280 && screen.width <= 600) {
      this.isPhoneWidth = true;
    } else if (screen.width >= 600 && screen.width <= 1368) {
      this.isTabletWidth = true;
    } else {
      this.isPhoneWidth = false;
      this.isTabletWidth = false;
    }

    if (this.userType !== 'user') {
      this._subs.add(
        this._usersService.getUsers('desc').subscribe(users => {
          this.recentUsersData = users.data.slice(0, 7).map((i: UserModel) => {
            return {
              name: `${i.givenName} ${i.lastName}`,
              email: i.email,
              date_added: i.lastAccessedFormatted?.split(',')[0],
            };
          });
        })
      );
    } else {
      this._subs.add(
        this._fileService.getAllFolder(false).subscribe(({ data }) => {
          const recentFoldersData = data.data.slice(0, 7);
          this.recentFolders = recentFoldersData.map((datum: any) => {
            return {
              name: datum.name || '',
              date: datum.createdAtFormatted?.split(',')[0],
              tags: datum.tags || [],
              actions: {
                download: datum,
                info: { data: datum, action: 'recent-doc-info' },
              },
            };
          });
        })
      );
    }
    this._subs.add(
      this._fileService.getRecentFiles().subscribe(({ data }) => {
        this._fileService.setRecentFiles(
          this._fileService.formatRecentFile(data.data)
        );
      })
    );
    this._subs.add(
      this._dashboardService.getDashboardData().subscribe(dashData => {
        this.dashData = dashData.data;
      })
    );

    const email = localStorage.getItem('email');
    this.filterWorkflowData(dashFilter.pending, true);

    if (this._navigation.hasPublicData()) {
      const data = this._navigation.getPublicData();
      if (typeof data === 'string') {
        this._navigation.setLocation(`sign-secure/${data}`);
      }
    }

    if (this._navigation.hasFileData()) {
      const data = this._navigation.getFileData();
      if (typeof data === 'string') {
        this._navigation.setLocation(`file-view/pdf/${data}`);
      }
    }

    if (this._navigation.noAccess) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: {
          type: 'error',
          text: 'No permission to open the document',
        },
      });
      this._navigation.noAccess = false;
    }
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  dateConverter(date: string) {
    return new Date(date);
  }
  recentDocumentModal(data: any) {
    if (data) {
      const { data: mData } = data;
      const dialogRef = mData.dialog?.open(ModalComponent, {
        width: '636px',
        data: {
          action: 'share-permission',
          title: mData.title,
          fileId: mData.fileId,
          data: mData.data,
          fileType: mData.type,
          fileExtension: mData.fileExtension,
        },
      });

      dialogRef?.afterClosed()?.subscribe((data: any) => {});
    }
  }

  openPdf(data: FileTableModel) {
    if (data.file_type === 'folder' || data.file_type === 'back') {
      return;
    }

    let url =
      data?.actions?.choices?.download?.path || data?.actions?.download?.path;

    if (
      data.fileExtension == 'docx' ||
      data.fileExtension == 'doc' ||
      data.fileExtension == 'xls' ||
      data.fileExtension == 'xlsx' ||
      data.fileExtension == 'ppt' ||
      data.fileExtension == 'pptx' ||
      data.fileExtension == 'zip' ||
      data.fileExtension == 'rar' ||
      data.fileExtension == '7z'
    ) {
      let office = '';
      if (data.fileExtension == 'docx' || data.fileExtension == 'doc') {
        office = 'ms-word';
      }
      if (data.fileExtension == 'xls' || data.fileExtension == 'xlsx') {
        office = 'ms-excel';
      }
      if (data.fileExtension == 'ppt' || data.fileExtension == 'pptx') {
        office = 'ms-powerpoint';
      }
      this._fileService.getProxyUrl(data.id).subscribe(msData => {
        this._fileService.downloadFile(data.name || '', msData);
      });
      return;
    }

    if (data.fileExtension == 'mp4' || data.fileExtension === 'mp3') {
      const dialogRef = this.dialog.open(ModalComponent, {
        panelClass: 'transparent-dialog',
        backdropClass: 'dark-bg',
        data: {
          action: 'media-viewer',
          data: data,
        },
      });
    } else {
      this._fileActionService.pdfSrc = url;
      this._fileActionService.file = data;
      this._fileActionService.setIsViewFile(true);
      this.router.navigateByUrl(`/file-view/${data.fileExtension}/${data.id}`);
    }
  }

  openFolder(event: any, data: any) {
    if (event && event.target.localName == 'img') {
      return;
    }

    const { download } = data.actions;

    this._breadcrumbs.setBreadcrumbsData({
      title: download?.name,
      id: download?.id,
    });
    this._fileService.getAllFolderFiles(download.id).subscribe(({ data }) => {
      const format = this._fileService.formatFilesAndFolderData(
        data,
        false,
        true
      );
      this._fileService.setFilesAndFolderAsync(format);
      this._navigation.setLocation('folders-and-files');
    });
  }

  openSignSecureDoc(event: any, element: any) {
    if (event && event.target.localName == 'img') {
      return;
    }

    this._signSecureService.setWorkFlowData(element);
    if (element.actions.delete) {
      return;
    } else {
      this.router.navigateByUrl(`sign-secure/${element.id}`);
    }
  }

  openSignSecure(data: any) {
    this._navigation.setLocation(`sign-secure/${data.id}`);
  }

  useSignSecure() {
    this.router.navigateByUrl(`sign-secure`);
  }

  async filterWorkflowData(filter: dashFilter, first = false) {
    await firstValueFrom(
      this._dashboardService.filterDashboardData(filter).pipe(
        tap(({ data }) => {
          this.signSecureDocumentDataHolder = this.signSecureDocumentData =
            this._signSecureService.formatWorkflowData(data.items);

          if (!data.items.length && first && filter === dashFilter.pending) {
            this.filterWorkflowData(dashFilter.all);
          }
        })
      )
    );
  }

  loadSignSecureDocs() {
    this._fileService.getWorkflowData(0).subscribe(data => {
      this.signSecureDocumentDataHolder = this.signSecureDocumentData =
        this._signSecureService.formatWorkflowData(data.data);
    });
  }

  search() {
    if (this.searchQuery.trim().length == 0) {
      this.signSecureDocumentData = this.signSecureDocumentDataHolder;
    } else {
      this.signSecureDocumentData = this.signSecureDocumentDataHolder.filter(
        (file: any) => {
          if (
            file.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            file.status.toLowerCase().includes(this.searchQuery.toLowerCase())
          ) {
            return file;
          } else {
            return;
          }
        }
      );
    }
  }
}
