import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { filter, map, Observable, startWith } from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { UserGroupModel } from 'src/app/@shared/models/user-group.model';
import { UserModel } from 'src/app/@shared/models/user.model';

@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.sass'],
})
export class UserGroupComponent {
  @Output() closeModalEvent = new EventEmitter();
  @Input() data: ModalData = {
    type: '',
    action: '',
  };
  groupName: string = '';
  groupMembers: any[] = [];
  groupMember: any = '';
  userListControl = new FormControl<string>('');
  users: UserModel[] = [];
  userlist: Observable<any[]>;
  constructor() {
    this.userlist = new Observable<String[]>();
  }

  addMember() {
    this.groupMembers.push({
      userGroupId: '',
      email: this.groupMember.email,
      userId: this.groupMember.id,
    });

    this.users = this.users.filter((data) => {
      return data.email != this.groupMember.email;
    });
    this.groupMember = '';
  }

  removeMember(index: number) {
    if (index > -1) {
      // only splice array when item is found
      this.groupMembers.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  getUserGroupData(): UserGroupModel {
    const data: UserGroupModel = {
      groupName: this.groupName,
      members: this.groupMembers,
      role: '',
    };

    return data;
  }

  closeModal() {
    this.closeModalEvent.emit();
  }

  filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.users.filter(
      (value) =>
        value.email.includes(filterValue) &&
        !this.groupMembers.some((member) => member.email === value.email)
    );
  }

  updateList() {
    if (this.groupName.length >= 2) {
    }
    this.users
      .filter((value) => value.email.includes(this.groupName))
      .map((user) => user.email);
  }
}
