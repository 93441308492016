<app-spinner (compLoaded)="spinnerLoaded = $event"></app-spinner>
<div
  class="container"
  *ngIf="spinnerLoaded"
  [ngStyle]="{ overflow: (isLoading | async) ? 'hidden' : 'auto' }"
>
  <router-outlet></router-outlet>
</div>

<!-- <ng-container
  *ngIf="
    menuType == 'folders-and-files' &&
    !isSearch &&
    !modifyFile &&
    !viewPdf &&
    spinnerLoaded
  "
>
  <div class="list-wrapper">
    <app-document-tree></app-document-tree>
    <router-outlet></router-outlet>
  </div>
</ng-container> -->
