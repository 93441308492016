import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { AdvanceTaggingService } from '../service/advance-tagging.service';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.sass'],
  standalone: true,
})
export class AdvanceTaggingStepComponent implements OnInit {
  @Input()
  step: any = null;

  @Input()
  index: number = 0;

  @Input()
  move: Function = () => {};

  workflow: any;
  constructor(
    private _stepsNav: AdvanceTaggingService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // this._stepsNav.worflowData$.subscribe(workflow => {
    //   this.workflow = workflow;
    // });
  }

  changeSection(templateSection: number) {
    if (this.step.status === 'done') {
      //this.move(templateSection);
      this._stepsNav.setATProgress(templateSection);
    }
  }

  getIcon() {
    console.log({ 'background-image': this.step[this.step.status] });
    return { 'mask-image': `url(${this.step[this.step.status]})` };
  }
}
