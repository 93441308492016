<div class="wrapper">
  <mat-accordion class="document-tree-wrapper" [displayMode]="'flat'">
    <mat-expansion-panel [togglePosition]="'before'" #allFoldersFiles>
      <mat-expansion-panel-header>
        <mat-panel-title> All Folders and Files</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="panel-content">
        <mat-tree
          [dataSource]="dataSource"
          [treeControl]="treeControl"
          class="example-tree"
          #tree
        >
          <!-- This is the tree node template for leaf nodes -->
          <!-- There is inline padding applied to this node using styles.
              This padding value depends on the mat-icon-button width. -->
          <mat-tree-node
            *matTreeNodeDef="let node"
            matTreeNodeToggle
            (click)="openFolder(node)"
          >
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name"
              class="tree-button"
              (click)="openFolder(node)"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
                }}
              </mat-icon>
              <img
                src="../../../../assets/images/icons/folder.png"
                class="tree-image"
              />
            </button>

            <p
              class="center-text {{
                node.name.length > 20 ? 'limit-text' : ''
              }}"
              [matTooltip]="node.name.length > 20 ? node.name : ''"
            >
              {{ node.name }}
            </p>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            (click)="openFolder(node)"
          >
            <div class="mat-tree-node">
              <button
                class="tree-button"
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name"
                (click)="openFolder(node)"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    treeControl.isExpanded(node)
                      ? "expand_more"
                      : "chevron_right"
                  }}
                </mat-icon>
                <img
                  src="../../../../assets/images/icons/folder.png"
                  class="tree-image"
                />
              </button>
              <p
                class="tree-text center-text {{
                  node.name.length > 20 ? 'limit-text' : ''
                }}"
                [matTooltip]="node.name.length > 20 ? node.name : ''"
              >
                {{ node.name }}
              </p>
            </div>
            <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
            <div
              [class.example-tree-invisible]="!treeControl.isExpanded(node)"
              role="group"
            >
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </mat-expansion-panel>





    
    <mat-expansion-panel [togglePosition]="'before'">
      <mat-expansion-panel-header>
        <mat-panel-title> Recents </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="panel-content">
        <mat-tree
          [dataSource]="recentDataSource"
          [treeControl]="recentTreeControl"
          class="example-tree"
        >
          <!-- This is the tree node template for leaf nodes -->
          <!-- There is inline padding applied to this node using styles.
              This padding value depends on the mat-icon-button width. -->
          <mat-tree-node
            *matTreeNodeDef="let node"
            matTreeNodeToggle
            (click)="openFolderRecent(node)"
          >
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name"
              class="tree-button"
              (click)="openFolderRecent(node)"
            >
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
                }}
              </mat-icon>
              <img
                src="../../../../assets/images/icons/folder.png"
                class="tree-image"
              />
            </button>
            <p
              class="center-text {{
                node.name.length > 20 ? 'limit-text' : ''
              }}"
              [matTooltip]="node.name.length > 20 ? node.name : ''"
            >
              {{ node.name }}
            </p>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            (click)="openFolderRecent(node)"
          >
            <div class="mat-tree-node">
              <button
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name"
                (click)="openFolderRecent(node)"
              >
                <mat-icon class="mat-icon-rtl-mirror">
                  {{
                    treeControl.isExpanded(node)
                      ? "expand_more"
                      : "chevron_right"
                  }}
                </mat-icon>
              </button>
              <p
                class="center-text {{
                  node.name.length > 20 ? 'limit-text' : ''
                }}"
                [matTooltip]="node.name.length > 20 ? node.name : ''"
              >
                {{ node.name }}
              </p>
            </div>
            <!-- There is inline padding applied to this div using styles.
                    This padding value depends on the mat-icon-button width.  -->
            <div
              [class.example-tree-invisible]="!treeControl.isExpanded(node)"
              role="group"
            >
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
