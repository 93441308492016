import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-support-upload',
  templateUrl: './support-upload.component.html',
  styleUrls: ['./support-upload.component.sass'],
})
export class SupportUploadComponent implements OnInit {
  buttonText: string = 'Add files';
  value: string = 'files';

  @Output() closeModalEvent = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onChangeOption({ value }: any) {
    this.buttonText = `Add ${value}`;
    this.value = value;
  }

  dragover(data: FileList) {
    this.closeModalEvent.emit(data);
  }

  onFileSelected(data: any) {
    const regex =
      /.*\.(jpg|JPG|png|PNG|pdf|PDF|doc|DOC|docx|DOCX|xls|XLS|xlsx|XLSX|ppt|PPT|pptx|PPTX|mp3|MP3|mp4|MP4|zip|ZIP)$/g;
    let files = [];
    if (data.path) {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else if (data.target) {
      files = Array.from(data.target.files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    }

    this.closeModalEvent.emit(files);
  }

  upload() {
    document.getElementById('fileUpload')?.click();
  }

  close() {
    this.closeModalEvent.emit();
  }
}
