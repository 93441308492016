import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, throwError } from 'rxjs';
import { PwordRegex } from 'src/app/@shared/constants/regex.const';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass'],
})
export class ResetPasswordComponent implements OnInit {
  hide: Boolean = true;
  hideConfirm: Boolean = true;

  passwordReset: FormGroup = this.fb.group({
    password: [
      '',
      [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(PwordRegex),
      ],
    ],
    confirmPassword: ['', [Validators.required, this.checkPasswordMatch()]],
  });

  required: boolean = false;
  errorMsg: any;
  isValid: boolean = true;
  email: string = '';
  isPwordMatch: boolean = true;
  pwordErrorMsg: string = '';
  token: string = '';
  constructor(
    private fb: FormBuilder,
    private _authService: AuthenticationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _loader: LoaderService,
    private _snack: MatSnackBar
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe(({ token }) => {
      this.token = token;
    });
  }

  checkPasswordMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      return control.value !== this.passwordReset.controls['password'].value
        ? { pwordNotMatch: true }
        : null;
    };
  }

  checkPassword() {
    if (
      this.passwordReset.controls['password'].dirty &&
      this.passwordReset.controls['confirmPassword'].dirty
    ) {
      if (
        this.passwordReset.controls['password'].value !=
        this.passwordReset.controls['confirmPassword'].value
      ) {
        this.pwordErrorMsg = 'The password does not match';
        this.isPwordMatch = false;
      } else {
        this.pwordErrorMsg = '';
        this.isPwordMatch = true;
      }
    }
  }

  changePassword() {
    if (this.passwordReset.controls['password'].value === '') {
      this.passwordReset.controls['password'].markAsDirty();
    }

    if (this.passwordReset.controls['confirmPassword'].value === '') {
      this.passwordReset.controls['confirmPassword'].markAsDirty();
    }

    if (this.passwordReset.valid) {
      const resetPass = {
        resetToken: this.token,
        password: this.passwordReset.controls['password'].value,
        confirmPassword: this.passwordReset.controls['confirmPassword'].value,
      };
      this._loader.show();
      this._authService
        .resetPassword(resetPass)
        .pipe(
          catchError(err => {
            this._snack.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: {
                type: 'error',
                text: err?.error?.error?.slice(15) ?? 'Something went wrong',
              },
            });
            this._loader.hide();
            return of();
          })
        )
        .subscribe(data => {
          this._router.navigate(['/reset-password/success', this.token], {
            relativeTo: this._route,
          });
          this._loader.hide();
        });

      // this.resetPassword.emit('reset-password-success');
    }
  }
}
