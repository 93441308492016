<ng-container>
  <div class="container">
    <div class="body margin-container">
      <div class="recents {{ userType }}">
        <div class="statistics">
          <mat-tab-group mat-stretch-tabs>
            <mat-tab label="All Documents"> 
              <ng-template mat-tab-label>
                <app-tabs 
                [number]="dashData.allDocuments" 
                [title]="'All Documents'"
                [location]="'documents'"
                (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.all)">
              </app-tabs>
              </ng-template>
              <app-table
                *ngIf="!isTabletWidth && !isPhoneWidth"
                [title]="''"
                [data]="signSecureDocumentData"
                [columns]="signSecureDocumentCols"
                [sortColumns]="signSecureDocumentSortCols"
                [canOpenFile]="true"
                [type]="'recent-documents'"
                (modalCloses)="recentDocumentModal($event)"
                [rowFunction]="openSignSecureDoc"
                [paginate]="true"
                (modal)="useSignSecure()"
                [pageSize]="5"
                class="signsecure"
              >
              </app-table>
            </mat-tab>
            <mat-tab label="Viewed"> 
              <ng-template mat-tab-label>
                <app-tabs 
                  [number]="dashData.viewed" 
                  [title]="'Viewed'"
                  [location]="'viewed'"
                  (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.viewed)">
                </app-tabs>
              </ng-template>
              <app-table
                *ngIf="!isTabletWidth && !isPhoneWidth"
                [title]="''"
                [data]="signSecureDocumentData"
                [columns]="signSecureDocumentCols"
                [sortColumns]="signSecureDocumentSortCols"
                [canOpenFile]="true"
                [type]="'recent-documents'"
                (modalCloses)="recentDocumentModal($event)"
                [rowFunction]="openSignSecureDoc"
                [paginate]="true"
                (modal)="useSignSecure()"
                [pageSize]="5"
                class="signsecure"
              >
              </app-table>
            </mat-tab>
            <mat-tab label="Pending"> 
              <ng-template mat-tab-label>
                <app-tabs 
                  [number]="dashData.pending" 
                  [title]="'Pending'"
                  [location]="'pending'"
                  (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.pending)">
                </app-tabs>
              </ng-template>
              <app-table
                *ngIf="!isTabletWidth && !isPhoneWidth"
                [title]="''"
                [data]="signSecureDocumentData"
                [columns]="signSecureDocumentCols"
                [sortColumns]="signSecureDocumentSortCols"
                [canOpenFile]="true"
                [type]="'recent-documents'"
                (modalCloses)="recentDocumentModal($event)"
                [rowFunction]="openSignSecureDoc"
                [paginate]="true"
                (modal)="useSignSecure()"
                [pageSize]="5"
                class="signsecure"
              >
              </app-table>
            </mat-tab>
            <mat-tab label="Signed"> 
              <ng-template mat-tab-label>
                <app-tabs 
                  [number]="dashData.signed" 
                  [title]="'Signed'"
                  [location]="'signed'"
                  (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.signed)">
                </app-tabs>
              </ng-template>
              <app-table
                *ngIf="!isTabletWidth && !isPhoneWidth"
                [title]="''"
                [data]="signSecureDocumentData"
                [columns]="signSecureDocumentCols"
                [sortColumns]="signSecureDocumentSortCols"
                [canOpenFile]="true"
                [type]="'recent-documents'"
                (modalCloses)="recentDocumentModal($event)"
                [rowFunction]="openSignSecureDoc"
                [paginate]="true"
                (modal)="useSignSecure()"
                [pageSize]="5"
                class="signsecure"
              >
              </app-table>
            </mat-tab>
            <mat-tab label="Completed"> 
              <ng-template mat-tab-label>
                <app-tabs 
                  [number]="dashData.completed" 
                  [title]="'Completed'"
                  [location]="'completed'"
                  (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.completed)">
                </app-tabs>
              </ng-template>
              <app-table
                *ngIf="!isTabletWidth && !isPhoneWidth"
                [title]="''"
                [data]="signSecureDocumentData"
                [columns]="signSecureDocumentCols"
                [sortColumns]="signSecureDocumentSortCols"
                [canOpenFile]="true"
                [type]="'recent-documents'"
                (modalCloses)="recentDocumentModal($event)"
                [rowFunction]="openSignSecureDoc"
                [paginate]="true"
                
                (modal)="useSignSecure()"
                [pageSize]="5"
                class="signsecure"
              >
              </app-table>
            </mat-tab>
          </mat-tab-group>
        </div>
  
        <div  class="recents {{ userType }}">
        <app-tablet-table
          *ngIf="isTabletWidth || isPhoneWidth"
          [itemTemplate]="signsecure_document_row"
          [title]="signSecureDocumentTitle"
          [data]="signSecureDocumentData"
        >
          <button *ngIf="isTabletWidth" class="button" (click)="useSignSecure()">
            <img [src]="'../../../../assets/images/signature-icon.svg'" alt="" />
            Use SignSecure
          </button>
        </app-tablet-table>
  
        <div class="recent-admin">
          <app-tablet-table
            *ngIf="isTabletWidth || isPhoneWidth"
            [itemTemplate]="recent_document_row"
            [title]="recentDocmentTitle"
            [data]="recentDocumentData | async"
          >
          </app-tablet-table>

          <app-tablet-table
            *ngIf="userType === 'admin' && (isTabletWidth || isPhoneWidth)"
            [itemTemplate]="recent_user_row"
            [title]="recentUsersTitle"
            [data]="recentUsersData"
          >
          </app-tablet-table>

          <app-tablet-table
            *ngIf="(isTabletWidth || isPhoneWidth) && userType === 'user'"
            [itemTemplate]="recent_folder_row"
            [title]="'Recent Folders'"
            [data]="recentFolders"
          >
          </app-tablet-table>
        </div>
  
        </div>
        <div class="recent-admin" *ngIf="!isTabletWidth && !isPhoneWidth">

          <app-table
            [title]="recentDocmentTitle"
            [data]="recentDocumentData | async"
            [columns]="recentDocumentCols"
            [sortColumns]="recentDocumentSortCols"
            [canOpenFile]="true"
            [type]="'recent-documents'"
            (modalCloses)="recentDocumentModal($event)"
            [nameFunction]="openPdf"
            style="flex-grow: 1"
            [customizeMatCellClass]="'customize-mat-cell'"
          ></app-table>
          <app-table
            *ngIf="userType === 'admin'"
            [title]="recentUsersTitle"
            [data]="recentUsersData"
            [columns]="recentUsersCols"
            style="flex-grow: 1"
            [customizeMatCellClass]="'customize-mat-cell'"
          ></app-table>
        </div>

        <app-table
          *ngIf="!isTabletWidth && !isPhoneWidth && userType === 'user'"
          [title]="'Recent Folders'"
          [data]="recentFolders"
          [columns]="recentFoldersCols"
          [rowFunction]="openFolder"
          (modalCloses)="recentDocumentModal($event)"
          style="flex-grow: 1"
        ></app-table>
      </div>
      
    </div>
    <div class="bottom-row" *ngIf="!isTabletWidth && !isPhoneWidth"> 
      <!-- <app-dashboard-banner></app-dashboard-banner> -->
    </div>
  </div>
</ng-container>

<ng-template #signsecure_document_row let-data>
  <div class="recent-document-row" (click)="openSignSecureDoc('', data)">
    <div class="left-content">
      <div class="recent-document__content">
        <p class="title">{{ data.name }}</p>
        <p class="subtitle">{{ data.status }}</p>
        <p class="content">
          {{
            data.updatedAtFormatted || data.updatedAt | date : "MMM d, y 'at' h:mm a"
          }}
        </p>
      </div>
    </div>

    <div class="recent-document__img" >
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>

<ng-template #recent_document_row let-data>
  <div class="recent-document-row" (click)="openPdf(data)">
    <div class="left-content">

      <div class="recent-document__content">
        <p class="title">{{ data.name }}</p>
        <mat-chip-grid class="list-tags">
          <mat-chip
            class="chip-tag"
            *ngFor="let tag of data.tags; let i = index"
            >{{ tag.name }}
          </mat-chip>
        </mat-chip-grid>
        <!-- <pre class="subtitle">{{ data | json }} </pre> -->
        <p class="content">
          {{
            data.date | date : "MMM d, y 'at' h:mm a"
          }}
        </p>
      </div>
    </div>

    <div class="recent-document__img" >
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>

<ng-template #recent_folder_row let-data>
  <div class="recent-document-row" (click)="openFolder('', data)">
    <div class="left-content">

      <div class="recent-document__content">
        <p class="title">{{ data.name }}</p>
        <mat-chip-grid class="list-tags">
          <mat-chip
            class="chip-tag"
            *ngFor="let tag of data.tags; let i = index"
            >{{ tag.name }}
          </mat-chip>
        </mat-chip-grid>
        <!-- <pre class="subtitle">{{ data | json }} </pre> -->
        <p class="content">
          {{
            data.date | date : "MMM d, y 'at' h:mm a"
          }}
        </p>
      </div>
    </div>

    <div class="recent-document__img" >
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>

<ng-template #recent_user_row let-data>
  <div class="recent-document-row">
    <div class="left-content">
      <div class="recent-document__content">
        <p class="title">{{ data.name }}</p>
        <p class="subtitle1">{{ data.email }}</p>
        <p class="content">
          <a>Added on: </a
          >
          {{ data.date_added | date : "MMM d, y 'at' h:mm a" }} 
        </p>
      </div>
    </div>
    <div class="recent-document__img" >
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>