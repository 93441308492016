import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { catchError, of, switchMap } from 'rxjs';
import { PwordRegex } from 'src/app/@shared/constants/regex.const';
import { UserUpdateModel } from 'src/app/@shared/models/user-update.model';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass'],
})
export class AccountComponent implements OnInit {
  hideOldPw: boolean = true;
  hideNewPw: boolean = true;
  hideConfirm: boolean = true;
  phone: any;
  isFormaCommercial = environment.flag.commercial;
  editAccountForm: FormGroup = this.fb.group({
    phoneNumber: ['', [Validators.required]],
  });
  fullName: string | undefined;
  givenName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  profilePicture: any;
  pictureSrc: any = '../../../../assets/images/forma/Avatar (silhouette).png';
  email: string | undefined;
  errorMsg: string = '';
  errorStatus: number = 0;
  isPwordValid: boolean = true;

  gridTileColumn1: number = 1;
  gridTileColumn2: number = 2;
  gridTileColumn3: number = 1;

  constructor(
    private _navigationService: NavigationService,
    private _authService: AuthenticationService,
    private _userService: UsersService,
    private fb: FormBuilder,
    private sb: MatSnackBar,
    public dialog: MatDialog,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._userService.currentUser$.subscribe(i => {
      this.givenName = i.givenName;
      this.lastName = i.lastName;
      this.email = i.email;
      this.editAccountForm.controls['phoneNumber'].setValue(i.phoneNumber);
      this.pictureSrc =
        i.displayPhoto ||
        '../../../../assets/images/forma/Avatar (silhouette).png';
      this._userService.updatePhoto(this.pictureSrc);
    });
    this.fullName = this._authService.name
    this.email = this._authService.email

    if(screen.width <= 820) {
      this.gridTileColumn1 = 4;
      this.gridTileColumn2 = 4;
      this.gridTileColumn3 = 4;
    }
  }

  // tiles: Tile[] = [
  //   {text: 'One', cols: 3, rows: 1, color: 'lightblue'},
  //   {text: 'Two', cols: 1, rows: 2, color: 'lightgreen'},
  //   {text: 'Three', cols: 1, rows: 1, color: 'lightpink'},
  //   {text: 'Four', cols: 2, rows: 1, color: '#DDBDF1'},
  // ];

  updateProfile() {
    let userFormData = new FormData();
    const user: UserUpdateModel = {
      id: this._authService.userId,
    };
    if (this.givenName) {
      user.givenName = this.givenName;
    }
    if (this.lastName) {
      user.lastName = this.lastName;
    }
    if (this.email) {
      user.email = this.email;
    }
    if (this.editAccountForm.controls['phoneNumber'].valid) {
      user.phoneNumber = this.editAccountForm.controls['phoneNumber'].value;
    }
    if (this.profilePicture) {
      user.file = this.profilePicture as File;
    }
    console.log(user);
    this._userService.udpateUser(user).subscribe(i => {
      this.sb.openFromComponent(SnackbarComponent, {
        duration: 1000,
        data: { type: 'UserUpdated' },
      });
    });
  }
  updateDisplayPhoto() {
    const userFormData = new FormData();
    if (this.profilePicture) {
      userFormData.append('file', this.profilePicture as File);
    }
    userFormData.append('id', this._authService.userId);
    this._userService
      .updateDisplayPicture(userFormData, this._authService.userId)
      .pipe(
        switchMap(() => {
          this.sb.openFromComponent(SnackbarComponent, {
            duration: 1000,
            data: { type: 'UserUpdated' },
          });
          this._userService.updatePhoto(this.pictureSrc);
          return this._userService.getCurrentUserDetails();
        }),
        catchError((err,caught)=>{
          return of();
        })
      )
      .subscribe(i => {
        this._userService.setCurrentUser(i.data);
      });
  }
  checkPword() {
    this.isPwordValid = this.newPassword.match(PwordRegex) ? true : false;
  }

  updatePassword() {
    const user: UserUpdateModel = {
      id: this._authService.userId,
    };

    if (this.oldPassword) {
      user.oldPassword = this.oldPassword;
    }

    if (this.newPassword) {
      user.newPassword = this.newPassword;
    }

    if (this.confirmPassword) {
      user.confirmNewPassword = this.confirmPassword;
    }

    if (this.confirmPassword == this.newPassword && this.isPwordValid) {
      this._authService
        .updateUserPassword(user)
        .pipe(
          catchError(err => {
            this.errorStatus = err.status;

            this.errorMsg = err.error.error;
            this.errorMsg = this.errorMsg.slice(15);

            return of();
          })
        )
        .subscribe(i => {
          this.sb.openFromComponent(SnackbarComponent, {
            duration: 1000,
            data: { type: 'UserUpdated' },
          });
        });
    } else {
      this.errorStatus = 1;
      this.errorMsg = 'Passwords does not match';
    }
  }

  cancelSub() {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '460px',
      data: { action: 'cancel-subs' },
    });

    dialogRef?.afterClosed()?.subscribe((email: string) => {});
  }
  addSubscription() {
    this._router.navigateByUrl('account/subscription');
  }

  upload() {
    document.getElementById('fileUpload')?.click();
  }

  onFileSelected(data: any) {
    const regex = /.*\.(jpg|JPG|png|PNG)$/g;
    let files = [];
    if (data.path) {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else if (data.target) {
      files = Array.from(data.target.files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    }
    let src;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.pictureSrc = e.target.result;
    };
    reader.readAsDataURL(files[0] as Blob);

    this.profilePicture = files[0];
  }
}
