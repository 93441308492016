<div class="party">
	<h3 class="document-header">
		Parties {{ index + 1 }}
		<!-- <img
		src="../../../../../assets//images/template-icons/check-box.svg"
		alt=""
		(click)="goBack(3)"
		/> -->
	</h3>
	<div class="party-column">
		<div class="party-details">
			<!-- <p class="party__number">{{ index + 1 }}</p> -->
			<p>Full Name</p>
			<p class="party__name">{{ party.name }}</p>
		</div>
		<div class="party-details">
			<p>Email</p>
			<p class="party__email">{{ party.email }}</p>
		</div>
	</div>
	<div class="party-column">
		<div class="party-details">
			<p>Designation (optional)</p>
			<p class="party__position">{{ party.designation ?? " " }}</p>
		</div>
		<div class="party-details">
			<p>Role</p>
			<p class="party__role">{{ role() }}</p>
		</div>
	</div>
</div>
