<div class="user-groups margin-container" *ngIf="!showAddUser">
  <app-table
    [title]="''"
    [addButton]="'Add New User'"
    [columns]="userCols"
    [data]="users | async"
    (modal)="showModal($event)"
    [type]="'mUser'"
  >
  </app-table>
</div>
<app-add-new-user *ngIf="showAddUser"></app-add-new-user>
