import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qr-verification-success',
  templateUrl: './qr-verification-success.component.html',
  styleUrls: ['./qr-verification-success.component.sass']
})
export class QrVerificationSuccessComponent implements OnInit {

  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()

  img: string = "../../../../assets/images/success-verified.svg"

  buttonText: string = "Please login to view the document"
  constructor() { }

  ngOnInit(): void {
    console.log({ data: this.data })
    if (this.data?.data?.workflow?.privacyType === 'PUBLIC') {
      this.buttonText = 'View document'
    }
  }

  close(result: boolean) {
    this.closeModalEvent.emit(result)
  }

}
