import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { delay, lastValueFrom } from 'rxjs';
import { FilesService } from 'src/app/@shared/services/files.service';
import { QrService } from '../../services/qr.service';


@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.sass']
})
export class QrScannerComponent implements OnInit, AfterViewInit, OnDestroy {


  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()

  @ViewChild('action')
  scanner!: NgxScannerQrcodeComponent
  isRearCam:boolean = true;

  isMobile:boolean = false;

  public frontCam: ScannerQRCodeConfig = {
    // fps: 1000,
    vibrate: 400,
    isBeep: false,
    // decode: 'macintosh',
    //deviceActive: 0, // camera front: deviceActive=0  // back camera: deviceActive=1
    constraints: {
      //facingMode: "user", // 'user' (front camera), and 'environment' (back camera). 
      audio: false,
      video: {
        width: window.innerWidth // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
      }
    }
  };
  public rearCam: ScannerQRCodeConfig = {
    // fps: 1000,
    vibrate: 400,
    isBeep: false,
    // decode: 'macintosh',
    //deviceActive: 1, // camera front: deviceActive=0  // back camera: deviceActive=1
    constraints: {
      //facingMode: "environment", // 'user' (front camera), and 'environment' (back camera). 
      audio: false,
      video: {
        width: window.innerWidth // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
      }
    }
  };

  constructor(private _qrService: QrService, private _files: FilesService) { }
  ngOnDestroy(): void {
    if (this.scanner.isStart) {
      this.scanner.stop()
    }
  }

  ngAfterViewInit(): void {

    this.scanner.isReady.pipe(delay(1)).subscribe(() => {
      if(screen.width >= 280 &&screen.width <=430){
        this.isMobile=true;
      }
      if (!this.scanner.isStart) {
        this.scanner.start();
      }
    });
  }

  ngOnInit(): void {
    if(screen.width >= 280 &&screen.width <=430){
      this.isMobile=true;
    }
  }

  async reading(result: ScannerQRCodeResult[] | any) {
    if (result?.length < 1 || result?.data === null) return;

    this.scanner.pause()
    console.log({ id: result[0].value })
    const fileId = this._qrService.decrypt(result[0].value)
    try {
      console.log({ fileId })
      const verificationResult = await lastValueFrom(this._files.verifyFile(fileId))
      const data = verificationResult.data
      this.closeModalEvent.emit(data)
    } catch (e) {
      console.log({ e })
      this.closeModalEvent.emit('fails')
    }
  }

  switchCam(){
    this.scanner.stop();
    this.isRearCam = !this.isRearCam;
    this.scanner.start();
  }

  close() {
    this.closeModalEvent.emit(null)
  }
}
