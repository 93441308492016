import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignSecureService } from '../../services/sign-secure.service';

@Component({
  selector: 'app-cancel-document',
  templateUrl: './cancel-document.component.html',
  styleUrls: ['./cancel-document.component.sass'],
})
export class CancelDocumentComponent implements OnInit {
  @Input()
  data: any;

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter();

  constructor(private _signSecure: SignSecureService) {}

  ngOnInit(): void {
  }

  approve() {
    this._signSecure.documentCancel(this.data.data).subscribe((data) => {
      this.closeModalEvent.emit({ action: 'document-cancelled' });
    });
  }

  decline() {
    this.closeModalEvent.emit(false);
  }

  close(action: string) {
    this.closeModalEvent.emit({ action });
  }
}
