<div class="template-container">
  <div class="table-container margin-container">
    <app-table
      [title]="'Templates'"
      [data]="templates | async"
      [columns]="templateColumns"
      (actionClicked)="tableActionClicked('templates', $event)"
    >
      <div class="header-container">
        <app-template-card
          *ngFor="let card of cards"
          [id]="card.id"
          [name]="card.name"
          [number]="card.number"
          [selected]="selected === card.id"
          (clicked)="cardSelected($event)"
        ></app-template-card>
      </div>
    </app-table>
  </div>

  <app-template-banner></app-template-banner>
</div>
