import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ModalData } from 'src/app/@shared/models/Modal.model';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.sass'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
  ],
})
export class AdvancedSearchComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  @Input()
  data: ModalData = {
    type: '',
    action: '',
    data: '',
  };
  constructor() {}

  ngOnInit(): void {}

  closeModal() {
    this.closeModalEvent.emit();
  }
}
