<div class="cancel-container">
  <div class="cancel">
    <img alt="" [style.mask-image]="'url(../../../assets/images/torn-page.svg)'"/>
    <div class="text">
      <h3 class="title">
        This document has been {{ expired ? "expired" : "voided" }}
      </h3>
    </div>
    <div class="buttons">
      <button class="return" (click)="return()">Back to dashboard</button>
    </div>
  </div>
</div>
