import { SignaturePadComponent, NgSignaturePadOptions } from '@almothafar/angular-signature-pad';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { firstValueFrom, of } from 'rxjs';
import { FilesService } from 'src/app/@shared/services/files.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignatureService } from 'src/app/@shared/services/signature.service';
import { environment } from 'src/environments/environment';
import { SignSecureService } from '../../services/sign-secure.service';

@Component({
  selector: 'app-create-initials',
  templateUrl: './create-initials.component.html',
  styleUrls: ['./create-initials.component.sass']
})
export class CreateInitialsComponent implements OnInit {

  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()

  @ViewChild('signature')
  public signaturePad!: SignaturePadComponent;

  public signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 462,
    canvasHeight: 134,
  };

  public signaturePhonePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 340,
    canvasHeight: 300,
  };

  signatureData: any = null;

  resetUpload: boolean = false
  status: string = ''

  signatureList: any[] = []

  selectedSignature: any = null

  config = of({
    width: 462,
    height: 134
  })

  typedSignature: string = ''
  selectedFont: string | undefined;
  inputSignature: string = '';

  fonts: string[] = ['Lora', 'PlayfairDisplay', 'Redressed', 'Yellowtail'];

  @ViewChild('signaturePreviewElement') signaturePreviewElement!: ElementRef;

  @ViewChild('signatureCanvas') canvasRef!: ElementRef;
  constructor(
    private _signatures: SignatureService,
    private _nav: NavigationService,
    private _signSecure: SignSecureService,
    private _fileService: FilesService,
    private _domSanitizer: DomSanitizer,
    private _detech: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._signatures.getSignatures().subscribe((data: any) => {
      console.table(data.data)
      this.signatureList = data?.data ?? []
    })
  }

  ngAfterViewInit() {
  }

  drawComplete(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onEnd event
    console.log('Completed drawing', event);

    this.signatureData = this.signaturePad.toDataURL()
    console.log({ signature: this.signatureData })
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('Start drawing', event);
  }

  close() {
    if (this.signatureData) {
      this.closeModalEvent.emit(this.signatureData)
    } else {
      this.closeModalEvent.emit(null)
    }

  }


  doneUpload(event: any) {
    console.log('doneUpload', event)
    if (!environment.local) {

      this.signatureData = `./assets/${event.data.path}`;
    } else {
      this.signatureData = `../../../assets/images/signature.svg`;
    }

    this.close()
  }

  uploadStatus(event: any) {
    this.status = event
    if (event === 'start') {
      this.resetUpload = false
    }
  }

  reset() {
    if (this.status !== 'status')
      this.resetUpload = true
  }
  
  async selectSignature(signature: any) {
    this._signSecure.signatureId = signature.id;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.signatureData = e.target.result;
    };
    reader.readAsDataURL(
      await firstValueFrom(this._signSecure.getSignaturesProxy(signature.id))
    );
  }

  clearSelected() {
    this.signatureData = null
  }

  clearDrawing() {
    this.signaturePad.clear()
    this.clearSelected()
  }

  clearSelectedFont() {
    this.selectedFont = undefined;
  }

  selectFont(font: string) {
    this.selectedFont = font;
    this.drawSignature();
  }

  drawSignature() {
    this._detech.detectChanges();

    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      console.error('Canvas context is not available.');
      return;
    }

    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    const fontSize = 45;
    ctx.font = `${fontSize}px ${this.selectedFont}`;
    ctx.fillStyle = 'black';

    const text = this.inputSignature;

    const textMetrics = ctx.measureText(text);
    const textWidth = textMetrics.width;
    const textHeight =
      textMetrics.actualBoundingBoxAscent +
      textMetrics.actualBoundingBoxDescent;

    const textX = (canvasWidth - textWidth) / 2;
    const textY = (canvasHeight - textHeight) / 2 + 20;

    ctx.fillText(text, textX, textY);
  }

  signWithFont() {
    this.drawSignature();
    this.setSignatureFontDataURL();
    this._signSecure.signatureId = this.generateSignatureId();
  }

  setSignatureFontDataURL() {
    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;
    const dataURL = canvas.toDataURL();
    this.signatureData = dataURL;

    var link = document.createElement('a');
    link.href = dataURL;

    this.close();
  }

  generateSignatureId() {
    var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return (
      timestamp +
      'xxxxxxxxxxxxxxxx'
        .replace(/[x]/g, function () {
          return ((Math.random() * 16) | 0).toString(16);
        })
        .toLowerCase()
    );
  }
}
