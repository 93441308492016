import { Component, OnInit } from '@angular/core';
import { SignSecureService } from '../services/sign-secure.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrls: ['./create-document.component.sass'],
})
export class CreateDocumentComponent implements OnInit {
  documentProgress = this.signSecureService.signSecurePage$;
  options = this.signSecureService.signatureOptions;

  data = this.signSecureService.worflowData$

  signatureData: any

  pdfDetails: any

  addedAll: any = false

  qr: string = 'bottom-right'
  constructor(private signSecureService: SignSecureService, private _files: FilesService) { }

  async ngOnInit() {
    this.signSecureService.clearWorkFlowData();
    const data = await this.signSecureService.getSavedWorkflowData()
    if (!!data) {

      try {
        if (typeof data.fileData === 'object') {
          data.fileData = Uint8Array.from(Object.values(data.fileData)).buffer
        } else {
          if (data.fileData) {
            data.fileData = await lastValueFrom(this._files.getProxyUrl(data.fileData))
            data.fileData = await data.fileData.arrayBuffer()
          }
        }
      } catch {

      }

      data.file = this.signSecureService.bytesToFile(data.fileData, data.name)
      this.signSecureService.setWorkFlowData(data)
      this.signSecureService.clearSavedData()
      this.signSecureService.nextProgress(2)
    }
  }

  review(value: boolean) {
    this.signSecureService.setSignatures(this.signatureData ?? [], this.qr)
    this.signSecureService.nextProgress(5)
  }

  setSignatureData(data: any) {
    this.signatureData = data
    this.signSecureService.setSignatures(data, this.qr)
  }

  setPdfDetails(data: any) {
    this.pdfDetails = data
  }

  addedAllSignatories(status: any) {
    this.addedAll = status
  }

  updateQRPosition(qrposition: string) {
    this.qr = qrposition
  }
}
