import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class AdvanceTaggingService {
  advancedTaggingProgress = new BehaviorSubject<number>(4);
  $advancedTaggingProgress = this.advancedTaggingProgress.asObservable();

  setATProgress(value: number) {
    this.advancedTaggingProgress.next(value);
  }
}
