<div class="signature_title">
	<div class="header">
		<h3 class="title">Add your initial</h3>
		<button class="close" (click)="close()">
			<img src="../../../../assets/images/close.svg" alt="" srcset="" />
		</button>
	</div>

	<mat-tab-group animationDuration="0ms" (selectedTabChange)="clearSelected()">
		<mat-tab>
			<ng-template mat-tab-label>
				<span>Draw</span>
			</ng-template>
			<div class="signature-pad-header">
				<p class="subtitle">Draw your initial below</p>
				<div class="reset" (click)="clearDrawing()">
					<img src="../../../../assets/images/reset.svg" alt="" srcset="" />
					Reset
				</div>
			</div>
			<div class="draw-pad">
				<signature-pad
					#signature
					[options]="signaturePadOptions"
					(drawStart)="drawStart($event)"
					(drawEnd)="drawComplete($event)"
				></signature-pad>
				<button
					class="sign {{ signatureData !== null ? 'active' : '' }}"
					(click)="close()"
				>
					Sign
				</button>
			</div>
			<div class="draw-pad-phone">
				<signature-pad
					#signature
					[options]="signaturePhonePadOptions"
					(drawStart)="drawStart($event)"
					(drawEnd)="drawComplete($event)"
				></signature-pad>
				<div class="align-button">
					<button
						class="sign {{ signatureData !== null ? 'active' : '' }}"
						(click)="close()"
					>
						Sign
					</button>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="Upload">
			<ng-template mat-tab-label>
				<span>Upload</span>
			</ng-template>
			<div class="signature-2-pad-header">
				<div class="text">
					<p class="subtitle">Upload your signature below</p>
					<p class="description">
						File should be GIF, JPG, PNG, or BMP. Max file size is 200KB.
					</p>
				</div>
				<div
					class="reset {{ status === 'start' ? 'hide' : '' }}"
					(click)="(reset)"
				>
					<img
						src="../../../../assets/images/icons/delete.svg"
						alt=""
						srcset=""
					/>
					Delete
				</div>
			</div>
			<app-add-signature-component
				(done)="doneUpload($event)"
				(status)="uploadStatus($event)"
				[reset]="resetUpload"
			></app-add-signature-component>
		</mat-tab>
		<mat-tab label="Choose">
			<ng-template mat-tab-label>
				<span>Choose</span>
			</ng-template>
			<div class="signature-pad-header">
				<p class="subtitle">Select a saved signature</p>
				<div class="reset">
					<img src="../../../../assets/images/reset.svg" alt="" srcset="" />
					Reset
				</div>
			</div>
			<div class="no-overflow">
				<div class="signatures" *ngIf="!signatureData">
					<div
						class="signature"
						*ngFor="let signature of signatureList"
						(click)="selectSignature(signature)"
					>
						<img [src]="signature.path" />
						<span>select</span>
					</div>
				</div>
				<div class="selectedSignature" *ngIf="signatureData">
					<img [src]="signatureData" />
				</div>
				<div class="align-button">
					<button
						class="sign {{ signatureData ? 'active' : '' }}"
						(click)="close()"
					>
						Sign
					</button>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="Type">
			<ng-template mat-tab-label>
				<span>Type</span>
			  </ng-template>
		
			  <div class="type-container">
				<!-- <ko-stage [config]="config"></ko-stage> -->
		
				<mat-card>
				  <div class="card-content">
					<div>
					  <span class="card-text">Type your signature below</span>
					</div>
					<div class="right-text" (click)="clearSelectedFont()">
					  <span class="right-text-content">
						<img
						  src="../../../../assets/images/change-style.svg"
						  alt=""
						  srcset="" />
						Change style
					  </span>
					</div>
				  </div>
				</mat-card>
		
				<mat-form-field class="type-form-field">
				  <input
					matInput
					type="text"
					class="type-full-width"
					[(ngModel)]="inputSignature"
					name="inputSignature" />
				</mat-form-field>
		
				<mat-list *ngIf="!selectedFont && inputSignature" class="list">
				  <mat-list-item
					*ngFor="let font of fonts; let even = even"
					class="list-item"
					[ngClass]="{ 'gray-background': even }">
					<span class="font-name" [style.fontFamily]="font">{{
					  inputSignature
					}}</span>
					<button mat-button class="right-button" (click)="selectFont(font)">
					  Select
					</button>
				  </mat-list-item>
				</mat-list>
		
				<canvas
				  style="display: none"
				  *ngIf="selectedFont && inputSignature"
				  #signatureCanvas
				  width="462"
				  height="134"></canvas>
		
				<div
				  *ngIf="selectedFont && inputSignature"
				  #signaturePreviewElement
				  id="signaturePreviewElement"
				  class="preview-card">
				  <span class="font-name" [style.fontFamily]="selectedFont">{{
					inputSignature
				  }}</span>
				</div>
		
				<div class="align-button">
				  <button
					class="sign {{ selectedFont ? 'active' : '' }}"
					(click)="signWithFont()">
					Sign
				  </button>
				</div>
			  </div>
		</mat-tab>
	</mat-tab-group>
</div>
