<div
  class="template-card {{ selected ? 'active' : '' }}"
  (click)="clicked.emit(id)"
>
  <img src="../../../assets/images/forma/folder.svg" alt="" srcset="" />
  <div class="text-content">
    <h2>{{ name }}</h2>
    <p>{{ number }} templates</p>
  </div>
</div>
