import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-signing-page-cancel',
  templateUrl: './signing-page-cancel.component.html',
  styleUrls: ['./signing-page-cancel.component.sass'],
})
export class SigningPageCancelComponent implements OnInit {

  @Input()
  expired: boolean = false;
  
  constructor(private _nav: NavigationService, private _router: Router) {}

  ngOnInit(): void {}

  return() {
    this._router.navigateByUrl('/dashboard');
  }
}
