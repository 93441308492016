import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { FilesService } from 'src/app/@shared/services/files.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recent-doc-info',
  templateUrl: './recent-doc-info.component.html',
  styleUrls: ['./recent-doc-info.component.sass'],
})
export class RecentDocInfoComponent implements OnInit {
  @Input() data: ModalData = <ModalData>{};
  @Output() closeModalEvent = new EventEmitter<any>();

  pdfSrc: any;
  datum: any;
  userGroups: any[] = [];
  users: any[] = [];
  constructor(
    private _usergroupsService: UserGroupService,
    private _loaderService: LoaderService,
    private _userService: UsersService,
    public dialog: MatDialog,
    private _fileService: FilesService
  ) {
    this._loaderService.show();
  }

  ngOnInit(): void {
    const { data } = this.data;
    this.datum = data;
    const obs = forkJoin([
      this._userService.getUserInfo(this.datum.createdBy),
      this._userService.getUserInfo(this.datum.openedBy),
      this._userService.getUserInfo(this.datum.updatedBy),
    ]).subscribe(([createdBy, openedBy, updatedBy]) => {
      this.datum.owner = `${createdBy.data.givenName} ${createdBy.data.lastName}`;
      this.datum.openedByName = `${openedBy.data.givenName} ${openedBy.data.lastName}`;
      this.datum.updatedByName = `${updatedBy.data.givenName} ${updatedBy.data.lastName}`;
    });

    this._usergroupsService.userGroups$.subscribe(data => {
      this.userGroups = this.datum.userGroups.map((d: any) => d.name);
      //this.userGroups = this.datum.shareToTeams.map((d: any) => d.name);
      this.users = this.datum.users;
      //this.users = this.datum.shareToUsers;

      this._loaderService.hide();
    });

    this._fileService.getProxyUrl(this.datum.id).subscribe(response => {
      if (this.datum.fileExtension == 'pdf') {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          this.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
        };
        fileReader.readAsArrayBuffer(response);
      } else {
        this._fileService.blobToBase64(response).then(image => {
          this.pdfSrc = image;
        });
      }
    });
  }

  openSharingPermission() {
    const emitData = {
      action: 'share-permission',
      data: {
        title: this.datum.name,
        fileId: this.datum.id,
        type: this.datum.type,
        data: this.datum,
        dialog: this.dialog,
        fileExtension: this.datum.fileExtension
      },
    };
    this.closeModalEvent.emit(emitData);
  }

  close() {
    this.closeModalEvent.emit();
  }
}
