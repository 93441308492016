<mat-card class="container">
  <img *ngIf="_navigation.lastKnownLocation !== '/maintainance'" class="desktop img-logo" src="../../../assets/images/forma/forma-login-logo.svg" alt="" />
  <p class="transform">Transform Paper</p>
  <p class="transform">Records Into</p>
  <p class="transform">Digital Assets</p>
  <img *ngIf="_navigation.lastKnownLocation !== '/maintainance'" class="tablet" src="../../../assets/images/side-logo.svg" alt="" />
  <img *ngIf="_navigation.lastKnownLocation === '/maintainance' && (!isTabletWidth && !isPhoneWidth)" class="img-maintainance" src="../../../assets/images/maintainance.svg" alt="" />
  <img *ngIf="_navigation.lastKnownLocation === '/maintainance' && (isTabletWidth || isPhoneWidth)" class="img-maintainance-mobile" src="../../../assets/images/maintainance-mobile.svg" alt="" />
  <div class="login-footer">
    <div class="product-text">
       A PRODUCT OF <img class="login-footer-logo" src="../../../assets/images/forma/unawa-logo-footer.png" alt="" />
    </div>
    <p class="copyright">
      © 2024 <a class="emphasize" href="https://www.unawa.asia/" target="_blank"></a>
      . All rights reserved.
      <a class="emphasize" href="https://signsecureapp.unawa.asia/terms-and-conditions" target="_blank">
        Terms & Conditions
      </a> | <a class="emphasize" href="https://signsecureapp.unawa.asia/privacy-policy" target="_blank">
        Privacy Policy
      </a>
    </p>
  </div>
</mat-card>
