import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AdvanceTaggingService } from '../service/advance-tagging.service';

@Component({
  selector: 'advanced-tagging-add-case',
  templateUrl: './add-case.component.html',
  styleUrls: ['./add-case.component.sass'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule],
})
export class AdvanceTaggingAddCaseComponent implements OnInit {
  private readonly aTaggingService = inject(AdvanceTaggingService);
  constructor() {}

  ngOnInit(): void {}
  nextStep() {
    this.aTaggingService.setATProgress(3);
  }
}
