import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuditRequestModel } from '../models/audit-request.model';
import { AuditLogService } from '../services/audit-log.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.sass'],
})
export class FilterComponent implements OnInit, OnDestroy {
  filterForm: FormGroup = this.fb.group({
    fromDate: ['', [Validators.required]],
    toDate: ['', [Validators.required]],
  });
  minDate:Date = new Date();
  email: string = '';
  objectType: string = '';
  action: string = '';
  constructor(
    private _auditService: AuditLogService,
    private fb: FormBuilder,
    private dp: DatePipe
  ) {}

  ngOnInit(): void {
    this.getData();
    this.filterForm.valueChanges.subscribe(i=>{
      this.minDate = this.filterForm.controls['fromDate'].value;
    });
  }
  ngOnDestroy(): void {
    const filter: AuditRequestModel = {
      email: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      actionType: undefined,
      action: undefined,
    };
    this._auditService.setFilter(filter);
    this.getData().unsubscribe();
  }
  submit() {
    const formattedFromDateFilter = this.dp.transform(
      this.filterForm.controls['fromDate'].value,
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    );
    const formattedToDateFilter = this.dp.transform(
      this.filterForm.controls['toDate'].value,
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    );
    const filter: AuditRequestModel = {
      email: this.email || undefined,
      dateTo: formattedToDateFilter || undefined,
      dateFrom:  formattedFromDateFilter|| undefined,
      actionType: this.objectType || undefined,
      action: this.action || undefined,
    };
    

    this._auditService.setFilter(filter);
    this.getData();
  }
  getData() {
    return this._auditService.getAuditLog().subscribe((data) => {
      this._auditService.setResult(
        this._auditService.formatAuditLogResult(data.data)
      );
    });
  }
}
