import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-audit-trail-detail',
  templateUrl: './audit-trail-detail.component.html',
  styleUrls: ['./audit-trail-detail.component.sass']
})
export class AuditTrailDetailComponent implements OnInit {


  @Input()
  data: any

  time: string = ''
  event: string = ''
  constructor() { }

  ngOnInit(): void {
    this.time = moment(this.data.timestamp).format("DD/MM/YYYY hh:mm")
    if (this.data?.details?.toLowerCase() === 'manifest generated') {
      this.event = this.data?.details
    } else {
      this.event = `${this?.data?.author?.name ?? 'John Doe'} ${this.data?.details?.toLowerCase() ?? 'unknown'} the document`
    }


  }

  verb() {

  }

}
