<div class="details-container">
  <div class="top">
    <h1>Add document details</h1>
    <a>Tell us more about the document to be signed.</a>
  </div>

  <div class="body">
    <div class="form-group">
      <div class="form-field">
        <mat-label>Document Title</mat-label>
        <mat-form-field floatLabel="auto" appearance="fill">
          <input type="text" matInput [(ngModel)]="name" />
        </mat-form-field>
      </div>
      <div class="form-field margin-top-20">
        <mat-label>Document Description</mat-label>
        <mat-form-field floatLabel="auto" appearance="fill">
          <textarea name="" id="" cols="30" rows="5" [(ngModel)]="description"></textarea>
          <!-- <input type="textarea" matInput [(ngModel)]="description" placeholder="Enter document description"/> -->
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="bottom">
    <div class="button-grp">
      <button class="back" (click)="back()">Back</button>
      <button class="next" (click)="next()">Next</button>
    </div>
  </div>
</div>
