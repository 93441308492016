<div class="container-review">
  <app-spinner></app-spinner>
  <div class="title-contianer">
    <h3 class="review__title">Review & send</h3>
    <h5 class="review__subtitle">Please review if all details are correct</h5>
  </div>

  <div class="review-document-privacy">
    <div>
      <h3 class="document-header">
        Document privacy
        <!-- <img
          src="../../../../../assets//images/template-icons/question-mark.svg"
          alt=""
          matTooltip="Confidential - can be viewed by parties involved only
          Restricted - can be viewed by all signed in users
          Public - can be viewed by the general public"
        /> -->
      </h3>
    </div>
    <div class="document-details__privacy">
      <mat-radio-group
        aria-labelledby="privacy-options"
        class="privacy-options"
        [(ngModel)]="privacy"
      >
        <mat-radio-button class="privacy-option" [value]="'CONFIDENTIAL'" selected>
          Confidential
        </mat-radio-button>
        <mat-radio-button class="privacy-option" [value]="'RESTRICTED'">
          Restricted
        </mat-radio-button>
        <mat-radio-button class="privacy-option" [value]="'PUBLIC'">
          Public
        </mat-radio-button>
      </mat-radio-group>
      <div class="privacy-description">
        <p><b>Confidential</b> - can be viewed by parties involved only</p> 
        <p><b>Restricted</b> - can be viewed by all signed in users</p> 
        <p><b>Public</b> - can be viewed by the general public</p> 
      </div>
    </div>
  </div>

  <div class="review-content">
    <div class="review-details">
      <h3 class="document-header">
        Document details
        <!-- <img
          src="../../../../../assets//images/template-icons/check-box.svg"
          alt=""
          (click)="goBack(2)"
        /> -->
      </h3>
      <div class="document-column">
        <div class="document-details__content">
          <h5> Document Title:</h5>
          <p> {{ data.name }} (<span
              class="link"
              (click)="goBack(4)"
              >Preview</span>)
          </p>
        </div>
        <div class="document-details__content">
          <h5> Document Description:</h5>
          <p>{{ data.description }}</p>
        </div>
      </div>
    </div>

    <div class="review-details">
      <h3 class="document-header">Email</h3>
      <div class="document-column">
        <div class="document-details__content">
          <h5>Subject</h5>
          <p> {{ subject }} </p>
          <!-- <mat-form-field floatLabel="auto" appearance="fill">
            <input type="text" matInput [(ngModel)]="subject" />
          </mat-form-field> -->
        </div>
        <div class="document-details__content">
          <h5>Message (optional)</h5>
          <p> {{ message }} </p>
          <!-- <mat-form-field floatLabel="auto" appearance="fill">
            <textarea type="text" matInput [(ngModel)]="message"></textarea>
          </mat-form-field> -->
        </div>
      </div>
    </div>

    <!-- <div class="party-details">
      <h3 class="document-header">
        Parties
        <img
          src="../../../../../assets//images/template-icons/check-box.svg"
          alt=""
          (click)="goBack(3)"
        />
      </h3>
      <app-party-list [parties]="data?.workflow?.parties ?? []"></app-party-list>
    </div> -->
    <app-party-list [parties]="data?.workflow?.parties ?? []"></app-party-list>

    <div class="expiration-details">
      <div class="expiration-header">
        <h3>
          Expiration and reminder options
        </h3>
        <div class="automatic-reminders">
          <mat-checkbox
            #send
            [checked]="!!data?.workflow?.reminders"
            (change)="clearReminders($event)"
            >Send automatic reminders</mat-checkbox
          >
          <app-automatic-reminders *ngIf="send.checked"></app-automatic-reminders>
        </div>
      </div>

      <div class="expiration-colum">
        <mat-label class="expiry-title">Document expiry date</mat-label>
        <div class="form-pair">
            <mat-form-field class="date-picker">
              <input class="date-input" matInput [matDatepicker]="picker">
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-label>In Philippine Standard Time</mat-label>

            <mat-label class="reminder-perday">Send Reminder in X Days</mat-label>
            <mat-form-field floatLabel="auto" appearance="fill">
              <input
                class="input-number"
                type="number"
                matInput
                placeholder="0"
              />
            </mat-form-field>
            <mat-label class="reminder-everyday">Send Reminder every X Days</mat-label>
            <mat-form-field floatLabel="auto" appearance="fill">
              <input
                class="input-number"
                type="number"
                matInput
                placeholder="0"
              />
            </mat-form-field>
            <mat-label class="reminder-expiry">Send Reminder in X Days before Expiration</mat-label>
            <mat-form-field floatLabel="auto" appearance="fill">
              <input
                class="input-number"
                type="number"
                matInput
                placeholder="0"
              />
            </mat-form-field>
        </div>

        <!-- <div class="document-details__content">
         <mat-form-field floatLabel="auto" appearance="fill">
            <input type="text" matInput [(ngModel)]="subject" />
          </mat-form-field>
        </div>
        <div class="document-details__content">
          sssss
        </div> -->
      </div>
    </div>
  </div>

  <div class="buttons-container">
    <button class="back" (click)="goBack(4)">Back</button>
    <button class="next" (click)="done()">Send</button>
  </div>
</div>
