<div class="upload-container-signature">
  <div class="info-container" *ngIf="image === '' && !isOTPValid">
    <h2>No Signature Uploaded</h2>
    <p>Acceptable file formats: jpg or png</p>
    <p>Max file size: 200KB</p>
  </div>
  <ng-container *ngIf="image === '' && !isOTPValid ">
    <div
      class="drag-drop-container"
      appDragDropFileUpload
      (fileDropped)="dragover($event)"
    >
      <div class="container">
        <h4>Drag and Drop to upload</h4>
        <img src="../../../../assets/images/upload.svg" alt="" />
      </div>
      <div class="or-container">
        <p class="or">Or select from your computer</p>
        <div class="container-button">
          <button matRipple (click)="upload()">Add files</button>
        </div>
      </div>
    </div>
    

    <input
      type="file"
      id="fileUpload"
      name="fileUpload"
      style="display: none"
      (change)="onFileSelected($event)"
      accept=".jpg,.png"
    />
  </ng-container>
  <ng-container *ngIf="image !== '' && !isOTPValid">
    <div class="signature-container" *ngIf="otp === ''">
      <img [src]="image" alt="" class="signature" />
    </div>

    <ng-container *ngIf="otp === ''">
      <p class="use-this-sig">Use this signature?</p>
      <div class="buttons-container">
        <button matRipple class="no-button" (click)="chooseAnother()">
          No, select another image
        </button>
        <button matRipple class="yes-button" (click)="sendOTP()">Yes, send OTP</button>
      </div>
    </ng-container>
    <ng-container *ngIf="otp !== ''">
      <h2>Verification</h2>
      <p style="margin-top: 0;">Enter the 4-digit code that you received on your email.</p>
      <div class="otpContainer">
        <div class="otpInput">
          <input #otpInput type="text" maxlength="1" pattern=".{1}" (input)="moveFocus($event, 0)">
          <input #otpInput type="text" maxlength="1" pattern=".{1}" (input)="moveFocus($event, 1)">
          <input #otpInput type="text" maxlength="1" pattern=".{1}" (input)="moveFocus($event, 2)">
          <input #otpInput type="text" maxlength="1" pattern=".{1}" (input)="moveFocus($event, 3)">
        </div>
        <p>
          <ng-container *ngIf="time>0;else otpExpired" style="text-align: center;">{{formattedTime}}</ng-container>
        </p>
        <p *ngIf="otpError !== ''" class="error-text" style="text-align: center;">{{ otpError }}</p>
      </div>
      <div class="otp-input-container">
        <!-- <div class="input-container">
          <input
            type="text"
            class="otp"
            placeholder="Enter OTP"
            (change)="inputOtp($event)"
          />
        </div>

        <button
          [class]="otpValue === '' ? 'otp-button' : 'otp-button active'"
          (click)="verifyOtp()"
        >
          Verify OTP
        </button> -->
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isOTPValid">
    <div class="upload-success-container">
      <img src="../../../../assets/images/signature-checked.svg" alt="">
      <h2>Signature has been uploaded successfully.</h2>
      <button class="yes-button" (click)="navigateToDashboard()">Go to Dashboard</button>
    </div>
  </ng-container>
</div>

<ng-template #otpExpired><a style="color: red">OTP has expired</a></ng-template>