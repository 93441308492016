import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.sass']
})
export class TabsComponent implements OnInit {

  @Input()
  title: string = 'Duplicate';

  @Input()
  number: string = "14";

  @Input()
  location:string = '';

  @Output() filterWorkflowDataEvent = new EventEmitter<string>();

  constructor(private _navigationService:NavigationService) {}

  ngOnInit(): void {
  }

  navigateTo(loc:string){
    //if(loc=='documents'){
      //this._navigationService.setLocation(loc);
    //}else{
      this.filterWorkflowDataEvent.emit(loc);
    //}
    
  }

}
