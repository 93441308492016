import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { startWith, map } from 'rxjs';
import { UserGroupModel } from 'src/app/@shared/models/user-group.model';
import { FilesService } from 'src/app/@shared/services/files.service';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { UserGroupComponent } from '../user-group/user-group.component';

@Component({
  selector: 'app-add-user-group',
  templateUrl: './add-user-group.component.html',
  styleUrls: ['../user-group/user-group.component.sass'],
})
export class AddUserGroupComponent
  extends UserGroupComponent
  implements OnInit
{
  memberData: any;
  orginalGroupMembers: any[] = this.groupMembers;
  isCreateGroups: boolean = false;
  isGroupSuccess: boolean = false;

  constructor(
    private _fileService: FilesService,
    private _signSecureService: SignSecureService,
    private _userGroupService: UserGroupService,
    private _usersService: UsersService
  ) {
    super();
    this._usersService.getUsers();
  }

  addUserGroup() {
    const userGroup = this.getUserGroupData();
    this._userGroupService
      .createUserGroups(userGroup)
      .subscribe(async (result) => {
        this._userGroupService.getUserGroups().subscribe(({ data }) => {
          this._userGroupService.setUserGroup(
            this._userGroupService.format(data)
          );
        });
        this.closeModal();
      });
    
    this.isCreateGroups = true;
    this.isGroupSuccess = true;
    this._fileService.setCreateData(this.isCreateGroups, this.isGroupSuccess)
  }

  ngOnInit(): void {
    this._usersService.getUsers().subscribe((value) => {
      this.users = value.data;
    });
    this.userlist = this.userListControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this.users.filter((data) =>
          data.email.toLowerCase().includes(value || '')
        ); //this.filter(value as string);
      })
    );
  }

  back(){
    this._fileService.setCreateData(this.isCreateGroups, this.isGroupSuccess)
    console.log('back', this.isCreateGroups, this.isGroupSuccess)
  }
}
