<div class="navbar-container {{ done ? 'done' : '' }}">
  <div class="left">
    <button (click)="back()">
      <img
        src="../../../assets/images/template-icons/back-arrow.svg"
        alt=""
        class="back" />
      Back
    </button>
  </div>
  <div class="right">
    <button
      class="working {{
        numberDoneSignatures === numberSignatures ? 'enabled' : 'disabled'
      }}"
      (click)="generateFile()"
      *ngIf="
        !done &&
        role === 'SIGN' &&
        status !== 'COMPLETED' &&
        signingNow &&
        status !== 'CANCELLED' &&
        status !== 'EXPIRED' &&
        status !== 'DECLINED' &&
        status !== 'REJECTED'
      ">
      Affix signature
    </button>
    <button
      class="other"
      *ngIf="!done && status !== 'CANCELLED'"
      [cdkMenuTriggerFor]="menu">
      Other Actions
      <img src="../../../assets/images/template-icons/down-arrow.svg" alt="" />
    </button>
    <button
      class="working enabled white-text"
      (click)="showModal()"
      *ngIf="
        !done &&
        role === 'APPROVE' &&
        status !== 'COMPLETED' &&
        status !== 'EXPIRED' &&
        status !== 'DECLINED' &&
        status !== 'REJECTED' &&
        signingNow
      ">
      Approve/reject
    </button>
    <img
      src="../../../assets/images/template-icons/sign-logo.svg"
      alt=""
      class="icon"
      (click)="back()" />
  </div>
  <div *ngIf="status !== 'CANCELLED'" class="phone-right">
    <button class="other" *ngIf="!done" [cdkMenuTriggerFor]="menu">
      Other Actions
      <img src="../../../assets/images/template-icons/down-arrow.svg" alt="" />
    </button>
    <button
      class="working {{
        numberDoneSignatures === numberSignatures ? 'enabled' : 'disabled'
      }}"
      (click)="generateFile()"
      *ngIf="!done && role === 'SIGN' && status !== 'COMPLETED'">
      Affix signature
    </button>
    <button
      class="working enabled white-text"
      (click)="showModal()"
      *ngIf="role === 'APPROVE' && status !== 'COMPLETED' && signingNow">
      Approve/reject
    </button>
  </div>
</div>
<div
  class="nav__details"
  *ngIf="
    !done &&
    status !== 'COMPLETED' &&
    signingNow &&
    status !== 'CANCELLED' &&
    status !== 'EXPIRED' &&
    status !== 'DECLINED' &&
    status !== 'REJECTED'
  ">
  <p class="info">{{ message }}</p>
  <p class="status" *ngIf="role !== 'COPY'">
    {{ numberDoneSignatures }} of {{ numberSignatures }} required fields
    completed
  </p>
</div>
<div
  class="nav__details other_info"
  *ngIf="!done && !signingNow && status !== 'COMPLETED' && !cancel">
  <p class="info {{ signingNow ? '' : 'waiting' }}">{{ message }}</p>
  <p class="status">
    {{ numberDoneSignatures }} of {{ numberSignatures }} required fields
    completed
  </p>
</div>
<div
  class="nav__details completed_info"
  *ngIf="
    !(
      !done &&
      status !== 'COMPLETED' &&
      signingNow &&
      status !== 'CANCELLED' &&
      status !== 'EXPIRED' &&
      status !== 'DECLINED' &&
      status !== 'REJECTED'
    ) &&
    !(!signingNow && status !== 'COMPLETED' && !cancel) &&
    !cancel &&
    (status === 'COMPLETED' ||
      status === 'CANCELLED' ||
      status === 'INPROGRESS' ||
      status === 'EXPIRED' ||
      status === 'DECLINED' ||
      status === 'REJECTED')
  ">
  <p class="info doc-title" *ngIf="message !== ''">
    {{ message }}
  </p>
  <p class="info doc-title">{{ documentTitle }}</p>
</div>

<ng-template #menu>
  <div class="other-actions-menu" cdkMenu>
    <button
      class="other-actions-menu-item"
      cdkMenuItem
      (click)="doAction('audit-trail')">
      Audit Trail
    </button>
    <button
      class="other-actions-menu-item"
      cdkMenuItem
      (click)="doAction('document-details')">
      Document Details
    </button>
    <button
      class="other-actions-menu-item"
      cdkMenuItem
      *ngIf="
        role === 'SIGN' &&
        documentStatus !== 'COMPLETED' &&
        signingNow &&
        documentStatus !== 'EXPIRED' &&
        documentStatus !== 'REJECTED' &&
        documentStatus !== 'DECLINED'
      "
      (click)="showModal('decline-document', '512px')">
      Decline to Sign
    </button>
    <button
      class="other-actions-menu-item"
      cdkMenuItem
      *ngIf="
        createdByMe &&
        documentStatus !== 'COMPLETED' &&
        documentStatus !== 'EXPIRED' &&
        documentStatus !== 'REJECTED' &&
        documentStatus !== 'DECLINED'
      "
      (click)="showModal('cancel-document', '512px')">
      Cancel Document
    </button>
  </div>
</ng-template>
