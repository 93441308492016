import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'law-update-case',
  templateUrl: './update-case.component.html',
  styleUrls: ['./update-case.component.sass'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    NgClass,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
  ],
})
export class UpdateCaseComponent {}
