<ng-container>
    <form class="delete-document">
        <h4 class="title">
        Are you sure?
        </h4>
        <div class="content">
        <div class="input-group">
            <mat-label class="label-text">
                you are about to delete "{{ data.data.title }}"?
            </mat-label>
        </div>
        <div class="actions">
            <button class="cancel" (click)="closeModal()">Cancel</button>
            <button class="create" (click)="deleteDocument()">Yes</button>
        </div>
        </div>
    </form>
</ng-container>