import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, interval } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { SignatureService } from 'src/app/@shared/services/signature.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-add-signature-component',
  templateUrl: './add-signature-component.component.html',
  styleUrls: ['./add-signature-component.component.sass'],
})
export class AddSignatureComponentComponent implements OnInit {
  image: string = '';
  value: any;
  reader = new FileReader();
  otp: any = '';
  otpValue: string = '';
  otpError: string = '';
  timer = new Subscription();
  time = 100;
  @Output()
  done: EventEmitter<any> = new EventEmitter();

  @Output()
  status: EventEmitter<any> = new EventEmitter();

  @Input()
  reset: boolean = false;
  constructor(
    private _signatureService: SignatureService,
    private _userService: UsersService,
    private _auth: AuthenticationService,
    private _snackBar: MatSnackBar
  ) {
    this.reader.onload = (e: any) => {
      this.image = this.reader.result as string;
      this.status.emit('add-image');
    };
  }

  ngOnInit(): void {
    if (this.reset) {
      this.chooseAnother();
    } else {
      this.status.emit('start');
    }
  }

  ngOnChanges() {
    console.log({reset: this.reset})
    if (this.reset) {
      this.chooseAnother();
    } 
  }

  dragover(data: any) {
    //if (!data[0].type.toString().toLowerCase().includes('jpeg')) {
      this.value = data[0];
      this.image = data[0].name;
      this.reader.readAsDataURL(this.value);
    //
    // this.closeModalEvent.emit(data);
  }

  checkIfContainsFolder(data: FileList) {
    for (let i = 0; i < data.length; i++) {
      if (data.item(i)?.type === '') {
        return true;
      }
    }
    return false;
  }

  onFileSelected(data: any) {
    const regex = /.*\.(png|PNG)$/g;
    let files = [];

    if (data.path) {
      this.value = data.path[0].files[0];
      this.image = this.value.name;
      this.reader.readAsDataURL(this.value);
    } else if (data.target) {
      this.value = data.target.files[0];
      this.image = this.value.name;
      this.reader.readAsDataURL(this.value);
    }
    // this.closeModalEvent.emit(files);
  }

  upload() {
    document.getElementById('fileUpload')?.click();
  }

  close(data: any) {
    this.done.emit(data);
  }

  chooseAnother() {
    this.image = '';
    this.value = {};
    this.status.emit('start');
    this.upload();
  }

  sendOTP() {
    this.otp = 'otp';
    this._userService.generateOTP().subscribe(() => {
      this.status.emit('otp');
      this.startTimer();
    });
  }

  inputOtp(data: any) {
    this.otpValue = data?.target?.value || '';
  }

  random(length: number = 7) {
    return (Math.random() + 1).toString(36).substring(length);
  }

  verifyOtp() {
    this._userService.validateOTP(this.otpValue).subscribe(({ data }) => {
      const { valid } = data;
      if (valid) {
        const randString = this.random();
        this._signatureService
          .addSignature({
            name: `signature-${this._auth.userId}-${randString}`,
            file: this.value,
          })
          .subscribe((i) => {
            this.close(i);
          });
      } else {
        this.otpError = 'Invalid OTP';
      }
    });
  }

  showNotif(msg: string) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 3000,
      data: { type: 'error', text: msg },
    });
  }
  
  startTimer() {
    this.timer = interval(1000).subscribe((timeData) => {
      
      this.time = this.time - 1;
    });
    return this.timer;
  }
}
