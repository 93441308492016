import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SignSecureService } from '../../services/sign-secure.service';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-DD-YYYY',
  },
  display: {
    dateInput: 'MM-DD-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-automatic-reminders',
  templateUrl: './automatic-reminders.component.html',
  styleUrls: ['./automatic-reminders.component.sass'],
  providers: [],
})
export class AutomaticRemindersComponent implements OnInit {
  @Output('data')
  inputData: EventEmitter<any> = new EventEmitter<any>();

  data: any = {
    expiration: '',
    reminderInXDays: 0,
    reminderEveryXDays: 0,
    reminderBeforeXDays: 0,
  };

  constructor(private _sign: SignSecureService) {}

  ngOnInit(): void {
    this._sign.worflowData$.subscribe((data) => {
      const workflow = data.workflow;
      const reminders = workflow?.reminders;
      if (reminders) {
        this.data = {
          ...reminders,
          expiration: moment(reminders.expiration, 'MM-DD-YYYY'),
        };
      }
    });
  }

  changeValue(field: string, value: any) {
    this.data[field] = value?.value ?? value.target.value;
    this._sign.updateReminders(this.data);
  }
}
