<div class="advSearch-container">
  <div class="title">Advanced Search</div>
  <div class="flex-body">
    <div class="input-group-full">
      <mat-label>Description</mat-label>
      <mat-form-field>
        <input matInput />
      </mat-form-field>
    </div>
    <div class="flex-row">
      <div>
        <div class="input-group">
          <mat-label>Lawyer</mat-label>
          <mat-form-field>
            <input matInput />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label>Client</mat-label>
          <mat-form-field>
            <input matInput />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label>File No.</mat-label>
          <mat-form-field>
            <input matInput />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label>Case Title No.</mat-label>
          <mat-form-field>
            <input matInput />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label>Court</mat-label>
          <mat-form-field>
            <input matInput />
          </mat-form-field>
        </div>
      </div>
      <div>
        <div class="input-group">
          <mat-label>Date of Document</mat-label>
          <mat-form-field>
            <input matInput />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label>Date Received</mat-label>
          <mat-form-field>
            <input matInput />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label>Folder No.</mat-label>
          <mat-form-field>
            <input matInput />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label>Practice Area</mat-label>
          <mat-form-field>
            <input matInput />
          </mat-form-field>
        </div>
        <div class="checkbox-group">
          <mat-checkbox>All Documents</mat-checkbox>
          <mat-checkbox>Received</mat-checkbox>
          <mat-checkbox>Internal Record</mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-grp">
    <button class="cancel">Cancel</button>
    <button class="search">Search</button>
  </div>
</div>
