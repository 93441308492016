import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-audit-trail-details',
  templateUrl: './audit-trail-details.component.html',
  styleUrls: ['./audit-trail-details.component.sass']
})
export class AuditTrailDetailsComponent implements OnInit {


  @Input()
  data: any

  constructor() { }

  ngOnInit(): void {
  }

}
