<div class="mat-review-container">
  <div class="section">
    <div class="header">
      <span>Matter Details</span>
      <span
        ><img src="../../../assets/images/puno-assets/puno-edit-review.svg" />
        Edit</span
      >
    </div>
    <div>
      <div class="info">
        <div class="label">BarCode</div>
        <div>0000-0000-000</div>
      </div>

      <div class="info">
        <div class="label">Matter No.</div>
        <div>0000-0000-000</div>
      </div>

      <div class="info">
        <div class="label">Matter Title</div>
        <div>Lorerm ipsum dolor...</div>
      </div>

      <div class="info">
        <div class="label">Practice Area</div>
        <div>Corporate Service</div>
      </div>

      <div class="info">
        <div class="label">Status</div>
        <div>Semi-active</div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="header">
      <span>CLIENT & LAWYERS DETAILS</span>
      <span
        ><img src="../../../assets/images/puno-assets/puno-edit-review.svg" />
        Edit</span
      >
    </div>
    <div>
      <div class="info">
        <div class="label">Clients</div>
        <div>Energy Development Corporation</div>
      </div>

      <div class="info">
        <div class="label">Lawyers</div>
        <div>Puno, Roderico V.</div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="header">
      <span>CASE</span>
      <span
        ><img src="../../../assets/images/puno-assets/puno-edit-review.svg" />
        Edit</span
      >
    </div>
    <div>
      <div class="info">
        <div class="label">BarCode</div>
        <div>0000-0000-000</div>
      </div>
      <div class="info">
        <div class="label">Case No.</div>
        <div>0000-0000-000</div>
      </div>
      <div class="info">
        <div class="label">Case Title</div>
        <div>Lorerm ipsum dolor...</div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="header">
      <span>DOCUMENT DETAILS</span>
      <span
        ><img src="../../../assets/images/puno-assets/puno-edit-review.svg" />
        Edit</span
      >
    </div>

    <div class="dual-row">
      <div>
        <div class="info">
          <div class="label">BarCode</div>
          <div>0000-0000-000</div>
        </div>

        <div class="info">
          <div class="label">Document Name</div>
          <div>Lorerm ipsum dolor...</div>
        </div>

        <div class="info">
          <div class="label">Folder Number</div>
          <div>002</div>
        </div>

        <div class="info">
          <div class="label">Case Title</div>
          <div>Lorerm ipsum dolor...</div>
        </div>

        <div class="info">
          <div class="label">Court</div>
          <div>Lorerm ipsum dolor...</div>
        </div>

        <div class="info">
          <div class="label">Court Branch</div>
          <div>Lorerm ipsum dolor...</div>
        </div>

        <div class="info">
          <div class="label">File Input</div>
          <div>
            Document_001_000.docs <img
              src="../../../assets/images/delete-icon.svg" />
          </div>
        </div>
      </div>

      <div>
        <div class="info">
          <div class="label">Notes</div>
          <div class="notes-container">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
            odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
            Suspendisse urna nibh, viverra non, semper suscipit, posuere a,
            pede.
          </div>
        </div>

        <div class="info">
          <div class="label">Handling Lawyer</div>
          <div>Lorerm ipsum dolor...</div>
        </div>

        <div class="info">
          <div class="label">Court Region</div>
          <div>Lorerm ipsum dolor...</div>
        </div>

        <div class="info">
          <div class="label">Court Location</div>
          <div>Lorerm ipsum dolor...</div>
        </div>

        <div class="info">
          <div class="label">Date</div>
          <div>Lorerm ipsum dolor...</div>
        </div>
      </div>
    </div>
  </div>
</div>
