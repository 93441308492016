import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-color-palette',
  templateUrl: './color-palette.component.html',
  styleUrls: ['./color-palette.component.sass'],
})
export class ColorPaletteComponent implements OnInit, AfterContentInit {
  @Input()
  hue!: string;

  @Output()
  color: EventEmitter<string> = new EventEmitter(true);

  @Output()
  done: EventEmitter<boolean> = new EventEmitter(false);

  @ViewChild('canvas')
  canvas!: ElementRef<HTMLCanvasElement>;

  private ctx!: CanvasRenderingContext2D | null;

  private mousedown: boolean = false;

  public selectedPosition!: { x: number; y: number };

  ngAfterContentInit() {
    this.draw();
  }
  ngOnInit(): void {}

  draw() {
    try{
      if (!this.ctx) {
        this.ctx = this.canvas.nativeElement.getContext('2d');
      }
    
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    this.ctx!.fillStyle = this.hue || 'rgba(255,255,255,1)';
    this.ctx!.fillRect(0, 0, width, height);

    const whiteGrad = this.ctx!.createLinearGradient(0, 0, width, 0);
    whiteGrad.addColorStop(0, 'rgba(255,255,255,1)');
    whiteGrad.addColorStop(1, 'rgba(255,255,255,0)');

    this.ctx!.fillStyle = whiteGrad;
    this.ctx!.fillRect(0, 0, width, height);

    const blackGrad = this.ctx!.createLinearGradient(0, 0, width, height);
    blackGrad.addColorStop(0, 'rgba(0,0,0,0)');
    blackGrad.addColorStop(1, 'rgba(0,0,0,1)');

    this.ctx!.fillStyle = blackGrad;
    this.ctx!.fillRect(0, 0, width, height);

    if (this.selectedPosition) {
      this.ctx!.strokeStyle = 'black';
      this.ctx!.fillStyle = 'black';
      this.ctx!.beginPath();
      this.ctx!.arc(
        this.selectedPosition.x,
        this.selectedPosition.y,
        13,
        0,
        2 * Math.PI
      );
      this.ctx!.lineWidth = 1;
      this.ctx!.stroke();
    }}catch(err){
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hue']) {
      this.draw();
      const pos = this.selectedPosition;
      if (pos) {
        this.color.emit(this.getColorAtPosition(pos.x, pos.y));
      }
    }
  }

  onMouseUp(evt: MouseEvent) {
    this.mousedown = false;
    this.done.emit(true);
  }

  onMouseDown(evt: MouseEvent) {
    this.mousedown = true;
    this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
    this.draw();
    this.color.emit(this.getColorAtPosition(evt.offsetX, evt.offsetY));
  }

  onMouseMove(evt: MouseEvent) {
    if (this.mousedown) {
      this.selectedPosition = { x: evt.offsetX, y: evt.offsetY };
      this.draw();
      this.emitColor(evt.offsetX, evt.offsetY);
    }
  }

  emitColor(x: number, y: number) {
    const rgbaColor = this.getColorAtPosition(x, y);
    this.color.emit(rgbaColor);
  }

  getColorAtPosition(x: number, y: number) {
    const imageData = this.ctx!.getImageData(x, y, 1, 1).data;
    return this.rgbToHex(imageData[0], imageData[1], imageData[2]);
  }

  rgbToHex(r: number, g: number, b: number) {
    if (r > 255 || g > 255 || b > 255) throw 'Invalid color component';
    return `#${((r << 16) | (g << 8) | b).toString(16)}`;
  }
}
