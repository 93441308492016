import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuditRequestModel } from '../models/audit-request.model';
import { AuditLogResponseModel } from '../models/auditLogResponse.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuditLogService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly auditUrl = this.baseUrl + environment.apiConfig.audit.url;
  private filter: AuditRequestModel = {};
  private auditResult = new BehaviorSubject<any>('');
  auditResult$ = this.auditResult.asObservable();
  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthenticationService
  ) {}

  getAuditLog() {
    let url = `${this.auditUrl}/all?`;

    if (this.filter) {
      if (this.filter.email) {
        url = url + `&email=` + this.filter.email;
      }
      if (this.filter.actionType) {
        url = url + `&actionType=` + this.filter.actionType;
      }
      if (this.filter.dateFrom) {
        url = url + `&dateFrom=` + this.filter.dateFrom;
      }
      if (this.filter.dateTo) {
        url = url + `&dateTo=` + this.filter.dateTo;
      }
    }
    return this._httpClient.get<any>(url);
  }

  setFilter(filter: AuditRequestModel) {
    this.filter = filter;
  }
  setResult(data: any) {
    this.auditResult.next(data);
  }

  formatAuditLogResult(data: AuditLogResponseModel[]) {
    return data.map((datum: AuditLogResponseModel) => {
      const formattedData: any = {
        user: datum.role,
        changes_made: datum.action,
        type: datum.actionType,
        date_and_time: datum.updatedAtFormatted,
        // action: { download: '1' }, hide actions
      };
      return formattedData;
    });
  }
  downloadLog() {
    let url = `${this.auditUrl}/download?`;

    if (this.filter) {
      if (this.filter.email) {
        url = `${url}email=${this.filter.email}`;
      }
      if (this.filter.actionType) {
        url = url + `&actionType=` + this.filter.actionType;
      }
      if (this.filter.dateFrom) {
        url = `${url}&dateFrom=${this.filter.dateFrom}`;
      }
      if (this.filter.dateTo) {
        url = `${url}&dateTo=${this.filter.dateTo}`;
      }
    }

    return this._httpClient.get<any>(url, {
      responseType: 'arraybuffer' as 'json',
    });
  }
}
