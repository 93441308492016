<div class="inbox margin-container">
  <app-table
    *ngIf="!(viewMsg | async)"
    [title]="''"
    [hasCheckBox]="hasCheckBox"
    [columns]="columns"
    [data]="data | async"
    [type]="'inbox'"
    [rowFunction]="onClickRow"
    [isFolderStructure]="false"
    [selection]="selection"
  >
    <div class="extra-buttons">
      <img src="../../../assets/images/icons/delete.svg" alt="" (click)="deleteMessages()">
      <img src="../../../assets/images/sidebar/inbox-black.svg" alt="">
    </div>
  </app-table>
  <app-inbox-view
    *ngIf="viewMsg | async"
    (goBackEvent)="goback($event)"
  ></app-inbox-view>
</div>
