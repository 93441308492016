<div class="confirmation-container">
  <button matRipple class="close" (click)="closeModal()">
    <img src="../../../../assets/images/close.svg" alt="" srcset="" />
  </button>
  <img
    class="logo"
    src="'../../../../../../assets/images/email-sent.svg"
    alt="logo"
  />
  <div class="text-container flexGrid">
    <h2 class="header">Email Sent</h2>
    <h4 class="description">
      We’ve sent an email to <span class="emphasize">{{ data.data }}</span> with
      further instructions.
    </h4>
  </div>
</div>
