import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe, formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  firstValueFrom,
  forkJoin,
  lastValueFrom,
  map,
  share,
  Subscription,
  take,
  zip,
} from 'rxjs';
import { Breadcrumb } from 'src/app/@shared/models/breadcrumb.model';
import { BreadcrumbsService } from 'src/app/@shared/services/breadcrumbs.service';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-dashboard-folder-files',
  templateUrl: './admin-dashboard-folder-files.component.html',
  styleUrls: ['./admin-dashboard-folder-files.component.sass'],
})
export class AdminDashboardFolderFilesComponent implements OnInit, OnDestroy {
  filesCols: string[] = [
    ' ',
    'title',
    'Date Modified',
    'Size',
    'OCR',
    'Indexed',
    'actions',
  ];
  filesData: any = this._fileService.filesAndFolderAsync$;
  hasCheckBox: boolean = environment.flag.multiFileSelect;
  modifyFile = this._fileActionService.isModify$;
  folderFunctions = {
    openFile: this.openFile,
  };

  selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  selectedIds: string[] = [];
  subs = new Subscription();
  // to keep track  statuses
  viewFile: boolean = false;
  modify: boolean = false;
  isAllChecked: boolean = false;
  folderId: string = '';
  readonly multipleFileActionsFlag = environment.flag.multipleFileActions;

  isTabletWidth = false;
  isPhoneWidth = false;

  constructor(
    private _fileService: FilesService,
    private _fileActionService: FileActionService,
    private _breadcrumbs: BreadcrumbsService,
    private _loader: LoaderService,
    public dialog: MatDialog,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (!this.viewFile && !this.modify) {
      this._breadcrumbs.emptyBreadcrumb();
    }
  }
  ngOnInit(): void {
    if (screen.width >= 280 && screen.width <= 600) {
      this.isPhoneWidth = true;
      this.filesCols = ['title', 'actions'];
    } else if (screen.width >= 600 && screen.width <= 1368) {
      this.isTabletWidth = true;
      this.filesCols = ['title', 'actions'];
    } else {
      this.isPhoneWidth = false;
      this.isTabletWidth = false;
      this.filesCols = [
        ' ',
        'title',
        'Date Modified',
        'Size',
        'OCR',
        'Indexed',
        'actions',
      ];
    }

    this.subs.add(
      this._activatedRoute.paramMap.subscribe(params => {
        this.folderId = params.get('id') || '';
      })
    );
    this.subs.add(this.getFilesAndFolderData());
    //TODO: this is for when folder is shared.
    // this._fileService.filesAndFolderAsync$.subscribe(data => {
    //   if (this.folderId) {
    //     const folderData = data.find(file => file.id);
    //    this.openFolder(null,folderData);
    //   }
    // });
    this.subs.add(
      this._fileActionService.isViewFile$.subscribe(i => {
        this.viewFile = i;
      })
    );
    this.subs.add(
      this._fileActionService.isModify$.subscribe(i => {
        this.modify = i;
      })
    );
  }

  createFolder(data: any) {
    const { dialog, type: action, breadcrumb } = data;
    const dialogRef = dialog?.open(ModalComponent, {
      data: { action, data: breadcrumb },
    });

    dialogRef.afterClosed()?.subscribe(async () => {
      this.isAllChecked = false;
      this.selection.clear();
    });
  }

  openFile(file: any): void {
    this._fileActionService.file = file;
    this._fileActionService.setIsModify(true);
  }

  donwloadSelected() {
    this.selection.selected.forEach(data => {
      if (data.file_type == 'file') {
        this._fileService.downloadFile(
          data.title,
          `assets/${data.actions?.choices?.download?.path}`
        );
      } else {
        this._fileService.downloadFolder(data.id).subscribe((response: any) => {
          const blob = new Blob([response], { type: 'application/zip' });
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', `${data.title}.zip`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      }
    });
  }

  bulkDownload() {
    const dateNow = formatDate(
      new Date().toLocaleString(),
      'MM-dd-yy',
      'en-ph'
    );
    this._fileService
      .downloadBulkFiles(this.selectedIds)
      .subscribe((data: any) => {
        const blob = new Blob([data], { type: 'application/zip' });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `Files-${dateNow}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  deleteSelected() {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '512px',
      data: {
        action: 'delete-selected-files',
        data: this.selection.selected,
      },
    });

    dialogRef.afterClosed()?.subscribe(() => {});
  }

  deleteBulkFiles() {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '512px',
      data: {
        action: 'delete-selected-files',
        isBulk: true,
        data: this.selectedIds,
      },
    });

    dialogRef.afterClosed()?.subscribe(() => {
      this._breadcrumbs.removeBreadcrumbData();
      this.isAllChecked = false;
      this.selection.clear();
    });
  }

  async openFolder(event?: any, data?: any) {
    this.selection.clear();
    if (event?.target.className.includes('checkbox')) {
      return;
    }

    const { title, file_type, id } = data;
    if (file_type === 'folder') {
      this._breadcrumbs.setBreadcrumbsData({ title, id });
      this.selection.clear();
      if (id === 'signsecure') {
        this._fileService.setFilesAndFolderAsync(
          await firstValueFrom(
            this._fileService.getWorkflowData(99999).pipe(
              map(({ data }) => {
                return this._fileService.formatFilesAndFolderData(data, true);
              })
            )
          )
        );
      } else {
        this._fileService.setFilesAndFolderAsync(
          await firstValueFrom(
            this._fileService.getAllFolderFiles(id).pipe(
              map(({ data }) => {
                return this._fileService.formatFilesAndFolderData(
                  data,
                  false,
                  true
                );
              })
            )
          )
        );
      }
    } else if (file_type === 'back') {
      this.selection.clear();
      const breadcrumb = this._breadcrumbs.removeBreadcrumbData();

      if (breadcrumb.length === 0) {
        this._fileService.setFilesAndFolderAsync(
          await firstValueFrom(
            this._fileService.getAllFilesAndFolder().pipe(
              map(data => {
                return this._fileService.formatFilesAndFolderData(
                  data.data.data
                );
              })
            )
          )
        );
      } else {
        this._fileService
          .getAllFolderFiles(breadcrumb[breadcrumb.length - 1].id)
          .subscribe(({ data }) => {
            const format = this._fileService.formatFilesAndFolderData(
              data,
              false,
              true
            );

            this._fileService.setFilesAndFolderAsync(format);
          });

        this._fileService.setFilesAndFolderAsync(
          await firstValueFrom(
            this._fileService
              .getAllFolderFiles(breadcrumb[breadcrumb.length - 1].id)
              .pipe(
                map(({ data }) => {
                  return this._fileService.formatFilesAndFolderData(
                    data,
                    false,
                    true
                  );
                })
              )
          )
        );
      }
    }
  }

  onFolderBack(data: Breadcrumb) {
    this.selection.clear();
    this._fileActionService.setIsModify(false);
    if (data.id === '') {
      this._breadcrumbs.emptyBreadcrumb();
      this._fileService.getAllFolder().subscribe(({ data }) => {
        this._fileService.setFolderAsync(this._fileService.formatFolder(data));
      });
      this._fileService.getAllFilesAndFolder().subscribe(({ data }) => {
        const format = this._fileService.formatFilesAndFolderData(data.data);
        const signSecure = {
          id: 'signsecure',
          title: 'SignSecure workflow documents',
          file_type: 'folder',
          status: '',
        };

        this._fileService.setFilesAndFolderAsync([signSecure, ...format]);
      });
    } else {
      const loc = this._breadcrumbs.breadcrumbsData.findIndex(
        datum => datum.id === data.id
      );
      this._breadcrumbs.backBreadcrumb(loc);

      if (data.id === 'signsecure') {
        this._fileService.getWorkflowData(99999).subscribe(({ data }) => {
          const format = this._fileService.formatFilesAndFolderData(data, true);

          this._fileService.setFilesAndFolderAsync(format);
        });
      } else {
        this._fileService.getAllFolderFiles(data.id).subscribe(({ data }) => {
          const format = this._fileService.formatFilesAndFolderData(
            data,
            false,
            true
          );

          this._fileService.setFilesAndFolderAsync(format);
        });
      }
    }
  }

  openPdf = (data: any) => {
    if (data.file_type === 'folder' || data.file_type === 'back') return;
    let url =
      data?.action?.choices?.download?.path ||
      data?.actions?.choices?.download?.path ||
      data?.action?.download?.path ||
      data?.actions?.download?.path;

    if (
      data.fileExtension == 'docx' ||
      data.fileExtension == 'doc' ||
      data.fileExtension == 'xls' ||
      data.fileExtension == 'xlsx' ||
      data.fileExtension == 'ppt' ||
      data.fileExtension == 'pptx' ||
      data.fileExtension == 'zip' ||
      data.fileExtension == 'rar' ||
      data.fileExtension == '7z'
    ) {
      let office = '';
      if (data.fileExtension == 'docx' || data.fileExtension == 'doc') {
        office = 'ms-word';
      }
      if (data.fileExtension == 'xls' || data.fileExtension == 'xlsx') {
        office = 'ms-excel';
      }
      if (data.fileExtension == 'ppt' || data.fileExtension == 'pptx') {
        office = 'ms-powerpoint';
      }

      // window.location.assign(
      //   office + ':ofv|u|' + window.location.origin + '/assets/' + url
      // );
      this._fileService.getProxyUrl(data.id).subscribe(msData => {
        this._fileService.downloadFile(data.title, msData);
      });
      return;
    }
    //url = `../../../assets/${url}`;

    if (data.fileExtension == 'mp4' || data.fileExtension === 'mp3') {
      const dialogRef = this.dialog.open(ModalComponent, {
        panelClass: 'transparent-dialog',
        backdropClass: 'dark-bg',
        data: {
          action: 'media-viewer',
          data: data,
        },
      });

      dialogRef.afterClosed().subscribe(() => {});
    } else {
      this._fileActionService.pdfSrc = url;
      this._fileActionService.file = data;
      this._fileActionService.setIsViewFile(true);
      this._router.navigateByUrl(
        `/file-view/${data.fileExtension.toLowerCase()}/${data.id}`
      );
    }
  };

  clearSelection() {
    this.selection.clear();
  }

  getFilesAndFolderData() {
    const temp =
      this._breadcrumbs.breadcrumbsData[
        this._breadcrumbs.breadcrumbsData.length - 1
      ];
    let breadcrumb;
    if (temp?.id === '') {
      breadcrumb = this._breadcrumbs.removeBreadcrumbData();
    } else {
      breadcrumb = [temp];
    }

    if (!!!temp || breadcrumb.length === 0) {
      forkJoin([
        this._fileService.getAllFilesAndFolder(),
        this._fileService.getAllFilesAndFolder(false),
      ]).subscribe(([userFiles, sharedDocuments]) => {
       

        const reduced = sharedDocuments.data.data.filter(
          (data: any) =>
            !sharedDocuments.data.data.some(
              (datum: any) => datum.id === data.parent
            ) && data.parent
        );

        const formatUserFiles = this._fileService.formatFilesAndFolderData(
          userFiles.data.data
        );

        const sharedFiles = this._fileService.formatFilesAndFolderData(reduced);

        const signSecure = {
          id: 'signsecure',
          title: 'SignSecure workflow documents',
          file_type: 'folder',
          status: '',
        };

        const sortDate = [...formatUserFiles, ...sharedFiles].sort(
          (a, b) =>
            new Date(b['date_modified']).getTime() -
            new Date(a['date_modified']).getTime()
        );

        this._fileService.setFilesAndFolderAsync([signSecure, ...sortDate]);
      });
    } else if (breadcrumb[breadcrumb.length - 1].id === 'signsecure') {
      this._fileService.getWorkflowData(99999).subscribe(({ data }) => {
        const format = this._fileService.formatFilesAndFolderData(data, true);

        this._fileService.setFilesAndFolderAsync(format);
      });
    } else {
      this._fileService
        .getAllFolderFiles(breadcrumb[breadcrumb.length - 1].id)
        .subscribe(({ data }) => {
          const format = this._fileService.formatFilesAndFolderData(data);

          this._fileService.setFilesAndFolderAsync(format);
        });
    }
  }

  moveBulk() {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'move-file',
        bulk: true,
        data: this.selectedIds,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.isAllChecked = false;
      this.selection.clear();
    });
  }

  modalCloses(data: any) {
    if (data == 'deleted') {
      this._breadcrumbs.removeBreadcrumbData();
    }
    if (data?.modalAction == 'share-permission') {
      this.getFilesAndFolderData();
    }
  }
}
