import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly subsUrl = environment.apiConfig.subscription.url;
  private readonly _subsUrl = `${this.baseUrl}${this.subsUrl}`;
  private subsIndex = new BehaviorSubject<any>([]);
  subsIndex$ = this.subsIndex.asObservable();
  constructor(private _httpClient: HttpClient) {}
  
  setIndex(index: number) {
    this.subsIndex.next(index);
  }

  getSubscription() {
    return this._httpClient.get(`${this._subsUrl}`);
  }
}
