<div class="template-billing-confirmation">
  <h1>Payment Confirmation</h1>
  <p>You can now edit, sign, and notarize your document.</p>
  <div class="templates">
    <div class="template">
      <img src="" alt="" />
      <div class="details">
        <p class="title">Nondisclosure Agreement</p>
        <p class="pages">5 pages</p>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button matRipple class="cancel">Go back</button>
    <button matRipple class="continue">Continue</button>
  </div>
</div>
