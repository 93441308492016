import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.sass']
})
export class ReviewComponent implements OnInit {

  data: any

  subject: string = ''
  message: string = ''

  dataSent: boolean = false
  privacy: string = 'CONFIDENTIAL'
  constructor(private _signSecure: SignSecureService, private _loader: LoaderService) { }

  ngOnInit(): void {
    this._signSecure.worflowData$.subscribe(data => {
      console.log({ data })
      this.data = data
      // this.subject = `Please sign ${data.name}`
      this.subject = this.data.name
    })
  }

  goBack(stage: number) {
    this._signSecure.nextProgress(stage)
  }

  done() {
    if (this.dataSent) return

    this.dataSent = true
    this._loader.show();
    this._signSecure.createWorkflow({ ...this.data, workflow: { ...this.data.workflow, emailContent: { subject: this.subject, message: this.message }, privacyType: this.privacy } }).subscribe(result => {
      console.log({ result })
      this._signSecure.nextProgress(6)
      this._loader.hide()
    })
  }

  clearReminders(event: any) {
    if (!event.checked) {
      this._signSecure.updateReminders(undefined)
    }
  }
}
