import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Subject, combineLatest, map, takeUntil } from 'rxjs';
import { dashFilter } from 'src/app/@shared/enums/dashboard-filter.enum';
import { UserResponseModel } from 'src/app/@shared/models/user-response.model';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { DashBoardServices } from 'src/app/@shared/services/dashboard.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { WorkflowService } from 'src/app/@shared/services/workflow.service';
import { LocalizationService } from 'src/app/sign-secure/services/localization.service';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.sass'],
})
export class WorkflowComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  filesCols: string[] = [
    'title',
    'status',
    'Date Created',
    'Last Updated',
    'actions',
  ];
  readonly templateFlag = environment.flag.workFlowTemplate;
  readonly workflow = environment.flag.useLocalSign;

  data: any;
  role: string = '';
  isPhoneWidth: boolean = false;

  constructor(
    private _navigation: NavigationService,
    private _workflowService: WorkflowService,
    private authService: AuthenticationService,
    private _signSecureService: SignSecureService,
    private _filesService: FilesService,
    private _router: Router,
    private _dashboardService: DashBoardServices,
    private _localize: LocalizationService
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.unsubscribe();
  }

  ngOnInit(): void {
    if (screen.width >= 393 && screen.width <= 490) {
      this.isPhoneWidth = true;
    } else {
      this.isPhoneWidth = false;
    }
    let tokenPayload: UserResponseModel;
    const token = localStorage.getItem('token');
    // if (token != null && !this.workflow) {
    //   tokenPayload = this.authService.getUserDataFromToken(token);
    //   this.role = tokenPayload.user.role;
    //   this._workflowService
    //     .getUserDocuments(tokenPayload.user.id)
    //     .pipe(takeUntil(this.ngUnsubscribe))
    //     .subscribe((result) => {
    //       const deleteCriteria = ['cancelled', 'rejected', 'declined'];
    //       console.log(result);
    //       const data = Object.assign(result);

    //       this.data = data.sort((a: any, b: any) =>
    //         this.dateConverter(b.last_updated)
    //           .toLocaleDateString()
    //           .localeCompare(
    //             this.dateConverter(a.last_updated).toLocaleDateString()
    //           )
    //       );

    //       this.data = this.data.map((x: any) => ({
    //         ...x,
    //         date_created: moment(x.date_created).format('DD MMM YYYY'),
    //         last_updated: moment(x.last_updated).format('DD MMM YYYY'),
    //         uid: localStorage.getItem('userID'),
    //         actions: {
    //           delete: deleteCriteria.includes(x.status) ? true : false,
    //         },
    //       }));
    //     });
    // } else {
    // this._dashboardService.filterDashboardData(dashFilter.all).subscribe(({ data }) => {
    //   this.data = this._signSecureService.formatWorkflowData(data.items);
    // });
    this.loadSignSecureDocs();
   
  }

  loadSignSecureDocs() {
    this._filesService.getWorkflowData(999).subscribe(data => {
      this.data = this._signSecureService.formatWorkflowData(data.data);
    });
  }

  
  dateConverter(date: string) {
    return new Date(date);
  }

  redirectUrl() {
    this._navigation.toggleSideBar('toggle')
    this._router.navigateByUrl(`sign-secure`);
    // if (this.workflow) {
    //   this._signSecureService.nextProgress(1);
    // } else {
    //   const token = localStorage.getItem('token');
    //   const userID = localStorage.getItem('userID');
    //   window.location.href = `${environment.integration.signSecureWebBaseURL}/my-safeforms/create?token=${token}&userID=${userID}`;
    // }
  }

  rowFunction = (event: any, element: any) => {
    if (event.target.localName == 'img') {
      return;
    }

    if (element?.action?.download || element?.action?.delete) {
      return;
    }

    this._signSecureService.setWorkFlowData(element);
    this._localize.storeData('workflow', element);
    this._router.navigateByUrl(`sign-secure/${element.id}`);
  };

  formatWorkflowData(data: any) {
    return data
      .filter((datum: any) => datum.workflow)
      .map((datum: any) => {
        return {
          ...datum,
          title: datum.name,
          status: datum.workflow.status,
          date_created: datum.createdAtFormatted.split(',')[0],
          last_updated: datum.updatedAtFormatted.split(',')[0],
          workflow: datum.workflow,
        };
      });
  }
}
