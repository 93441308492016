import { Component, Input, OnInit } from '@angular/core';
import { SignSecureService } from '../../services/sign-secure.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.sass']
})
export class StepComponent implements OnInit {

  @Input()
  step: any = null

  @Input()
  index: number = 0;

  @Input()
  move: Function = () => { }

  workflow: any
  constructor(private _stepsNav: SignSecureService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this._stepsNav.worflowData$.subscribe(workflow => {
      this.workflow = workflow
    })
  }

  changeSection(templateSection: number) {
    if (this.step.status === 'done') {
      this.move(templateSection, this.workflow)
    }
  }

  getIcon() {
    console.log({ 'background-image': this.step[this.step.status]})
      return { 'mask-image': `url(${this.step[this.step.status]})`}
  }
}
