<div class="decline-container">
    <div class="header">
        <h2 class="header__title">Decline to sign?</h2>
        <img alt="" class="header__close_img" (click)="close('')" [style.mask-image]="'url(../../../../assets/images/template-icons/close.svg)'">
    </div>
    <div class="content">
        <p class="content__title">Please tell us why you’re not signing the document</p>
        <textarea [(ngModel)]="note" class="content__input" required></textarea> 
    </div>
    <div class="buttons">
        <button class="reject" (click)="decline()">Cancel</button>
        <button class="approve" (click)="approve()">Submit</button>
    </div>
</div>