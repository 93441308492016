import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/@shared/services/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.sass'],
})
export class SettingsFormComponent implements OnInit {
  primaryColor: string = '';
  secondaryColor: string = '';
  backgroundColor: string = '';
  textColor: string = '';
  topbarColor: string = '';
  linkColor: string = '';
  companyLogoSrc: string = '../../../assets//images/forma/unawa-logo.png';
  companyLogo: any;
  customColorPicker = environment.flag.customColorPicker;
  theme = {
    logo: '',
    primaryColor: '',
    menuBackgroundColor: '',
    backgroundColor: '',
    textColor: '',
    topbarColor: '',
    hoverColor: '',
    headingFont: 'test',
    subheadingFont: 'test',
    bodyFont: 'test',
  };
  
  colorForm: FormGroup = this.fb.group({
    color: ['', []],
  });
  
  constructor(private _settings: SettingsService,private fb: FormBuilder) {}

  ngOnInit(): void {
    this._settings.updateTheme$.subscribe((theme: any) => {
      this.theme.logo = theme.logo
      this.theme.primaryColor = theme.primaryColor;
      this.theme.menuBackgroundColor = theme.menuBackgroundColor;
      this.theme.backgroundColor = theme.backgroundColor;
      this.theme.textColor = theme.textColor;
      this.theme.topbarColor = theme.topbarColor;
      this.theme.hoverColor = theme.hoverColor;
    });
  }

  updateColor(location: string, color: any) {
    type ThemeKey = keyof typeof this.theme;
    this.theme[location as ThemeKey] = color;

    console.log({theme: this.theme}, color, location)
  }

  previewTheme() {
    type ThemeKey = keyof typeof this.theme;
    const keys = Object.keys(this.theme);
    keys.forEach((key) => {
      this._settings.updatePreviewColor(key, this.theme[key as ThemeKey]);
    });

  }

  updateTheme(file: any = this.companyLogo) {
    type ThemeKey = keyof typeof this.theme;
    const keys = Object.keys(this.theme);
    const themeData = new FormData();
    themeData.append('file', file);
    keys.forEach((key) => {
      themeData.append(key, this.theme[key as ThemeKey]);
    });
    themeData.append('linkColor', this.theme['topbarColor'])
    this._settings.updateSettings(themeData).subscribe((data:any) => {
      console.log('settings updated', data);
      const theme = {...data.data, logo: data.data.companyLogo, topbarColor: data.data.linkColor}
      
      this._settings.setTheme(theme)
    });
  }

  changeLogo() {
    document.getElementById('fileUpload')?.click();
  }

  onFileSelected(data: any) {
    const regex = /.*\.(jpg|JPG|png|PNG|jpeg|JPEG)$/g;
    let files = [];
    if (data.path) {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else if (data.target) {
      files = Array.from(data.target.files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    }

    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.theme.logo = e.target.result;
    };
    console.log({files})
    reader.readAsDataURL(files[0] as Blob);

    this.companyLogo = files[0];
  }

  async resetDefaultTheme() {
    const theme = this._settings.defaultTheme
    console.log({theme})
    const abLogo = await fetch(theme.logo).then(data => data.blob())
    this.companyLogo = abLogo
    this.theme = theme
    const file = new File([abLogo], 'new logo.png')
    this.updateTheme(file)

  }
}
