import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalData } from 'src/app/@shared/models/Modal.model';

@Component({
  selector: 'app-sent-confirmation',
  templateUrl: './sent-confirmation.component.html',
  styleUrls: ['./sent-confirmation.component.sass'],
})
export class SentConfirmationComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  @Input()
  data: ModalData = {
    type: '',
    action: '',
    data: '',
  };
  constructor() {}

  ngOnInit(): void {}

  closeModal() {
    this.closeModalEvent.emit();
  }
}
