import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpResponse,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthenticationService,
    @Inject(DOCUMENT) private _document: Document
  ) {}
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this._authService.token) {
      return next.handle(httpRequest);
    } else {
      return next.handle(
        httpRequest.clone({
          setHeaders: { authorization: `Bearer ${this._authService.token}` },
        })
      );
    }
  }
}
