<div class="navbar-container" *ngIf="!isUseSignsecure" [ngClass]="{ 'mobile-overlay-active': sideNavToggle }">
  <div class="navbar-content">
    <img *ngIf="isTabletWidth" src="../../../assets/images/unawa-logo-with-icon.svg" alt="" class="small-logo" />
    <div class="left-side">
      <h1 class="title">{{pageTitle}}</h1>
    </div>

    <div class="right-side">
      <div class="menu-hamburger">
        <img
          src="../../../assets/images/nav-hamburger.svg"
          class="clickable"
          (click)="toggleSideNav()" />
      </div>
      <div class="unawa-logo-side">
        <img src="../../../assets/images/unawa-forma-logo-white.svg" alt="" />
      </div>

      
      <div class="search-form">
        <mat-form-field appearance="fill">
          <input
            matInput
            type="text"
            placeholder="Search"
            [(ngModel)]="searchQuery"
            (keydown.enter)="search()"
          />
          <span matPrefix
            ><img
              class="search-image"
              src="../../../assets/images/icons/search.png"
              alt=""
          /></span>
        </mat-form-field>
        <!-- <button type="submit" class="search-button" (click)="search()">
          Search
        </button> -->
      </div>

      <button matRipple class="upload" [matMenuTriggerFor]="uploadMenu">
        Upload Document <img src="../../../assets/images/icons/plus.png" alt="" />
      </button>
      <mat-menu #uploadMenu="matMenu" xPosition="before">
        <div class="upload-menu-item">
          <button
            mat-menu-item
            class="menu-label"
            (click)="showUploadDragAndDropModal()"
          >
            <img src="../../../assets/images/forma/upload-document.png" alt="" />
            Upload document
            <input
              type="file"
              id="fileUpload"
              name="fileUploads"
              style="display: none"
              (change)="onFileSelected($event)"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              multiple
            />
          </button>
          <a routerLink="/templates" mat-menu-item class="menu-label" *ngIf="isFormaCommercial">
            <img
              src="../../../assets/images/forma/small-template-icon.png"
              alt=""
            />
            Create from template
          </a>
        </div>
      </mat-menu>

      <!-- Profile temporary save while sidenav is not yet ready -->
      <!-- <button matRipple class="avatar-container" [matMenuTriggerFor]="accountMenu">
        <img [src]="pictureSrc" alt="Avatar" />
      </button>
      <mat-menu #accountMenu="matMenu" xPosition="before">
        <button mat-menu-item class="menu-label" (click)="viewAccount()">
          <img
            class="mini-avatar"
            [src]="'../../../assets/images/icons/accountAvatar.png'"
            alt="smallAvatar"
          />
          View Account
        </button>
        <button mat-menu-item class="menu-label" (click)="logout()">
          <img src="../../../assets/images/icons/logout.png" alt="logout" /> Log
          out
        </button>
      </mat-menu> -->
    </div>
  </div>
</div>

<div class="phone-search" *ngIf="isPhoneWidth && _navigation.lastKnownLocation === '/dashboard'" [ngClass]="{ 'mobile-overlay-active': sideNavToggle }">
  <div class="search">
    <!-- floatLabel="never" -->
    <mat-form-field
      appearance="fill"
      class="width-adjustment"
      >
      <input
        matInput
        type="text"
        placeholder="Search"
        [(ngModel)]="searchQuery"
        (keydown.enter)="search()" />
      <span matPrefix
        ><img
          class="search-image"
          src="../../../assets/images/icons/search.png"
          alt=""
      /></span>
    </mat-form-field>
  </div>
  <div class="button-container">
    <button type="submit" class="phone-upload" (click)="showUploadDragAndDropModal()">
      <img src="../../../assets/images/icons/plus.png" alt="" />
    </button>
  </div>
</div>