<div class="mat-addCase-container">
  <div class="header">Matter Details</div>
  <div class="input-container">
    <mat-label class="label">Barcode</mat-label>
    <mat-form-field><input matInput /></mat-form-field>
  </div>
  <div class="input-container">
    <mat-label class="label">Case No.</mat-label>
    <mat-form-field><input matInput /></mat-form-field>
  </div>
  <div class="input-container">
    <mat-label class="label">Case Title</mat-label>
    <mat-form-field><input matInput /></mat-form-field>
  </div>
  <div class="btn-container">
    <button class="btn" (click)="nextStep()">Next</button>
  </div>
</div>
