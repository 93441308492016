<ng-container *ngIf="aTProgress | async as progress">
  <div class="container {{ progress }}">
    <app-template-navbar
      class="header"
      [step]="progress"
      (review)="review($event)"
      [pageNumber]="pdfDetails?.page ?? 0"
      [totalPages]="pdfDetails?.total ?? 0"
      [updatePage]="pdfDetails?.updatePage ?? null"
      [addedAll]="addedAll"></app-template-navbar>
    <div class="body">
      <mat-card class="card-body card-{{ progress }}"
        >
        <ng-container [ngSwitch]="progress">
          <ng-container
            *ngSwitchCase="'1'"
            [ngTemplateOutlet]="MatterDetails"></ng-container>
          <ng-container
            *ngSwitchCase="'2'"
            [ngTemplateOutlet]="AddCase"></ng-container>
          <ng-container
            *ngSwitchCase="'3'"
            [ngTemplateOutlet]="DocumentDetails"></ng-container>
          <ng-container
            *ngSwitchCase="'4'"
            [ngTemplateOutlet]="Review"></ng-container>
          <ng-container
            *ngSwitchDefault
            [ngTemplateOutlet]="Default"></ng-container>
        </ng-container>
      </mat-card>
    </div>
  </div>
</ng-container>

<ng-template #Default></ng-template>
<ng-template #MatterDetails
  ><advanced-tagging-matter-details></advanced-tagging-matter-details>
</ng-template>

<ng-template #AddCase
  ><advanced-tagging-add-case></advanced-tagging-add-case>
</ng-template>

<ng-template #DocumentDetails
  ><advanced-tagging-document-details></advanced-tagging-document-details>
</ng-template>

<ng-template #Review
  ><advanced-tagging-review></advanced-tagging-review>
</ng-template>
