<div class="fields-container">
  <div class="fields-container__sidebar">
    <div class="select-field">
      <label for="qr-postion">Select QR code position</label>
      <mat-select
        name="qr-position"
        id=""
        [(value)]="qrPosition"
        (selectionChange)="updateQRPostion($event)"
      >
        <mat-option value="bottom-right">Bottom Right</mat-option>
        <mat-option value="bottom-left">Bottom Left</mat-option>
        <mat-option value="bottom-center">Bottom Center</mat-option>
        <mat-option value="top-right">Top Right</mat-option>
        <mat-option value="top-left">Top Left</mat-option>
        <mat-option value="top-center">Top Center</mat-option>
      </mat-select>
    </div>
    <div class="select-field">
      <label for="fields-for">Add fields for</label>
      <mat-select name="fields-for" [(value)]="fieldFor">
        <mat-select-trigger>
          <div
            class="color"
            style="background-color: {{ signatories[fieldFor]?.color }};"
          ></div>
          {{ signatories[fieldFor]?.name }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let signatory of signatories; let i = index"
          [value]="i"
          (click)="changeSignatory(i)"
        >
          <mat-icon [ngStyle]="{ color: signatory.color }">circle</mat-icon>
          {{ signatory.name }}
        </mat-option>
      </mat-select>
      <span *ngIf="signatories[fieldFor]?.role !== 'SIGN'">{{
        getMessage(signatories[fieldFor]?.role)
      }}</span>
    </div>
    <div
      class="document-fields"
      cdkDropList
      [cdkDropListData]="options"
      #optionList="cdkDropList"
      (cdkDropListDropped)="drop($event)"
    >
      <!-- <h5 class="document-fields__title">Document fields</h5>
      <h6 class="document-fields__subtitle">
        <b>Drag and Drop</b> the field into the document
      </h6> -->

      <button
        *ngFor="let option of options"
        class="document-fields__field-action {{
          option.disabled || option.disabledAll ? 'disabled' : ''
        }}"
        cdkDrag
        [cdkDragDisabled]="option.disabled || option.disabledAll"
        (mousedown)="selectedField(option)"
      >
        <img alt="" class="field-action__img" [style.mask-image]="'url(' + option.image + ')'"/>
        <span class="field-action__label">{{ option.name }}</span>

        <app-signature-field-preview
          *cdkDragPreview
          [details]="option"
          [signatory]="signatories[fieldFor]"
        ></app-signature-field-preview>
      </button>
    </div>
  </div>
  <div class="container-content">
    <div class="content-title">
      <h2 for="fields-for">Document Feilds</h2>
      <label for="fields-for">Drag and drop the field into the document. </label>
    </div>
    <div class="thumbnail-scrool">
      <div 
        class="pdf-thumbnail"
        *ngFor="let _ of [].constructor(totalPages); let i = index "
        >
        <pdf-viewer
          [src]="pdfSrc"
          [rotation]="0"
          [original-size]="false"
          [show-all]="false"
          [page]="i + 1"
          [fit-to-page]="true"
          [zoom]="1"
          [zoom-scale]="'page-width'"
          [stick-to-page]="true"
          [render-text]="true"
          [external-link-target]="'blank'"
          [autoresize]="true"
          [show-borders]="false"
          (click)="clickPdfPage(i + 1)"
          #viewer
        >
        </pdf-viewer>
      </div>
    </div>
    <div class="form-content">
      <div class="fields-container__content" #scroll>
        <div class="pdf" #pdf>
          <div
            class="signatures"
            cdkDropList
            #pages="cdkDropList"
            [cdkDropListConnectedTo]="[optionList]"
            (cdkDropListDropped)="drop($event)"
          >
            <div
              class="signature"
              *ngFor="let mSignatures of signatures; let i = index"
              (mouseenter)="currentPage($event, i)"
              [ngStyle]="pageConfigs[i]?.style"
            >
              <app-signature-field
                [details]="mSignatures"
                [i]="i"
                *ngIf="!loading"
                [config]="pageConfigs[i]"
                (location)="setCursorLocation($event, i)"
                (updateSignatureDetail)="updateSignature($event)"
                (showControls)="showControls($event)"
                (isDragged)="updateDrag($event)"
                [zoom]="1"
                [qrPosition]="qrPosition"
                [qr]="qrData"
              ></app-signature-field>
            </div>
            <div
              class="field_controls"
              [style.left]="left"
              [style.top]="top"
              [style.display]="controlsDisplay"
            >
              <button class="approve" (click)="hideControls()">
                <img
                  src="../../../../../assets/images/template-icons/check.svg"
                  alt=""
                  srcset=""
                />
              </button>
              <button class="delete" (click)="deleteSignature()">
                <img
                  src="../../../../../assets/images/template-icons/delete.svg"
                  alt=""
                  srcset=""
                />
              </button>
            </div>
          </div>
          <pdf-viewer [ngStyle]="{scale: zoomScale}"
            [src]="pdfSrc"
            [rotation]="0"
            [original-size]="false"
            [show-all]="false"
            [page]="selectedPage"
            [fit-to-page]="false"
            [zoom]= "zoom"
            [zoom-scale]="'page-width'"
            [stick-to-page]="true"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="false"
            (after-load-complete)="afterLoadComplete($event)"
            (pages-initialized)="testing($event, 'initialized')"
            (page-rendered)="pageRendered($event)"
            (scroll)="onScroll($event)"
            #viewer
          >
          </pdf-viewer>
        </div>
      </div>
      <div class="zoom-btn">
        <button (click)="zoomPdf('in')">
          <img src="../../../assets//images/icons/zoom-in.svg" alt="" />
        </button>
        <button class="zoom-out" (click)="zoomPdf('out')">
          <img src="../../../assets//images/icons/zoom-out.svg" alt="" />
        </button>
      </div>
    </div>
  </div>

  <div class="bottom">
    <div class="button-grp">
      <button class="back" (click)="back()">Back</button>
      <button class="next" (click)="next()">Next</button>
    </div>
  </div>
</div>
