import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { SignSecureService } from 'src/app/sign-secure/services/sign-secure.service';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.sass'],
})
export class TemplateDetailsComponent implements OnInit {

  name: string = ''
  description: string = ''


  constructor(private _signSecureService: SignSecureService, private _snack: MatSnackBar) {
    this._signSecureService.worflowData$.subscribe(data => {
      console.log({ data })
      this.name = data.name ?? ''
      this.description = data.description ?? ''
    })
  }

  ngOnInit(): void {

  }

  back() {
    this._signSecureService.nextProgress(1);
  }

  next() {

    if (!!!this.name) {
      this._snack.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: { type: 'error', text: `Please enter a document title` },
      });
      return
    }

    this._signSecureService.setFileInfo(this.name, this.description)
    this._signSecureService.nextProgress(3);

    this._signSecureService.setFileInfo(this.name, this.description)
    this._signSecureService.nextProgress(3);
  }
}
