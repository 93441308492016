export const DATE_FORMAT= {
    parse: {
      dateInput: 'DD MMM YYYY',
    },
    display: {
      dateInput: 'DD MMM YYYY',
      monthYearLabel: 'DD MMM YYYY',
      dateA11yLabel: 'DD MMM YYYY',
      monthYearA11yLabel: 'DD MMM YYYY',
    },
  };