<div class="settings-container">
  <h4 class="page-title">Settings</h4>

  <mat-tab-group class="settings-content" #tabGroup preserveContent>
    <mat-tab>
      <div style="display: flex;flex-direction: column;gap: 2vw;flex-grow: 1;">
        <ng-template mat-tab-label>
          <span>Marketing Banners</span>
        </ng-template>
        <app-settings-general></app-settings-general>
        <app-table
          [title]="'Current Banners'"
          [columns]="cols"
          [data]="data"
          [paginate]="true"
        ></app-table>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Customize Dashboard</span>
      </ng-template>
      <app-settings-form></app-settings-form>
      <app-settings-preview></app-settings-preview>
    </mat-tab>
  </mat-tab-group>
</div>
