import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-progress-card',
  templateUrl: './progress-card.component.html',
  styleUrls: ['./progress-card.component.sass'],
})
export class ProgressCardComponent implements OnInit {
  /**
   * TODO: Add functionality for dynamic content
   */
  @Input()
  image: string = '../../../assets/images/icons/duplicate-icon.png';

  @Input()
  title: string = 'Duplicate';

  @Input()
  number: string = "14";

  @Input()
  location:string = '';

  @Output() filterWorkflowDataEvent = new EventEmitter<string>();

  constructor(private _navigationService:NavigationService) {}

  ngOnInit(): void {}
  navigateTo(loc:string){
    //if(loc=='documents'){
      //this._navigationService.setLocation(loc);
    //}else{
      this.filterWorkflowDataEvent.emit(loc);
    //}
    
  }
}
