import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'advanced-tagging-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.sass'],
  standalone: true,
})
export class AdvancedTaggingReviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
