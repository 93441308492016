<div class="sidebar" *ngIf="sideNavToggle" [ngClass]="{'collapse': isCollapse}" [@slideInOut]="animationState">
  <img
    [src]="logoSrc | async"
    alt=""
    class="logo"
    (click)="updateLocation('dashboard')"
  />
  <img
    src="../../../assets/images/forma/forma-icon-white.png"
    alt=""
    class="logo-small"
    (click)="updateLocation('dashboard')"
  />
  <div class="account-header">
    <img
      src="../../../assets/images/avatar.png"
      alt=""
      class="small-logo" (click)="viewAccount()" />
    <div class="account-info">
      <a class="name">Hello, {{ name }}</a>
      <div class="planContainer">
        <p>Free Plan</p>
        <button class="btn btnViewAccount" (click)="viewAccount()">VIEW ACCOUNT</button>
      </div>
    </div>
  </div>
  <div class="sidebar-content">
    <div class="sidebar-items">
      <a
        class="sidebar-item"
        routerLink="/dashboard"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        (click)="closeSidebar()"
      >
        <div class="icon icon-dashboard"></div>
        <p>Dashboard</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/inbox"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
        (click)="closeSidebar()"
      >
        <div class="icon icon-inbox"></div>
        <p>Inbox</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/documents"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-documents"></div>
        <p>Documents</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/folders-and-files"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-folder"></div>
        <p>Folders and Files</p>
      </a>
      <a
      *ngIf="userPermission!=='RMSGroup'"
        class="sidebar-item"
        routerLink="/workflow"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-workflow"></div>
        <p>Workflow</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/signatures"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-signature"></div>
        <p>Signatures</p>
      </a>
      <a class="disabled-item" *ngIf="!hideItem">
        <div class="icon icon-template"></div>
        <p>Templates</p>
      </a>

      <a
        class="sidebar-item"
        routerLink="/faq"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-faq"></div>
        <p>FAQ</p>
      </a>
      <a
        class="sidebar-item"
        routerLink="/support"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-support"></div>
        <p>Support</p>
      </a>
      <app-admin-menu *ngIf="userType === 'admin'"></app-admin-menu>
      <a
        class="sidebar-item"
        (click)="logout()"
        routerLinkActive="active"
        ariaCurrentWhenActive="page"
      >
        <div class="icon icon-logout"></div>
        <p>Logout</p>
      </a>
    </div>
  </div>
  <div class="sidebar-footer">
    <div *ngIf="unawaLogoFlag" class="footer-logo">
      <span>Powered by:</span>
      <img src="../../../assets/images/unawa-logo-text.png" alt="" />
    </div>
    <p class="footer-text">
      <a
        class="emphasize"
        href="https://signsecureapp.unawa.asia/terms-and-conditions"
        target="_blank"
        >Terms & Conditions</a
      >
      |
      <a
        class="emphasize"
        href="https://signsecureapp.unawa.asia/privacy-policy"
        target="_blank"
        >Privacy Policy</a
      ><br />
      © 2024
      <a class="emphasize" href="https://www.unawa.asia/" target="_blank"
        >UNAWA</a
      >
      <!--<span class="emphasize">UNAWA</span>.!-->
      All rights reserved<br />
      <span class="version">Version: {{ (versionAsync | async) || version }}</span>
    </p>
  </div>
  <button class="btn btnCollapse" (click)="collapse()">&lt;</button>
</div>
