import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { FilesService } from 'src/app/@shared/services/files.service';
import { ModalComponent } from '../modal/modal.component';
import moment from 'moment';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.sass'],
})
export class FileViewerComponent implements OnInit, OnDestroy {
  type: string = '';
  pdfSrc: string = '';
  id: string = '';
  subs: Subscription = new Subscription();
  msFiles = ['docx', 'xlsx', 'pptx', 'zip', 'rar', '7z'];
  mediaFiles = ['mp4', 'mp3'];
  expired = false;
  info: any;

  constructor(
    private _route: ActivatedRoute,
    private fileService: FilesService,
    public dialog: MatDialog,
    private _nav: NavigationService,
  ) {}
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(
      this._route.params.subscribe(({ id, type }) => {
        if (id && id !== '') {
          console.log({ id, type });
          if(this._nav.hasPublicData()) {
            const file = this._nav.getPublicData()
            this._nav.setPublicData(file)
            const lowerCaseType = type.toLowerCase();
            this.type = lowerCaseType;
            this.id = id;

            if (
              file?.dueDate &&
              moment(file.dueDate).isSameOrBefore(moment())
            ) {
              this.expired = true;
              this.info = {
                image: '../../../assets/images/icons/expired.svg',
                title: 'Document expired',
                description: `The document <strong>“${file.name}”</strong> has expired. Everyone with access will be notified.`,
                hideDownload: true,
              };
            }

            // check for not pdf and image files
            if (this.msFiles.includes(lowerCaseType)) {
              this.fileService.getProxyUrl(id).subscribe(fileData => {
                this.fileService.downloadFile(file.name, fileData);
              });
            } else if (this.mediaFiles.includes(lowerCaseType)) {
              this.dialog.open(ModalComponent, {
                disableClose: true,
                panelClass: 'transparent-dialog',
                backdropClass: 'dark-bg',
                data: {
                  action: 'media-viewer',
                  data: file,
                  close: false,
                },
              });
            }
          } else {
            this.fileService.getFile(id).subscribe(({ data: file }) => {
              const lowerCaseType = type.toLowerCase();
              this.type = lowerCaseType;
              this.id = id;
  
              if (
                file?.dueDate &&
                moment(file.dueDate).isSameOrBefore(moment())
              ) {
                this.expired = true;
                this.info = {
                  image: '../../../assets/images/icons/expired.svg',
                  title: 'Document expired',
                  description: `The document <strong>“${file.name}”</strong> has expired. Everyone with access will be notified.`,
                  hideDownload: true,
                };
              }
  
              // check for not pdf and image files
              if (this.msFiles.includes(lowerCaseType)) {
                this.fileService.getProxyUrl(id).subscribe(fileData => {
                  this.fileService.downloadFile(file.name, fileData);
                });
              } else if (this.mediaFiles.includes(lowerCaseType)) {
                this.dialog.open(ModalComponent, {
                  disableClose: true,
                  panelClass: 'transparent-dialog',
                  backdropClass: 'dark-bg',
                  data: {
                    action: 'media-viewer',
                    data: file,
                    close: false,
                  },
                });
              }
            });
          }
          }
         
      })
    );
  }
}
