import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.sass'],
})
export class EmailSentComponent implements OnInit {
  @Input('type')
  type: string = '';

  @Output() returnEvent = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}
  returnScreen(value: any) {
    this.returnEvent.emit(`${value}`);
  }
}
