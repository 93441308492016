import {
  SignaturePadComponent,
  NgSignaturePadOptions,
} from '@almothafar/angular-signature-pad';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { firstValueFrom, of, switchMap } from 'rxjs';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignatureService } from 'src/app/@shared/services/signature.service';
import { environment } from 'src/environments/environment';
import { SignSecureService } from '../../services/sign-secure.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-create-signature',
  templateUrl: './create-signature.component.html',
  styleUrls: ['./create-signature.component.sass'],
})
export class CreateSignatureComponent implements OnInit {
  @Input()
  data: any;

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('signature')
  public signaturePad!: SignaturePadComponent;

  @ViewChild('phoneSignature')
  public signaturePhonePad!: SignaturePadComponent;

  public signaturePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 462,
    canvasHeight: 134,
  };

  public signaturePhonePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 340,
    canvasHeight: 300,
  };

  signatureData: any = null;

  resetUpload: boolean = false;
  status: string = '';

  signatureList: any[] = [];

  selectedSignature: any = null;

  config = of({
    width: 462,
    height: 134,
  });

  typedSignature: string = '';

  @ViewChild('signaturePreviewElement') signaturePreviewElement!: ElementRef;

  @ViewChild('signatureCanvas') canvasRef!: ElementRef;

  inputSignature: string = '';

  activeTabIndex: number = 0;

  selectedFont: string | undefined;

  fonts: string[] = ['Lora', 'PlayfairDisplay', 'Redressed', 'Yellowtail'];
  constructor(
    private _signatures: SignatureService,
    private _nav: NavigationService,
    private _signSecure: SignSecureService,
    private _fileService: FilesService,
    private _domSanitizer: DomSanitizer,
    private _detech: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._signSecure.signatureId = '';
    this._signatures.getSignatures().subscribe((data: any) => {
      this.signatureList = data?.data ?? [];
    });
  }

  ngAfterViewInit() {}

  drawComplete(event: MouseEvent | Touch, view: string) {
    // will be notified of szimek/signature_pad's onEnd event

    console.log({ view });
    if (view === "Desktop" || view === "Tablet") {
      this.signatureData = this.signaturePad.toDataURL();
    } else if (view === "Mobile") {
      this.signatureData = this.signaturePhonePad.toDataURL();
    }


    console.log({signatureData: this.signatureData})
    
    this._signSecure.signatureId = this.generateSignatureId();
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
  }

  close() {
    this._signSecure.signatureType = this.activeTabIndex;
    if (!!!this._signSecure.signatureTypeText) {
      this._signSecure.signatureTypeText =
        this._signSecure.signatureTypeIndexToText(this.activeTabIndex);
      this._signSecure.signatureId_ = this.generateSignatureId();
    }
    if (this.signatureData) {
      this.closeModalEvent.emit(this.signatureData);
    } else {
      this.closeModalEvent.emit(null);
    }
  }

  async doneUpload(event: any) {
    console.log('doneUpload11', event);
    this._signSecure.signatureId = event.data.id;

    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.signatureData = e.target.result;
      this.close();
    };
    reader.readAsDataURL(
      await firstValueFrom(this._signSecure.getSignaturesProxy(event.data.id))
    );
  }

  uploadStatus(event: any) {
    this.status = event;
    this.resetUpload = false;
  }

  reset() {
    console.log({status: this.status})
    if (this.status !== 'status') {
      console.log({status: this.status, reset: this.resetUpload})
      this.resetUpload = true;
      console.log({status: this.status, reset: this.resetUpload})
    }
  }

  async selectSignature(signature: any) {
    this._signSecure.signatureId = signature.id;
    const reader = new FileReader();

    reader.onload = (e: any) => {
      this.signatureData = e.target.result;
    };
    reader.readAsDataURL(
      await firstValueFrom(this._signSecure.getSignaturesProxy(signature.id))
    );
  }

  clearSelected() {
    this.signatureData = null;
  }

  clearDrawing() {
    this.signaturePad.clear();
    this.signaturePhonePad.clear()
    this.clearSelected();
  }

  signWithFont() {
    this.drawSignature();
    this.setSignatureFontDataURL();
    this._signSecure.signatureId = this.generateSignatureId();
  }

  selectFont(font: string) {
    this.selectedFont = font;
    this.drawSignature();
  }

  drawSignature() {
    this._detech.detectChanges();

    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      console.error('Canvas context is not available.');
      return;
    }

    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    const fontSize = 45;
    ctx.font = `${fontSize}px ${this.selectedFont}`;
    ctx.fillStyle = 'black';

    const text = this.inputSignature;

    const textMetrics = ctx.measureText(text);
    const textWidth = textMetrics.width;
    const textHeight =
      textMetrics.actualBoundingBoxAscent +
      textMetrics.actualBoundingBoxDescent;

    const textX = (canvasWidth - textWidth) / 2;
    const textY = (canvasHeight - textHeight) / 2 + 20;

    ctx.fillText(text, textX, textY);
  }

  setSignatureFontDataURL() {
    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;
    const dataURL = canvas.toDataURL();
    this.signatureData = dataURL;

    var link = document.createElement('a');
    link.href = dataURL;

    this.close();
  }

  clearSelectedFont() {
    this.selectedFont = undefined;
  }

  generateSignatureId() {
    var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
    return (
      timestamp +
      'xxxxxxxxxxxxxxxx'
        .replace(/[x]/g, function () {
          return ((Math.random() * 16) | 0).toString(16);
        })
        .toLowerCase()
    );
  }

}
