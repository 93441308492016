import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, catchError, lastValueFrom } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.sass']
})
export class VerifyAccountComponent implements OnInit {

  isEmailSent: boolean = false;
  success: string = ''
  imgLink: string = '../../../../assets/images/verified-success.svg'
  subs: Subscription = new Subscription();
  constructor(private _route: ActivatedRoute, private _auth: AuthenticationService, private _loader: LoaderService, private _router: Router ) { }

  ngOnInit(): void {
    this.subs.add(
      this._route.params.subscribe(async ({ token }) => {
        this._loader.show()
        if (token) {
            try {
              const data:any = await lastValueFrom(this._auth.verifyUser(token))
              this.success = 'Verified'
            } catch {
              this.imgLink = '../../../../assets/images/verification-failed.svg'
              this.success = 'Failed'
              
            } finally {
              this._loader.hide()
            }
        } else {
          this._router.navigate(['']);
          this.imgLink = '../../../../assets/images/mail.png'
          this.success = 'Not verified'
        }
      })
    );
  }

  handleClick() {
    if (this.success === 'Not verified') {
      this._auth.resendConfirmation().subscribe({
        next: () => {
          this.isEmailSent = true;
          this._auth.logout();
        }
      });
    } else {
      this._router.navigateByUrl('/login')
    }
  }

}
