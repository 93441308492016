import { Injectable } from '@angular/core';

@Injectable()
export class LocalizationService {
  storeData(property: string, data: any) {
    localStorage.setItem(property, JSON.stringify(data));
  }

  getData(property: string) {
    let data = localStorage.getItem(property);
    if (data) {
      return JSON.parse(data);
    }else{
        return false;
    }
  }

  clearProperty(property: string) {
    localStorage.removeItem(property);
  }

  cleanAll() {
    localStorage.clear();
  }
}
