<ng-container>
    <form class="cancel-sub">
      <h4 class="title">
        Cancel subscription?
      </h4>
      <div class="content">
        <div class="input-group">
          <mat-label class="label-text"
            >Are you sure you want to cancel your subscription?
          </mat-label>
        </div>
        <div class="actions">
          <button matRipple class="cancel" (click)="closeModal()">Cancel</button>
          <button matRipple class="create" (click)="cancelSubscription()">Yes, I am sure</button>
        </div>
      </div>
    </form>
  </ng-container>
  