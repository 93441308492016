<div class="upload-container">
  <button matRipple class="close" (click)="close()">
    <img src="../../../../assets/images/close.svg" alt="" srcset="" />
  </button>
  <h2 class="title">Upload your folder or file</h2>
  <mat-radio-group
    class="options-container"
    (change)="onChangeOption($event)"
    [selected]="selected"
  >
    <mat-radio-button value="files" disableRipple #selected
      >File</mat-radio-button
    >
    <mat-radio-button value="folder" disableRipple>Folder</mat-radio-button>
  </mat-radio-group>
  <div
    class="drag-drop-container"
    appDragDropFileUpload
    (fileDropped)="dragover($event)"
  >
    <div class="container" *ngIf="value !== 'folder'">
      <img src="../../../../assets/images/upload.svg" alt="" />
      <h4>Drag and Drop to upload</h4>
    </div>
    <div class="or-container" *ngIf="value !== 'folder'">
      <p class="or">Or select from your computer</p>
    </div>
    <div class="container-button">
      <button matRipple (click)="upload(value)">{{ buttonText }}</button>
    </div>
  </div>
  <div class="info-container">
    <p>Acceptable file formats: jpg, pdf, docx, xlsx, pptx, mp3, mp4, zip</p>
    <p>Max file size: 200MB</p>
  </div>
</div>

<input
  type="file"
  id="fileUpload"
  name="fileUpload"
  style="display: none"
  (change)="onFileSelected($event)"
  accept=".jpg,.png,.pdf,.docx,.doc,.xlsx,.xls,.ppt,.pptx"
  multiple
/>

<input
  type="file"
  id="folderUpload"
  name="folderUpload"
  style="display: none"
  (change)="onFileSelected($event)"
  accept=".jpg,.png,.pdf,.docx,.doc,.xlsx,.xls,.ppt,.pptx"
  webkitdirectory
  directory
/>
