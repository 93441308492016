import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { UserGroupModel } from 'src/app/@shared/models/user-group.model';
import { UserModel } from 'src/app/@shared/models/user.model';
import { SettingsService } from 'src/app/@shared/services/settings.service';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UsersService } from 'src/app/@shared/services/users.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass'],
})
export class ModalComponent {
  primaryColor: string = '';
  menuBackgroundColor: string = '';
  backgroundColor: string = '';
  textColor: string = '';
  topbarColor: string = '';
  hoverColor: string = '';

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    private _settingsService:SettingsService,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {}

  ngOnInit(): void {
    
    this._settingsService.updateTheme$.subscribe((theme) => {
      this.primaryColor = `--primary-color: ${theme.primaryColor};`;
      this.menuBackgroundColor = `--secondary-color: ${theme.menuBackgroundColor};`;
      this.backgroundColor = `--background-color: ${theme.backgroundColor};`;
      this.textColor = `--text-color: ${theme.textColor};`;
      this.topbarColor = `--topbar-color: ${theme.topbarColor};`;
      this.hoverColor = `--hover-color: ${theme.hoverColor};`;
    });
  }

  closeModal(data: any) {
    
    if (this.data.action === 'share-permission') {
      this.dialogRef.close({ ...data, modalAction: this.data.action });
    } else {
      this.dialogRef.close(data);
    }
  }
}
