import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';

@Component({
  selector: 'app-verification-container',
  templateUrl: './verification-container.component.html',
  styleUrls: ['./verification-container.component.sass'],
})
export class VerificationContainerComponent implements OnInit {
  @Input() screen: string = 'verification-success';
  @Output() returnEvent = new EventEmitter<string>();

  @Input()
  type: string = 'verification-fail';

  
  constructor(private _authService: AuthenticationService) {}

  ngOnInit(): void {
    this.type = this.screen;
  }

  returnScreen(value: any) {
    this.returnEvent.emit(value);
  }
  changeType(value: string) {
    this.type = value;
    value;
  }
}
