<ng-container>
  <form class="delete-file">
    <h4 class="title">
      <ng-container *ngIf="data.data.length > 1">
        Delete {{ data.data.length }} items
      </ng-container>
      <ng-container *ngIf="data.data.length == 1">
        Delete
        {{
          data.data[0].file_type === "file" || data.data[0].type === "file"
            ? "file"
            : "folder"
        }}
      </ng-container>
    </h4>
    <div class="content">
      <div class="input-group">
        <mat-label class="label-text"
          >Are you sure you want to delete
          <ng-container *ngIf="data.isBulk"> items? </ng-container>
          <ng-container *ngIf="!data.isBulk">
            <span class="file-title"
              >"{{ data.data[0].title || data.data[0].name }}"</span
            >?
          </ng-container>
        </mat-label>
      </div>
      <div class="actions">
        <button matRipple class="cancel" (click)="closeModal()">Cancel</button>
        <button matRipple class="create" (click)="deleteFile()">Delete</button>
      </div>
    </div>
  </form>
</ng-container>
