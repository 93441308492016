import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observer, Subscription } from 'rxjs';
import { UserGroupModel } from 'src/app/@shared/models/user-group.model';
import { UserGroupResponseModel } from 'src/app/@shared/models/user-groups-response.model';
import { UserTableModel } from 'src/app/@shared/models/user-table.model';
import { UserModel } from 'src/app/@shared/models/user.model';
import { UsersResponseModel } from 'src/app/@shared/models/users-response.model';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-admin-dashboard-content',
  templateUrl: './admin-dashboard-content.component.html',
  styleUrls: ['./admin-dashboard-content.component.sass'],
})
export class AdminDashboardContentComponent implements OnInit, OnDestroy, AfterContentInit {
  menuType: string = '';
  userType: string = '';
  recentDocmentTitle: string = 'Recent Documents';
  recentUsersTitle: string = 'Recent Users';
  usersData = this._userService.users$;
  isSearch: boolean = false;
  modifyFile: boolean = false;
  @Input()
  viewPdf: boolean = false;
  subs: Subscription = new Subscription();
  dialog: MatDialog;
  spinnerLoaded: boolean = false;
  readonly signaturesFlag = environment.flag.signatureMenu;
  isLoading = this._loaderService.isLoading;

  constructor(
    dialog: MatDialog,
    private _userService: UsersService,
    private _loaderService: LoaderService,
    private _navigation: NavigationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.dialog = dialog;
  }
  ngAfterContentInit(): void {
    this.changeDetectorRef.detectChanges();
  }
  ngOnInit(): void {
  }
  
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
