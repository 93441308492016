<div class="audit-trail__content" *ngIf="show">
    <div class="title">
        <div class="text">
            <h4>AUDIT TRAIL</h4>
            <p>View a history of the documents changes here.</p>
        </div>
        <img src="../../../assets/images/close-icon.svg" alt="" (click)="close.emit(true)">
        
    </div>
    
    
    <app-audit-trail-details [data]="data"></app-audit-trail-details>
</div>