import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { DashBoardServices } from 'src/app/@shared/services/dashboard.service';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SettingsService } from 'src/app/@shared/services/settings.service';
import { environment } from 'src/environments/environment';
export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        'max-width': '105vw',
        opacity: '1',
        visibility: 'visible',
      })
    ),
    state(
      'out',
      style({
        'max-width': '55vw',
        opacity: '1',
      })
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-width': '55vw',
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-width': '105vw',
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
      ]),
    ]),
  ]),
];
@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.sass'],
  animations: [SlideInOutAnimation],
})
export class AdminSidebarComponent implements OnInit {
  animationState = 'in';
  versionAsync: any;
  version: string = '';
  userType: string = '';
  readonly unawaLogoFlag = environment.flag.unawaLogo;
  selected: string = 'report';
  dashRep: boolean = true;
  manageUser: boolean = false;
  manageUserGrp: boolean = false;
  auditLog: boolean = false;
  name: string = '';
  email: string = '';
  docMenu: boolean = false;
  listMenu: boolean = false;
  docDetailMenu: boolean = false;
  isCollapse: boolean = false;
  logoSrc = this._settingsService.logoSrc$;
  readonly hideItem = environment.flag.hideSidebarMenu;
  readonly signaturesMenuFlag = environment.flag.signatureMenu;
  @Input() sideNavToggle: boolean = true;
  @Output() toggleSideNavEvent = new EventEmitter<boolean>();

  readonly userPermission = environment.userPermission;
  filter =
    'invert(38%) sepia(25%) saturate(2923%) hue-rotate(166deg) brightness(96%) contrast(87%)';

  constructor(
    private _navigation: NavigationService,
    private _authService: AuthenticationService,
    private _fileActionService: FileActionService,
    private _dashboardService: DashBoardServices,
    private _router: Router,
    private _settingsService: SettingsService
  ) {
    this._navigation.location$.subscribe(
      location => (this.selected = location)
    );
  }

  changeState() {
    this.animationState = this.animationState == 'in' ? 'out' : 'in';
  }
  ngOnInit(): void {
    this.versionAsync = this._dashboardService.appVersionAsync;
    this._navigation.initLocation = this.selected;
    this.userType = this._authService.userRole;

    this.name = this._authService.name;
    this.email = this._authService.email;

    this.version = localStorage.getItem(this._dashboardService.key) || '';
    this._navigation.toggleSidebar.subscribe((data:any)=>
    {
      this.isCollapse = true;
    })
  }

  updateLocation(location: string) {
    this._router.navigateByUrl(location);
    this._navigation.updateLocation(location);
    if (location !== 'docs') {
      this._fileActionService.selectedTab = 0;
    }

    this._fileActionService.setIsModify(false);
  }

  closeSidebar() {
    if (screen.width >= 280 && screen.width <= 600) {
      this.toggleSideNavEvent.emit(false);
    } else if (screen.width >= 600 && screen.width <= 1368) {
      this.toggleSideNavEvent.emit(true);
    }
  }

  logout() {
    this._authService.logout();
    sessionStorage.removeItem('token');
    this._authService.userRole = '';
    this._authService.setRole(this._authService.userRole);
    this._router.navigateByUrl('/login');
  }

  viewAccount() {
    this._router.navigateByUrl('/account');
  }

  collapse(){
    this.isCollapse = !this.isCollapse
  }
}
