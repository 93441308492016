import { Component, EventEmitter, OnInit, Output, ElementRef, NgZone, OnDestroy  } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { InboxService } from '../services/inbox.service';

@Component({
  selector: 'app-inbox-view',
  templateUrl: './inbox-view.component.html',
  styleUrls: ['./inbox-view.component.sass'],
})
export class InboxViewComponent implements OnInit {
  @Output() goBackEvent = new EventEmitter<boolean>();
  message: any;
  html!: SafeResourceUrl;
  private iframe: HTMLIFrameElement | undefined;
  constructor(private _inboxService: InboxService, private elementRef: ElementRef, private ngZone: NgZone) {}

  ngOnInit(): void {
    this._inboxService.message$.subscribe((data) => {
      this.message = data;
      this.html = data.html;
    });

    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('message', this.handleIframeMessage.bind(this));
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleIframeMessage.bind(this));
  }

  onIframeLoad(): void {
    this.iframe = this.elementRef.nativeElement.querySelector('iframe');
  }

  handleIframeMessage(event: MessageEvent): void {
    if (event.origin !== window.location.origin) {
      return;
    }

    const data = event.data;

    this.processIframeData(data);
  }

  processIframeData(data: any): void {
    window.open(data.link,"_self")
  }

  goBack() {
    this.goBackEvent.emit(false);
  }
}