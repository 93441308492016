import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-logo-container',
  templateUrl: './logo-container.component.html',
  styleUrls: ['./logo-container.component.sass']
})
export class LogoContainerComponent implements OnInit {
  isTabletWidth = false;
  isPhoneWidth = false;

  constructor(
    public _navigation: NavigationService,
  ) {}

  ngOnInit(): void {
    if (screen.width >= 280 && screen.width <= 600) {
      this.isPhoneWidth = true;
    } else if (screen.width >= 600 && screen.width <= 1368) {
      this.isTabletWidth = true;
    } else {
      this.isPhoneWidth = false;
      this.isTabletWidth = false;
    }
  }
}
